import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import projectsApi from "services/api/projects";
import { AppState as AppStoreState } from "stores/constants";

export type AppAccessType = "admin" | "developer" | "viewer" | "noAccess";

export interface AppUser {
  access: AppAccessType;
  name: string;
  userId: string;
  organizationRole: "owner" | "collaborator";
}

export interface AppState {
  access: AppAccessType;
  email: string;
  isAppAdmin: boolean;
  users: AppUser[];
}

const initialState: AppState = {
  access: "noAccess",
  email: "",
  isAppAdmin: false,
  users: [],
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    appUpdated: (state, action: PayloadAction<Partial<AppState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      projectsApi.endpoints.getProject.matchFulfilled,
      (state, { payload }) => {
        if (payload.appAccessLevel) {
          state.access = payload.appAccessLevel;
        }
      }
    );
  },
});

export const { appUpdated } = appSlice.actions;

export const selectApp = (state: AppStoreState) => state.app;
export const selectAppAccess = (state: AppStoreState) =>
  selectApp(state).access;
export const selectAppEmail = (state: AppStoreState) => selectApp(state).email;
export const selectIsAppAdmin = (state: AppStoreState) =>
  selectApp(state).isAppAdmin;
export const selectAppUsers = (state: AppStoreState) => selectApp(state).users;

const appManagementReducer = appSlice.reducer;

export default appManagementReducer;
