import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DeepPartial } from "utility-types";

import { appBuilderUpdater } from "utils/appBuilder";

import { AppState } from "../../constants";
import { selectAppBuilder } from "./utils";

export interface CssSelector {
  id: number;
  selector: string;
  visibility: "hide" | "remove" | null;
}

export interface CustomHeader {
  id: number;
  active: boolean;
  parameter: string;
  value: string;
}

export enum CookiePersistenceType {
  default = "default",
  custom = "custom",
}

export interface AppBuilderWebsiteOverridesState {
  cssSelectors?: CssSelector[];
  customCss?: {
    base: string | undefined;
    ios: string | undefined;
    android: string | undefined;
  };
  customCssUrl?: {
    base: string | undefined;
    ios: string | undefined;
    android: string | undefined;
  };
  customJs?: {
    base: string | undefined;
    ios: string | undefined;
    android: string | undefined;
  };
  customJsUrl?: {
    base: string | undefined;
    ios: string | undefined;
    android: string | undefined;
  };
  customUserAgent: {
    base?: {
      append: string;
      custom: boolean;
      value: string | undefined;
    };
    ios?: {
      append: string;
      custom: boolean;
      value: string | undefined;
    };
    android?: {
      append: string;
      custom: boolean;
      value: string | undefined;
    };
  };
  forceSessionCookieExpiry: number | undefined;
  cookiePersistence: CookiePersistenceType;
  npmPackage: boolean;
  customHeaders: {
    ios: {
      value: CustomHeader[];
    };
    android: {
      value: CustomHeader[];
    };
  };
}

const initialState: AppBuilderWebsiteOverridesState = {
  customUserAgent: {
    base: {
      append: "gonative",
      custom: false,
      value: undefined,
    },
    ios: {
      append: "GoNativeIOS/1.0 gonative",
      custom: false,
      value: undefined,
    },
    android: {
      append: "GoNativeAndroid/1.0 gonative",
      custom: false,
      value: undefined,
    },
  },
  cookiePersistence: CookiePersistenceType.default,
  forceSessionCookieExpiry: 90,
  customHeaders: {
    ios: {
      value: [],
    },
    android: {
      value: [],
    },
  },
  npmPackage: false,
};

const websiteOverridesSlice = createSlice({
  name: "websiteOverrides",
  initialState,
  reducers: {
    websiteOverridesUpdated: (
      state,
      action: PayloadAction<DeepPartial<AppBuilderWebsiteOverridesState>>
    ) => appBuilderUpdater(state, action.payload),
  },
});

export const { websiteOverridesUpdated } = websiteOverridesSlice.actions;

export const selectWebsiteOverrides = (state: AppState) =>
  selectAppBuilder(state).websiteOverrides;

const websiteOverridesReducer = websiteOverridesSlice.reducer;

export default websiteOverridesReducer;
