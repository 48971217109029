import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import appApi from "services/api/app";
import organizationApi from "services/api/organization";
import { AppState } from "stores/constants";
import App from "types/App";

export interface OrganizationMember {
  userId: string;
  name: string;
  email: string;
  role: "owner" | "collaborator";
  appAccess: "all" | "selected";
}

export interface OrganizationInvitedMember {
  name: string;
  email: string;
  role: "owner" | "collaborator";
  appAccess: "all" | "selected";
  invitationExpired: boolean;
  inviteId: string;
}

export interface OrganizationMemberDetails {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  role: "owner" | "collaborator";
  apps: App[];
  organizations: {
    id: string;
    name: string;
  }[];
}

export interface OrganizationState {
  apps: App[];
  isOwner: boolean | undefined;
  invitedMembers: OrganizationInvitedMember[];
  members: OrganizationMember[];
  memberDetails: OrganizationMemberDetails[];
  organizationId: string;
  organizationName: string;

  transferAppOrganizationId: string;
  transferAppId: string;

  leaveOrgId: string;

  invitationId: string;
  invitationInviter: string;
  invitationOrganization: string;
  invitationRole: "collaborator" | "owner";
}

const initialState: OrganizationState = {
  apps: [],
  invitedMembers: [],
  isOwner: false,
  members: [],
  memberDetails: [],
  organizationId: "",
  organizationName: "",

  transferAppOrganizationId: "",
  transferAppId: "",

  leaveOrgId: "",

  invitationId: "",
  invitationInviter: "",
  invitationOrganization: "",
  invitationRole: "collaborator",
};

const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    organizationUpdated: (
      state,
      action: PayloadAction<Partial<OrganizationState>>
    ) => ({
      ...state,
      ...action.payload,
    }),
    organizationMemberDetailsUpdated: (
      state,
      action: PayloadAction<Partial<OrganizationMemberDetails>>
    ) => {
      // Update member details
      const existing = state.memberDetails.some(
        (i) => i.userId === action.payload.userId
      );
      if (existing) {
        state.memberDetails = state.memberDetails.map((item) => {
          if (item.userId !== action.payload.userId) {
            return item;
          }
          return { ...item, ...action.payload };
        });
      } else {
        state.memberDetails = [
          ...state.memberDetails,
          action.payload as OrganizationMemberDetails,
        ];
      }

      // Update members
      state.members = state.members.map((item) => {
        const details = state.memberDetails.find(
          (i) => i.userId === item.userId
        );
        if (details && details.organizations) {
          return {
            ...item,
            firstName: details.firstName,
            lastName: details.lastName,
            email: details.email,
            role: item.role,
            appAccess: item.role === "owner" ? "all" : "selected",
          };
        }
        if (details && !details.organizations) {
          return {
            ...item,
            firstName: details.firstName,
            lastName: details.lastName,
            email: details.email,
            role: details.role || item.role,
            appAccess: details.role === "owner" ? "all" : "selected",
          };
        }

        return item;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        organizationApi.endpoints.getOrganizationMembers.matchFulfilled,
        (state, { payload }) => {
          state.isOwner = payload.isOrgOwner;
          state.members = payload.organizationMembers;
          state.invitedMembers = payload.invitedMembers;
        }
      )
      .addMatcher(
        organizationApi.endpoints.getOrganizationApps.matchFulfilled,
        (state, { payload }) => {
          state.isOwner = payload.isOrgOwner;

          const existingAppsMap = new Map(
            state.apps.map((app) => [app.appId, true])
          );

          const filteredApps = payload.apps.filter(
            (app) => !existingAppsMap.has(app.appId)
          );

          state.apps = [...state.apps, ...filteredApps];
        }
      )
      .addMatcher(
        organizationApi.endpoints.getOrganizationSettings.matchFulfilled,
        (state, { payload }) => {
          state.organizationName = payload.organizationName;
          state.isOwner = payload.isOrgOwner;
        }
      )
      .addMatcher(
        appApi.endpoints.getAppAccess.matchFulfilled,
        (state, { payload }) => {
          state.isOwner = payload.isOrgOwner;
        }
      );
  },
});

export const { organizationUpdated, organizationMemberDetailsUpdated } =
  organizationSlice.actions;

export const selectOrganization = (state: AppState) => state.organization;
export const selectOrganizationId = (state: AppState) =>
  selectOrganization(state).organizationId;
export const selectOrganizationApps = (state: AppState) =>
  selectOrganization(state).apps;
export const selectOrganizationMembers = (state: AppState) =>
  selectOrganization(state).members;
export const selectOrganizationInvitedMembers = (state: AppState) =>
  selectOrganization(state).invitedMembers;
export const selectOrganizationMemberDetails = (
  state: AppState,
  userId: string
) => selectOrganization(state).memberDetails.find((i) => i.userId === userId);
export const selectIsOrganizationOwner = (state: AppState) =>
  selectOrganization(state).isOwner;
export const selectOrganizationName = (state: AppState) =>
  selectOrganization(state).organizationName;
export const selectTransferAppOrganizationId = (state: AppState) =>
  selectOrganization(state).transferAppOrganizationId;
export const selectTransferAppId = (state: AppState) =>
  selectOrganization(state).transferAppId;
export const selectLeaveOrgId = (state: AppState) =>
  selectOrganization(state).leaveOrgId;
export const selectInvitationId = (state: AppState) =>
  selectOrganization(state).invitationId;
export const selectInvitationInviter = (state: AppState) =>
  selectOrganization(state).invitationInviter;
export const selectInvitationOrganization = (state: AppState) =>
  selectOrganization(state).invitationOrganization;
export const selectInvitationRole = (state: AppState) =>
  selectOrganization(state).invitationRole;

const organizationReducer = organizationSlice.reducer;

export default organizationReducer;
