import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import projectsApi from "services/api/projects";

import { AppState } from "../constants";

export interface ProjectState {
  privateKey: string;
  publicKey: string;
  created: string | undefined;
  updatedByServer: string | undefined;
  updatedByUser: string | undefined;
  lastModifiedBy: string | undefined;
  isModifiedByDeletedUser: boolean | undefined;
  isModifiedBySuperUser: boolean | undefined;
  versionCode: number;
  isShopifyApp: boolean;
  iosBundleId?: string;
  androidPackageName?: string;
  isOrgOwner?: boolean;
}

const initialState: ProjectState = {
  privateKey: "",
  publicKey: "",
  created: undefined,
  updatedByServer: undefined,
  updatedByUser: undefined,
  lastModifiedBy: undefined,
  isModifiedByDeletedUser: undefined,
  isModifiedBySuperUser: undefined,
  versionCode: 1,
  isShopifyApp: false,
  isOrgOwner: undefined,
};

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    projectUpdated: (state, action: PayloadAction<Partial<ProjectState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        projectsApi.endpoints.cloneProject.matchFulfilled,
        (state, { payload }) => {
          state.publicKey = payload.publicKey;
          state.iosBundleId = payload.iosBundleId;
          state.androidPackageName = payload.androidPackageName;
        }
      )
      .addMatcher(
        projectsApi.endpoints.getProject.matchFulfilled,
        (state, { payload }) => ({
          ...initialState,
          ...payload.appBuilder.project,
          isShopifyApp: payload.isShopifyApp,
          lastModifiedBy: payload.lastModifiedBy,
          isModifiedByDeletedUser: payload.isModifiedByDeletedUser,
          isModifiedBySuperUser: payload.isModifiedBySuperUser,
          isOrgOwner: payload.isOrgOwner,
        })
      )
      .addMatcher(
        projectsApi.endpoints.buildProject.matchFulfilled,
        (state, { payload }) => {
          if (payload.lastModifiedBy) {
            state.lastModifiedBy = payload.lastModifiedBy;
            state.isModifiedByDeletedUser = payload.isModifiedByDeletedUser;
            state.isModifiedBySuperUser = payload.isModifiedBySuperUser;
          }
        }
      )
      .addMatcher(
        projectsApi.endpoints.saveProject.matchFulfilled,
        (state, { payload }) => {
          if (payload.lastModifiedBy) {
            state.lastModifiedBy = payload.lastModifiedBy;
            state.isModifiedByDeletedUser = payload.isModifiedByDeletedUser;
            state.isModifiedBySuperUser = payload.isModifiedBySuperUser;
          }
        }
      )
      .addMatcher(
        projectsApi.endpoints.getProjectShare.matchFulfilled,
        (state, { payload }) => {
          state.publicKey = payload.publicKey;
          state.versionCode = payload.versionCode;
        }
      );
  },
});

export const { projectUpdated } = projectSlice.actions;

export const selectProject = (state: AppState) => state.project;
export const selectProjectCreated = (state: AppState) =>
  selectProject(state).created;
export const selectHasBuilt = (state: AppState) =>
  selectProject(state).versionCode >= 2 &&
  !!state.appBuilder.preview.appetizePublicKey &&
  !!state.appBuilder.preview.appetizeAndroidPublicKey;
export const selectPublicKey = (state: AppState) =>
  selectProject(state).publicKey;
export const selectPrivateKey = (state: AppState) =>
  selectProject(state).privateKey;
export const selectUpdatedByUser = (state: AppState) =>
  selectProject(state).updatedByUser;
export const selectIsShopifyApp = (state: AppState) =>
  selectProject(state).isShopifyApp;
export const selectVersionCode = (state: AppState) =>
  selectProject(state).versionCode;
export const selectIosBundleId = (state: AppState) =>
  selectProject(state).iosBundleId;
export const selectAndroidPackageName = (state: AppState) =>
  selectProject(state).androidPackageName;
export const selectCurrentAppOrgOwner = (state: AppState) =>
  selectProject(state).isOrgOwner;

const projectReducer = projectSlice.reducer;

export default projectReducer;
