/* eslint-disable @typescript-eslint/naming-convention */

import cloneDeep from "lodash/cloneDeep";

import { AppState } from "stores/constants";
import { AppAccessType } from "stores/features/app";
import { brandingUpdated } from "stores/features/appBuilder/branding";
import {
  BuildState,
  BuildStatus,
  buildUpdated,
  LastBuildBy,
} from "stores/features/appBuilder/build";
import { licenseUpdated } from "stores/features/appBuilder/license";
import { overviewUpdated } from "stores/features/appBuilder/overview";
import { previewUpdated } from "stores/features/appBuilder/preview";
import { SidebarItems } from "stores/features/appBuilder/sidebarNavigationBar";
import { appBuilderUpdated } from "stores/features/appBuilder/utils";
import { appConfigUpdated } from "stores/features/appConfig";
import { projectUpdated } from "stores/features/project";
import { uiUpdated } from "stores/features/ui";
import {
  ProjectSession,
  ProjectSource,
  trackEvent,
  trackLead,
} from "utils/analytics";
import {
  AppBuilder,
  AppBuilderInput,
  appBuilderUpdater,
  AppConfig,
  AppConfigInput,
  BuilderConfigInput,
  deserializeAppBuilder,
  serializeAppBuilder,
} from "utils/appBuilder";
import { errorMatchesMessage } from "utils/errors";
import urls from "utils/urls";

import apiSlice from ".";
import { TagType } from "./constants";
import { addCustomFilesContentToAppBuilder, appIconUploaded } from "./utils";

export interface ApiGetProject {
  privateKey: string;
  appConfig: AppConfig;
  appBuilder: {
    appBuilder: AppBuilder;
    project: {
      privateKey: string;
      publicKey: string;
      created: string;
      updatedByServer: string;
      updatedByUser: string;
    };
  };
  appAccessLevel?: AppAccessType;
  isModifiedByDeletedUser?: boolean;
  isModifiedBySuperUser?: boolean;
  isShopifyApp: boolean;
  lastModifiedBy?: string;
  isOrgOwner?: boolean;
  isDownloadsBlocked?: boolean;
  isAppetizeBlocked?: boolean;
  hasDevToolkit?: boolean;
}

export interface ApiGetProjectShare {
  _id: string;
  androidLink: boolean;
  appConfig: AppConfig;
  appetizeAndroidPublicKey?: string;
  appetizePublicKey?: string;
  appnumHashed: string;
  email: string;
  status: BuildStatus;
  versionCode: number;
  isDownloadsBlocked?: boolean;
  isAppetizeBlocked?: boolean;
}

interface ApiGetProjectShareArgs {
  publicKey: string;
  headers?: Record<string, string>;
}

export interface ApiGetProjectStatus {
  androidAppBundleLink?: boolean;
  androidBuild: BuildState;
  androidLastBuiltDate?: {
    binary: string;
    source: string;
  };
  androidLastBuiltBy?: LastBuildBy;
  androidLink?: boolean;
  androidSource?: boolean;
  appetizePublicKey?: string;
  appetizeAndroidPublicKey?: string;
  iosBuild: BuildState;
  iosLastBuiltDate?: {
    binary: string;
    source: string;
  };
  iosLastBuiltBy?: LastBuildBy;
  iosLink?: boolean;
  iosSource?: boolean;
  updatedByUser: string;
  updatedByServer: string | null;
  versionCode: number;
}

export type ApiCreateProject = ApiGetProject;

export interface ApiCreateProjectArgs {
  appIcon?: string;
  appName: string;
  clonePublicKey?: string;
  email: string;
  initialUrl: string;
  organizationId?: string;
  sidebarItems?: SidebarItems;
  source: ProjectSource;
  sourcePage?: string;
  sourceSelection?: string;
  projectSession?: ProjectSession;
}

export interface ApiCloneProject {
  appName: string;
  initialUrl: string;
  icon: string;
  iosBundleId: string;
  androidPackageName: string;
}

export interface ApiBuildProject extends ApiGetProject {
  result: string;
}

export interface ApiBuildProjectArgs {
  privateKey: string;
  data: Readonly<
    | { appConfig: AppConfigInput; builderConfig?: BuilderConfigInput }
    | {
        appBuilder: AppBuilderInput;
        builderConfig?: BuilderConfigInput;
      }
  >;
}

export type ApiSaveProject = ApiBuildProject;

export interface ApiSaveProjectArgs {
  privateKey: string;
  data: Readonly<
    | { appConfig: AppConfigInput }
    | {
        appBuilder: AppBuilderInput;
        builderConfig?: BuilderConfigInput;
      }
  >;
}

export interface ApiGetProjectArgs {
  privateKey?: string;
  headers?: Record<string, string>;
}

const projectsApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getProject: build.query<
      ApiGetProject,
      ApiGetProjectArgs & { source?: "server" | "client" }
    >({
      providesTags: [TagType.AppBuilder],
      query: ({ privateKey, headers }) => ({
        url: `${urls.api}/project/${privateKey}`,
        headers,
      }),
      transformResponse: async (response: ApiGetProject) => {
        const transformedResponse = { ...response };

        if (transformedResponse?.appBuilder?.appBuilder) {
          const appBuilder = deserializeAppBuilder(
            transformedResponse.appBuilder.appBuilder
          );
          await addCustomFilesContentToAppBuilder(appBuilder);
          transformedResponse.appBuilder.appBuilder = appBuilder;
        }

        return transformedResponse;
      },
      onQueryStarted: async (_args, { dispatch, getState, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          const appState = getState() as AppState;
          const nextAppBuilder = appBuilderUpdater(
            cloneDeep(appState.appBuilder),
            data.appBuilder.appBuilder
          );
          dispatch(appBuilderUpdated(nextAppBuilder));
          dispatch(
            licenseUpdated({
              hasDevToolkit: data.hasDevToolkit,
            })
          );

          if (_args.source !== "server") {
            dispatch(appConfigUpdated(data.appConfig));
            dispatch(projectUpdated(data.appBuilder.project));
          }
        } catch (e) {
          if (errorMatchesMessage(e, "not-loggedin")) {
            dispatch(
              uiUpdated({ authMessageType: "appAccessError/notLoggedIn" })
            );

            if (typeof e === "object" && e !== null) {
              const errorData = e as {
                error: { data: { appName: string; appIcon: string } };
              };

              if (errorData.error && errorData.error.data) {
                const { appName, appIcon } = errorData.error.data;

                dispatch(overviewUpdated({ appName }));
                dispatch(brandingUpdated({ appIcon: { base: appIcon } }));
              }
            }
          } else if (
            errorMatchesMessage(e, "not-organization-member") ||
            errorMatchesMessage(e, "no-app-access")
          ) {
            dispatch(
              uiUpdated({ authMessageType: "appAccessError/noAppAccess" })
            );
          }
        }
      },
      extraOptions: {
        maxRetries: 1,
      },
    }),

    getProjectShare: build.query<
      ApiGetProjectShare & { publicKey: string },
      ApiGetProjectShareArgs
    >({
      providesTags: [TagType.AppBuilder],
      query: ({ publicKey, headers }) => ({
        url: `${urls.api}/share/${publicKey}`,
        headers,
      }),
      transformResponse: (
        response: ApiGetProjectShare,
        _meta,
        { publicKey }
      ) => ({
        ...response,
        publicKey,
      }),
      extraOptions: {
        maxRetries: 1,
      },
    }),

    getProjectStatus: build.query<ApiGetProjectStatus, ApiGetProjectArgs>({
      providesTags: [TagType.AppBuilder, TagType.AppBuilderStatus],
      query: ({ headers, privateKey }) => ({
        url: `${urls.api21}/project/${privateKey}/buildStatus`,
        headers,
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            buildUpdated({
              androidBuild: data.androidBuild,
              iosBuild: data.iosBuild,
              androidLastBuiltDate: data.androidLastBuiltDate,
              androidLastBuiltBy: data.androidLastBuiltBy,
              iosLastBuiltDate: data.iosLastBuiltDate,
              iosLastBuiltBy: data.iosLastBuiltBy,
            })
          );

          const downloadLinks: (keyof ApiGetProjectStatus)[] = [
            "androidLink",
            "androidAppBundleLink",
            "androidSource",
            "iosLink",
            "iosSource",
          ];
          downloadLinks.forEach((key) => {
            if (data[key]) {
              dispatch(buildUpdated({ downloadLinks: { [key]: data[key] } }));
            }
          });

          const appetizeKeys: (keyof ApiGetProjectStatus)[] = [
            "appetizePublicKey",
            "appetizeAndroidPublicKey",
          ];
          appetizeKeys.forEach((key) => {
            if (data[key]) {
              dispatch(previewUpdated({ [key]: data[key] }));
            }
          });
        } catch {
          // NO-OP
        }
      },
      extraOptions: {
        maxRetries: 1,
      },
    }),

    createProject: build.mutation<ApiCreateProject, ApiCreateProjectArgs>({
      invalidatesTags: [TagType.AppBuilder, TagType.AppBuilderStatus],
      query: (args) => ({
        url: `${urls.api}/project`,
        method: "POST",
        body: args,
      }),
      onQueryStarted: async (
        { clonePublicKey, source, sourcePage, sourceSelection },
        { getState, queryFulfilled }
      ) => {
        const { data } = await queryFulfilled;
        const appState = getState() as AppState;

        trackEvent("new_create_click");
        if (clonePublicKey) trackEvent("clone_create_click");

        trackLead({
          app_create_page: sourcePage,
          app_create_selection: sourceSelection,
          app_create_source: source,
          email:
            appState.user.email || data.appBuilder.appBuilder.overview.email,
          privateKey: data.appBuilder.project.privateKey,
          publicKey: data.appBuilder.project.publicKey,
          recent_app_name: data.appBuilder.appBuilder.overview.appName,
          recent_app_initial_url:
            data.appBuilder.appBuilder.overview.websiteUrl,
        });
      },
    }),

    cloneProject: build.query<ApiCloneProject & { publicKey: string }, string>({
      providesTags: [TagType.AppBuilder],
      query: (publicKey) => ({
        url: `${urls.api}/clone/${publicKey}`,
      }),
      transformResponse: (response: ApiCloneProject, _meta, arg) => ({
        ...response,
        publicKey: arg,
      }),
    }),

    buildProject: build.mutation<ApiBuildProject, ApiBuildProjectArgs>({
      invalidatesTags: [TagType.AppBuilderStatus],
      query: ({ privateKey, data }) => {
        if ("appConfig" in data) {
          return {
            url: `${urls.api21}/project/${privateKey}`,
            method: "POST",
            body: {
              appConfig: data.appConfig,
              builderConfig: data.builderConfig,
            },
          };
        } else {
          return {
            url: `${urls.api21}/project/${privateKey}`,
            method: "POST",
            body: {
              appBuilder: serializeAppBuilder(data.appBuilder),
              builderConfig: data.builderConfig,
            },
          };
        }
      },
      onQueryStarted: async (
        { privateKey },
        { dispatch, getState, queryFulfilled }
      ) => {
        const appState = getState() as AppState;

        dispatch(
          projectsApi.util.updateQueryData(
            "getProjectStatus",
            { privateKey },
            (draft) => {
              if (draft?.androidBuild) {
                draft.androidBuild.progress = 0;
              }
              if (draft?.iosBuild) {
                draft.iosBuild.progress = 0;
              }
            }
          )
        );

        const { data } = await queryFulfilled;

        let app_icon_added: true | undefined;
        let native_navigation_once_enabled: true | undefined;
        let onesignal_once_enabled: true | undefined;

        if (
          appIconUploaded(
            appState.meta.cachedAppBuilder as AppBuilder,
            data.appBuilder.appBuilder
          )
        ) {
          trackEvent("app_icon_added");
          app_icon_added = true;
        }

        if (
          (appState.meta.cachedAppBuilder?.topNavigationBar?.enable?.ios
            ?.active !==
            data.appBuilder.appBuilder?.topNavigationBar?.enable?.ios?.active &&
            data.appBuilder.appBuilder?.topNavigationBar?.enable?.ios
              ?.active) ||
          (appState.meta.cachedAppBuilder?.topNavigationBar?.enable?.android
            ?.active !==
            data.appBuilder.appBuilder?.topNavigationBar?.enable?.android
              ?.active &&
            data.appBuilder.appBuilder?.topNavigationBar?.enable?.android
              ?.active) ||
          (appState.meta.cachedAppBuilder?.sidebarNavigationBar?.active !==
            data.appBuilder.appBuilder?.sidebarNavigationBar?.active &&
            data.appBuilder.appBuilder?.sidebarNavigationBar?.active) ||
          (appState.meta.cachedAppBuilder?.bottomTabBar?.active !==
            data.appBuilder.appBuilder?.bottomTabBar?.active &&
            data.appBuilder.appBuilder?.bottomTabBar?.active)
        ) {
          trackEvent("native_navigation_enabled");
          native_navigation_once_enabled = true;
        }

        if (
          appState.meta.cachedAppBuilder?.pushNotifications?.oneSignal?.config
            ?.active !==
            data.appBuilder.appBuilder?.pushNotifications?.oneSignal?.config
              ?.active &&
          data.appBuilder.appBuilder?.pushNotifications?.oneSignal?.config
            ?.active
        ) {
          trackEvent("onesignal_enabled");
          onesignal_once_enabled = true;
        }

        trackEvent("success_update_project");
        trackLead(
          {
            email:
              appState.user.email ||
              data.appBuilder.appBuilder?.overview?.email,
            privateKey,
            publicKey: data.appBuilder.project.publicKey,
            recent_app_name: data.appBuilder.appBuilder?.overview?.appName,
            recent_app_initial_url:
              data.appBuilder.appBuilder?.overview?.websiteUrl,
            licensed:
              data.appBuilder.appBuilder?.license?.app?.status === "active",
            app_icon_added,
            native_navigation_once_enabled,
            onesignal_once_enabled,
          },
          { appendLicense: true }
        );

        dispatch(
          projectsApi.util.updateQueryData(
            "getProject",
            { privateKey, headers: {} },
            (draft) => {
              draft.appBuilder = data.appBuilder;
              draft.appConfig = data.appConfig;
            }
          )
        );

        const nextAppBuilder = appBuilderUpdater(
          cloneDeep(appState.appBuilder),
          data.appBuilder.appBuilder
        );

        dispatch(appBuilderUpdated(nextAppBuilder));
        dispatch(appConfigUpdated(data.appConfig));
        dispatch(projectUpdated(data.appBuilder.project));
      },
      transformResponse: (response: ApiBuildProject) => {
        const transformedResponse = { ...response };

        if (transformedResponse?.appBuilder?.appBuilder) {
          transformedResponse.appBuilder.appBuilder = deserializeAppBuilder(
            transformedResponse.appBuilder.appBuilder
          );
        }

        return transformedResponse;
      },
    }),

    saveProject: build.mutation<ApiSaveProject, ApiSaveProjectArgs>({
      invalidatesTags: [TagType.AppBuilderStatus],
      query: ({ privateKey, data }) => {
        if ("appBuilder" in data) {
          return {
            url: `${urls.api21}/project/${privateKey}`,
            method: "POST",
            body: {
              appBuilder: serializeAppBuilder(data.appBuilder),
              status: "draft",
            },
          };
        } else {
          return {
            url: `${urls.api21}/project/${privateKey}`,
            method: "POST",
            body: {
              appConfig: data.appConfig,
              status: "draft",
            },
          };
        }
      },
      onQueryStarted: async (
        { privateKey },
        { dispatch, getState, queryFulfilled }
      ) => {
        const appState = getState() as AppState;

        const { data } = await queryFulfilled;

        let app_icon_added: true | undefined;
        let native_navigation_once_enabled: true | undefined;
        let onesignal_once_enabled: true | undefined;

        if (
          appIconUploaded(
            appState.meta.cachedAppBuilder as AppBuilder,
            data.appBuilder.appBuilder
          )
        ) {
          trackEvent("app_icon_added");
          app_icon_added = true;
        }

        if (
          (appState.meta.cachedAppBuilder?.topNavigationBar?.enable?.ios
            ?.active !==
            data.appBuilder.appBuilder?.topNavigationBar?.enable?.ios?.active &&
            data.appBuilder.appBuilder?.topNavigationBar?.enable?.ios
              ?.active) ||
          (appState.meta.cachedAppBuilder?.topNavigationBar?.enable?.android
            ?.active !==
            data.appBuilder.appBuilder?.topNavigationBar?.enable?.android
              ?.active &&
            data.appBuilder.appBuilder?.topNavigationBar?.enable?.android
              ?.active) ||
          (appState.meta.cachedAppBuilder?.sidebarNavigationBar?.active !==
            data.appBuilder.appBuilder?.sidebarNavigationBar?.active &&
            data.appBuilder.appBuilder?.sidebarNavigationBar?.active) ||
          (appState.meta.cachedAppBuilder?.bottomTabBar?.active !==
            data.appBuilder.appBuilder?.bottomTabBar?.active &&
            data.appBuilder.appBuilder?.bottomTabBar?.active)
        ) {
          trackEvent("native_navigation_enabled");
          native_navigation_once_enabled = true;
        }

        if (
          appState.meta.cachedAppBuilder?.pushNotifications?.oneSignal?.config
            ?.active !==
            data.appBuilder.appBuilder?.pushNotifications?.oneSignal?.config
              ?.active &&
          data.appBuilder.appBuilder?.pushNotifications?.oneSignal?.config
            ?.active
        ) {
          trackEvent("onesignal_enabled");
          onesignal_once_enabled = true;
        }

        trackEvent("success_update_project");
        trackLead(
          {
            email:
              appState.user.email ||
              data.appBuilder.appBuilder?.overview?.email,
            privateKey,
            publicKey: data.appBuilder.project.publicKey,
            recent_app_name: data.appBuilder.appBuilder?.overview?.appName,
            recent_app_initial_url:
              data.appBuilder.appBuilder?.overview?.websiteUrl,
            licensed:
              data.appBuilder.appBuilder?.license?.app?.status === "active",
            app_icon_added,
            native_navigation_once_enabled,
            onesignal_once_enabled,
          },
          { appendLicense: true }
        );

        dispatch(
          projectsApi.util.updateQueryData(
            "getProject",
            { privateKey, headers: {} },
            (draft) => {
              draft.appBuilder = data.appBuilder;
              draft.appConfig = data.appConfig;
            }
          )
        );

        const nextAppBuilder = appBuilderUpdater(
          cloneDeep(appState.appBuilder),
          data.appBuilder.appBuilder
        );

        dispatch(appBuilderUpdated(nextAppBuilder));
        dispatch(appConfigUpdated(data.appConfig));
        dispatch(projectUpdated(data.appBuilder.project));
      },
      transformResponse: (response: ApiBuildProject) => {
        const transformedResponse = { ...response };

        if (transformedResponse?.appBuilder?.appBuilder) {
          transformedResponse.appBuilder.appBuilder = deserializeAppBuilder(
            transformedResponse.appBuilder.appBuilder
          );
        }

        return transformedResponse;
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetProjectQuery,
  useBuildProjectMutation,
  useCreateProjectMutation,
  useGetProjectStatusQuery,
  useLazyGetProjectQuery,
  useSaveProjectMutation,
  useLazyGetProjectStatusQuery,
} = projectsApi;

export default projectsApi;
