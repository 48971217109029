/* eslint-disable @typescript-eslint/no-explicit-any */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import set from "lodash/set";
import { DeepPartial } from "utility-types";
import * as yup from "yup";

import { appBuilderUpdater } from "utils/appBuilder";

import { AppState } from "../../constants";
import { selectAppBuilder } from "./utils";

export type BuildStatus =
  | "draft"
  | "queued"
  | "building"
  | "done"
  | "error"
  | "inactive";

export type BuildType = {
  appetize: "expired" | "buildRequested" | "current" | "outdated" | undefined;
  binary: "expired" | "buildRequested" | "current" | "outdated" | undefined;
  source: "expired" | "buildRequested" | "current" | "outdated" | undefined;
};

export type BuildError = {
  shortMessage: string;
  errorMessage: string;
  errorCode: number;
  platform: "ios" | "android";
  appName: string;
  privateKey: string;
  referenceNumber: number;
};

export type BuildState = {
  buildProgressLastUpdated: string;
  error?: BuildError | null;
  errorCode?: number;
  errorLogUrl?: string;
  progress: number;
  status: BuildStatus;
  type: BuildType;
  updatedByUser: string;
  updatedByServer: string;
};

export type LastBuildBy = {
  binary?: string;
  binaryBuiltByDeletedUser?: boolean;
  binaryBuiltBySuperUser?: boolean;
  source?: string;
  sourceBuiltByDeletedUser?: boolean;
  sourceBuiltBySuperUser?: boolean;
};

export const appBuilderBuildSchema = yup.object({
  googleServices: yup
    .object({
      ios: yup.object({
        infoPlist: yup.string().optional().nullable(),
      }),
      android: yup.object({
        json: yup.object().optional().nullable(),
        debug: yup.object().optional().nullable(),
        release: yup.object().optional().nullable(),
      }),
    })
    .optional(),
  appIdentifiers: yup
    .object({
      ios: yup.object({
        bundleId: yup.string().optional().nullable(),
      }),
      android: yup.object({
        packageName: yup
          .string()
          .matches(/^([a-z]\w*\.)*([a-z]\w*)$/i, {
            excludeEmptyString: true,
          })
          .optional(),
        versionName: yup.string().optional().nullable(),
      }),
    })
    .optional(),
});

export interface AppBuilderBuildState {
  notifications: boolean | undefined;
  license: {
    buildActive: boolean | undefined;
    buildExpiry: string | undefined;
    usageActive: boolean | undefined;
    usageExpiry: string | undefined;
  };
  googleServices?: {
    ios?: {
      infoPlist: string | undefined | null;
    };
    android?: {
      json: any | undefined | null;
    };
  };
  androidReleaseSigningCertificate: {
    sha1: string | undefined;
    sha256: string | undefined;
    hash: string | undefined;
  };
  androidSigningKey: string | undefined;
  appIdentifiers?: {
    ios: {
      bundleId: string | undefined | null;
    };
    android: {
      packageName: string | undefined;
      versionName: string | undefined | null;
    };
    isReadOnly: boolean;
  };
  androidBuild: BuildState;
  iosBuild: BuildState;
  androidLastBuiltDate: {
    binary: string | undefined;
    source: string | undefined;
  };
  androidLastBuiltBy?: LastBuildBy;
  iosLastBuiltDate: {
    binary: string | undefined;
    source: string | undefined;
  };
  iosLastBuiltBy?: LastBuildBy;
  developmentTools: {
    enableWebConsoleLogs: boolean;
  };
  downloadLinks: {
    androidLink: boolean | undefined;
    androidAppBundleLink: boolean | undefined;
    androidSource: boolean | undefined;
    iosLink: boolean | undefined;
    iosSource: boolean | undefined;
  };
  appListing: {
    ios: {
      title: string | undefined;
      url: string | undefined;
      updated?: string;
      released?: string;
    };
    android: {
      title: string | undefined;
      url: string | undefined;
      updated?: string;
      released?: string;
    };
  };
}

const initialBuildState: BuildState = {
  buildProgressLastUpdated: "",
  errorCode: 0,
  progress: 0,
  status: "draft",
  type: {
    appetize: undefined,
    binary: undefined,
    source: undefined,
  },
  updatedByUser: "",
  updatedByServer: "",
};

const initialState: AppBuilderBuildState = {
  notifications: undefined,
  license: {
    buildActive: undefined,
    buildExpiry: undefined,
    usageActive: undefined,
    usageExpiry: undefined,
  },
  googleServices: {
    android: {
      json: undefined,
    },
  },
  androidReleaseSigningCertificate: {
    sha1: undefined,
    sha256: undefined,
    hash: undefined,
  },
  androidSigningKey: "release",
  androidBuild: initialBuildState,
  iosBuild: initialBuildState,
  androidLastBuiltDate: {
    binary: undefined,
    source: undefined,
  },
  iosLastBuiltDate: {
    binary: undefined,
    source: undefined,
  },
  androidLastBuiltBy: {
    source: undefined,
    sourceBuiltBySuperUser: false,
  },
  iosLastBuiltBy: {
    source: undefined,
    sourceBuiltBySuperUser: false,
  },
  developmentTools: {
    enableWebConsoleLogs: false,
  },
  downloadLinks: {
    androidLink: undefined,
    androidAppBundleLink: undefined,
    androidSource: undefined,
    iosSource: undefined,
    iosLink: undefined,
  },
  appListing: {
    ios: {
      title: undefined,
      url: undefined,
      updated: undefined,
      released: undefined,
    },
    android: {
      title: undefined,
      url: undefined,
      updated: undefined,
      released: undefined,
    },
  },
};

const buildSlice = createSlice({
  name: "build",
  initialState,
  reducers: {
    buildUpdated: (
      state,
      action: PayloadAction<DeepPartial<AppBuilderBuildState>>
    ) => {
      appBuilderUpdater(state, action.payload);

      if (!state.googleServices?.android?.json) {
        set(state, "googleServices.android", {});
      }
      if (!state.googleServices?.ios?.infoPlist) {
        set(state, "googleServices.ios", {});
      }
    },
  },
});

export const { buildUpdated } = buildSlice.actions;

export const selectBuild = (state: AppState) => selectAppBuilder(state).build;
export const selectAndroidBuild = (state: AppState) =>
  selectBuild(state).androidBuild;
export const selectAppIdentifierStatus = (state: AppState) =>
  selectBuild(state).appIdentifiers?.isReadOnly;
export const selectIosBuild = (state: AppState) => selectBuild(state).iosBuild;
export const selectAndroidLastBuildBy = (state: AppState) =>
  selectBuild(state).androidLastBuiltBy;
export const selectIosLastBuildBy = (state: AppState) =>
  selectBuild(state).iosLastBuiltBy;
export const selectIosAppListingLastUpdated = (state: AppState) =>
  selectBuild(state).appListing.ios.updated ||
  selectBuild(state).appListing.ios.released;
export const selectAndroidAppListingLastUpdated = (state: AppState) =>
  selectBuild(state).appListing.android.updated ||
  selectBuild(state).appListing.android.released;

const buildReducer = buildSlice.reducer;

export default buildReducer;
