import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DeepPartial } from "utility-types";

import { appBuilderUpdater } from "utils/appBuilder";

import { AppState } from "../../constants";
import { selectAppBuilder } from "./utils";

export interface NativePluginLicense {
  buildActive?: boolean;
  buildExpiry?: string;
  usageActive?: boolean;
  usageExpiry?: string;
  supportActive?: boolean;
  supportExpiry?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type NativePluginConfig = { active?: boolean } & Record<string, any>;

export interface NativePlugin {
  androidReleaseKeyHash?: string;
  androidSha1CertificateFingerprint?: string;
  androidSha256CertificateFingerprint?: string;
  license?: NativePluginLicense;
  config?: NativePluginConfig;
}

export type GoogleNativePlugin = NativePlugin & {
  requiresGoogleServiceInfoPlist: boolean;
  requiresGoogleServicesJson: boolean;
};

export type ShopifyPlugin = NativePlugin & {
  storeLink: string;
};

export interface AppBuilderNativePluginsState {
  facebook?: {
    androidReleaseKeyHash?: string;
    androidSha1CertificateFingerprint?: string;
    androidSha256CertificateFingerprint?: string;
    license?: NativePluginLicense;
    config?: {
      active?: boolean;
      appId?: string;
      displayName?: string;
      androidLogin?: boolean;
      iosLogin?: boolean;
      iosClientToken?: string;
    };
  };
  admob?: NativePlugin;
  cardIO?: NativePlugin;
  documentScanner?: NativePlugin;
  barcode?: NativePlugin;
  scandit?: NativePlugin;
  contacts?: NativePlugin;
  appleIAP?: NativePlugin;
  googleIAP?: NativePlugin;
  backgroundLocation?: NativePlugin;
  chromecast?: NativePlugin;
  biometricAuthentication?: NativePlugin;
  downloadManager?: NativePlugin;
  share?: NativePlugin;
  localSettings?: NativePlugin;
  appsflyer?: NativePlugin;
  nfc?: NativePlugin;
  couples?: NativePlugin;
  rootDetect?: NativePlugin;
  beacon?: NativePlugin;
  firebaseAnalytics?: GoogleNativePlugin;
  kaltura?: NativePlugin;
  twilio?: NativePlugin;
  shopify?: ShopifyPlugin;
}

const initialState: AppBuilderNativePluginsState = {
  firebaseAnalytics: {
    requiresGoogleServiceInfoPlist: true,
    requiresGoogleServicesJson: true,
  },
};

const nativePluginsSlice = createSlice({
  name: "nativePlugins",
  initialState,
  reducers: {
    nativePluginsUpdated: (
      state,
      action: PayloadAction<DeepPartial<AppBuilderNativePluginsState>>
    ) => appBuilderUpdater(state, action.payload),
  },
});

export const { nativePluginsUpdated } = nativePluginsSlice.actions;

export const selectNativePlugins = (state: AppState) =>
  selectAppBuilder(state).nativePlugins;

const nativePluginsReducer = nativePluginsSlice.reducer;

export default nativePluginsReducer;
