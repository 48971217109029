import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { deepEqual } from "fast-equals";
import cloneDeep from "lodash/cloneDeep";
import { DeepPartial } from "utility-types";

import { appBuilderUpdater } from "utils/appBuilder";

import { AppState } from "../../constants";
import { selectAppBuilder } from "./utils";

export enum PushNotificationServices {
  oneSignal = "oneSignal",
  adjust = "adjust",
  cordial = "cordial",
  episerver = "episerver",
  ibmCloud = "ibmCloud",
  sfmc = "sfmc",
  xtremepush = "xtremepush",
}

export interface PushNotificationLicense {
  buildActive?: boolean;
  buildExpiry?: string;
  usageActive?: boolean;
  usageExpiry?: string;
  supportActive?: boolean;
  supportExpiry?: string;
}

export interface PushNotificationService {
  config?: unknown;
  license?: PushNotificationLicense;
  requiresGoogleServiceInfoPlist?: boolean;
  requiresGoogleServicesJson?: boolean;
}

export enum OneSignalVersion {
  v3 = "oneSignal",
  v5 = "oneSignalV5",
}

export interface AppBuilderPushNotificationsState {
  oneSignal?: PushNotificationService & {
    config?: {
      active?: boolean | undefined;
      androidNotificationIcon?: string | undefined;
      applicationId?: string | undefined;
      autoRegister?: boolean | undefined;
      requiresUserPrivacyConsent?: boolean | undefined;
      notificationExtension?: boolean | undefined;
      iosSoftPrompt?: boolean | undefined;
      tagsJsonUrl?: string | undefined;
      showForegroundNotifications?: boolean | undefined;
      clearNotificationsOnLaunch?: boolean | undefined;
      customSound1?: string | undefined;
      customSound2?: string | undefined;
      customSound3?: string | undefined;
      customSound4?: string | undefined;
      customSound5?: string | undefined;
    };
    version?: OneSignalVersion;
  };
  registration?: {
    active: boolean | undefined;
    endpoint: string | undefined;
    custom: boolean | undefined;
    regex: string | undefined;
  };
}

const initialState: AppBuilderPushNotificationsState = {
  oneSignal: {
    config: {},
    version: OneSignalVersion.v3,
  },
};

/* eslint-disable @typescript-eslint/no-explicit-any */
const pushNotificationsSlice = createSlice({
  name: "pushNotifications",
  initialState,
  reducers: {
    pushNotificationsUpdated: (
      state,
      action: PayloadAction<DeepPartial<AppBuilderPushNotificationsState>>
    ) => appBuilderUpdater(state, action.payload),
    pushNotificationsItemUpdated: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      const { key, value } = action.payload;
      const item = cloneDeep((state as any)[key]) || {};
      if (!deepEqual(item.config || {}, value)) {
        item.config = { ...value };
        (state as any)[key] = item;
      }
    },
  },
});

export const { pushNotificationsUpdated, pushNotificationsItemUpdated } =
  pushNotificationsSlice.actions;

export const selectPushNotifications = (state: AppState) =>
  selectAppBuilder(state).pushNotifications;

const pushNotificationsReducer = pushNotificationsSlice.reducer;

export default pushNotificationsReducer;
