/* eslint-disable @typescript-eslint/no-explicit-any */

import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { AxiosError } from "axios";
import get from "lodash/get";
import { toast } from "react-toastify";

import { BuildError, BuildState } from "stores/features/appBuilder/build";

import { getSignedUrl } from "./s3";

// eslint-disable-next-line import/order
import type { Translate } from "next-translate";

export function isFetchBaseQueryError(
  error: unknown
): error is FetchBaseQueryError {
  return typeof error === "object" && error != null && "status" in error;
}

function errorMatchesString(error: unknown, message: string) {
  return ["data", "data.error", "error.data.error", "response.data.error"].some(
    (key) => {
      const errorData = get(error, key);
      const regex = new RegExp(message, "i");
      return typeof errorData === "string" && regex.test(errorData);
    }
  );
}

export function errorMatchesMessage(
  error: unknown,
  message: string | string[]
) {
  if (typeof message === "string") {
    return errorMatchesString(error, message);
  }
  return message.some((i) => errorMatchesString(error, i));
}

export function getErrorStatus(error: unknown) {
  for (const key of ["status", "response.status"]) {
    const errorData = get(error, key);
    if (typeof errorData === "number") {
      return errorData;
    }
  }
  return undefined;
}

export function getErrorMessage(error: unknown) {
  for (const key of [
    "response.data.error",
    "response.data.message",
    "data.message",
  ]) {
    const errorData = get(error, key);
    if (typeof errorData === "string") {
      return errorData;
    }
  }
  return undefined;
}

export function isErrorWithMessage(
  error: unknown
): error is { message: string } {
  return (
    typeof error === "object" &&
    error != null &&
    "message" in error &&
    typeof (error as any).message === "string"
  );
}

interface HandleErrorOptions {
  t: Translate;
}

export function handleError(error: unknown, { t }: HandleErrorOptions) {
  if (error instanceof AxiosError) {
    const errorMessage = getErrorMessage(error);
    if (errorMessage) {
      toast(errorMessage, { type: "error" });
    } else if (error.response?.status === 500) {
      toast(t("errors.500"), { type: "error" });
    }
  } else if (isFetchBaseQueryError(error)) {
    if (error.status === "FETCH_ERROR") {
      toast(t("errors.FETCH_ERROR"), { type: "error" });
    } else if (error.status === 500) {
      const errorMessage = get(error, "data.error");
      if (errorMessage && typeof errorMessage === "string") {
        toast(errorMessage, { type: "error" });
      } else {
        toast(t("errors.500"), { type: "error" });
      }
    } else if ("error" in error && typeof error.error === "string") {
      toast(error.error, { type: "error" });
    } else if ("data" in error) {
      const errorMessage = get(error.data, "message");
      if (errorMessage) {
        toast(errorMessage, { type: "error" });
      } else {
        toast(get(error.data, "error", t("errors.400")), { type: "error" });
      }
    } else {
      toast(t("errors.400"), { type: "error" });
    }
  } else if (isErrorWithMessage(error)) {
    toast(error.message, { type: "error" });
  } else if (typeof error === "string") {
    toast(error, { type: "error" });
  }
}

export async function getBuildError(build: BuildState) {
  // No error
  if (build.status !== "error") {
    return null;
  }

  if (build.errorLogUrl) {
    try {
      const signedUrl = getSignedUrl(build.errorLogUrl);
      if (!signedUrl) return null;
      const response = await fetch(signedUrl);
      const error = await response.json();
      if (!error) return null;

      const referenceNumber = get(error, "referenceNumber", 0);
      const updatedByServer = Math.floor(
        new Date(build.updatedByServer).getTime() / 1000
      );
      if (referenceNumber === updatedByServer) {
        return error as BuildError;
      }
      return null;
    } catch {
      return null;
    }
  }

  return null;
}
