import UAParser from "ua-parser-js";

const uaParser = new UAParser();

export const isBrowser = typeof window !== "undefined";
export const isServer = !isBrowser;
export const isIos = uaParser.getOS().name === "iOS";

export const isDev = process.env.NEXT_PUBLIC_ENV !== "production";
export const baseURL = process.env.NEXT_PUBLIC_BASE_URL;
export const appetizeSecretKey = process.env.NEXT_PUBLIC_APPETIZE_SECRET;

export enum SECTION {
  manage = "manage",
  dashboard = "dashboard",
  overview = "overview",
  branding = "branding",
  interface = "interface",
  linkHandling = "linkHandling",
  permissions = "permissions",
  websiteOverrides = "websiteOverrides",
  nativeNavigation = "nativeNavigation",
  pushNotifications = "pushNotifications",
  nativePlugins = "nativePlugins",
  build = "build",
  annualPlan = "annualPlan",
  services = "services",
  license = "license",
  access = "access",
}
export enum ORGSECTION {
  apps = "apps",
  members = "members",
  settings = "settings",
}
