interface FormatNumberOptions {
  minimumFractionDigits?: number | undefined;
}

export function formatNumber(
  lang: string,
  value: number,
  { minimumFractionDigits = 0 }: FormatNumberOptions = {}
) {
  const formatter = new Intl.NumberFormat(lang, {
    minimumFractionDigits,
  });

  return formatter.format(value);
}

interface FormatPriceOptions {
  currency?: string | undefined;
  minimumFractionDigits?: number | undefined;
}

export function formatPrice(
  locale: string,
  value: number,
  { currency = "USD", minimumFractionDigits = 0 }: FormatPriceOptions = {}
) {
  const formatter = new Intl.NumberFormat(locale, {
    currency,
    minimumFractionDigits,
    style: "currency",
  });

  return formatter.format(value);
}

export function formatGoogleServiceInfoPlist(infoPlist?: string | null) {
  if (!infoPlist) return infoPlist;

  const newlineKeys = ["<!DOCTYPE", "<plist", "</plist", "<dict>", "</dict>"];
  const tabKeys = ["<key>", "<string>", "<false>", "<true>"];

  return [...newlineKeys, ...tabKeys].reduce((current, key) => {
    if (tabKeys.includes(key)) return current.split(key).join(`\n\t${key}`);
    return current.split(key).join(`\n${key}`);
  }, infoPlist);
}

export function formatTime(seconds: number) {
  const totalSeconds = Math.floor(seconds); // Use seconds directly
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const remainingSeconds = totalSeconds % 60;

  const paddedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const paddedSeconds =
    remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;

  return hours > 0
    ? `${hours}:${paddedMinutes}:${paddedSeconds}`
    : `${minutes}:${paddedSeconds}`;
}
