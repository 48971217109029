import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import urljoin from "url-join";
import { DeepPartial } from "utility-types";

import { TabBarValue } from "components/common/MenuBuilder/constants";
import { appBuilderUpdater } from "utils/appBuilder";

import { AppState } from "../../constants";
import { selectAppBuilder } from "./utils";

export const defaultBottomTabBarItems = (
  websiteUrl: string
): TabBarValue<"tabSelectionConfig", "tabMenus"> => ({
  tabMenus: [
    {
      items: [
        {
          icon: "fas fa-home",
          inactiveIcon: "far fa-home",
          label: "Home",
          url: websiteUrl,
        },
        {
          label: "News",
          icon: "fas fa-newspaper",
          inactiveIcon: "far fa-newspaper",
          url: urljoin(websiteUrl, "news"),
        },
        {
          label: "Directory",
          icon: "fas fa-address-book",
          inactiveIcon: "far fa-address-book",
          url: urljoin(websiteUrl, "directory"),
        },
      ],
      id: "1",
    },
  ],
  tabSelectionConfig: [
    {
      id: "1",
      regex: ".*",
      label: "Bottom Tab Bar Menu 1 - All pages",
    },
  ],
  active: true,
});

export interface AppBuilderBottomTabBarState {
  active: boolean;
  styling: {
    android: {
      backgroundColor: string;
      backgroundColorDark: string;
      textColor: string;
      textColorDark: string;
      indicatorColor: string;
      indicatorColorDark: string;
    };
    ios: {
      tintColor: string;
      tintColorDark: string;
      inactiveColor: string;
      inactiveColorDark: string;
    };
  };
  bottomTabBarItems: TabBarValue<"tabSelectionConfig", "tabMenus">;
}

const initialState: AppBuilderBottomTabBarState = {
  active: false,
  styling: {
    android: {
      backgroundColor: "#ffffff",
      backgroundColorDark: "#333333",
      textColor: "#949494",
      textColorDark: "#ffffff",
      indicatorColor: "#1A100B",
      indicatorColorDark: "#666666",
    },
    ios: {
      tintColor: "#f8f8f8",
      tintColorDark: "#000000",
      inactiveColor: "#A1A1A1",
      inactiveColorDark: "#818181",
    },
  },
  bottomTabBarItems: {
    active: true,
    tabMenus: [],
    tabSelectionConfig: [],
  },
};

const bottomTabBarSlice = createSlice({
  name: "bottomTabBar",
  initialState,
  reducers: {
    bottomTabBarUpdated: (
      state,
      action: PayloadAction<DeepPartial<AppBuilderBottomTabBarState>>
    ) => appBuilderUpdater(state, action.payload),
  },
});

export const { bottomTabBarUpdated } = bottomTabBarSlice.actions;

export const selectBottomTabBar = (state: AppState) =>
  selectAppBuilder(state).bottomTabBar;

const bottomTabBarReducer = bottomTabBarSlice.reducer;

export default bottomTabBarReducer;
