import { decamelize } from "humps";
import Link, { LinkProps } from "next/link";
import React, { HTMLAttributeAnchorTarget } from "react";
import urljoin from "url-join";

import { baseURL, isDev, SECTION } from "./constants";

export function toRelativeUrl<T extends string | null | undefined>(url: T): T {
  if (url && !url.startsWith("http")) {
    return urljoin("https://cdn.median.co", url) as T;
  }

  return url;
}

// Workaround for https://github.com/i18next/react-i18next/issues/1090#issuecomment-884927848.
// Note: link is reserved (https://react.i18next.com/latest/trans-component#alternative-usage-v-11-6-0)
interface TransLinkProps extends React.PropsWithChildren<LinkProps> {
  target?: HTMLAttributeAnchorTarget | undefined;
}

export const TransLink: React.FC<TransLinkProps> = ({
  children,
  href,
  target,
  ...otherProps
}) => (
  <Link {...otherProps} href={href || ""}>
    <a target={target}>{children}</a>
  </Link>
);

let base = baseURL || "https://test.median.co/";
if (!isDev) {
  base = "https://median.co/";
} else if (process.env.NEXT_PUBLIC_ENV === "incremental") {
  base = "https://test2.median.co/";
}

const api = urljoin(base, "/api/v2");

const api21 = urljoin(base, "/api/v2.1");

const app = urljoin(base, "/app");

const createApp = "/app";

const login = "/login";

const logout = "/logout";

const discuss = (slug = "") => urljoin(base, `/discuss/${slug}`);

const documentation = (slug = "") => urljoin(base, `/docs/${slug}`);

const installAppQrCode = (publicKey = "") =>
  urljoin(base, `/share/${publicKey}#apk`);

const license = "/license";

const root = "/";

const signup = "/signup";

const docs = "https://median.co/docs/overview";

const pricing = (step = "", query?: Record<string, string>, sessionId = "") => {
  if (sessionId) {
    return `/pricing?sessionId=${sessionId}`;
  }
  let url = "/pricing";
  if (query) {
    url += `?${new URLSearchParams(query).toString()}`;
  }
  if (step) {
    url += `#${step}`;
  }
  return url;
};

const terms = urljoin(base, "/terms");
const policy = urljoin(base, "/privacy");

const staticPath = (path: string) => `/static/${path}`;

const images = (path: string) => staticPath(`images/${path}`);

const manage = (privateKey = "") => urljoin(base, `/app/${privateKey}`);

const claim = (privateKey = "") => urljoin(base, `/app/${privateKey}/claim`);

const legacy = (privateKey = "") => urljoin(base, `/manage/app/${privateKey}`);

const share = (publicKey = "", device = "") => {
  if (device) {
    return urljoin(base, `/share/${publicKey}#${device}`);
  } else {
    return urljoin(base, `/share/${publicKey}`);
  }
};

const shopify = (privateKey = "") =>
  urljoin(base, `/api/v2/shopify/auth/connect?privateKey=${privateKey}`);

const clone = (publicKey = "") => urljoin(base, `/clone/${publicKey}`);

const appBuilder = (
  privateKey = "",
  section = "",
  subsection = "",
  id = ""
) => {
  let sections = [section];
  if (section.startsWith("backup/")) {
    sections = section.split("/");
  }
  const paths = [privateKey, ...sections, subsection]
    .filter((s) => !!s)
    .map((s) => encodeURIComponent(decamelize(s, { separator: "-" })))
    .join("/");
  return `/app/${paths}${id ? `#${id}` : ""}`;
};

const organization = (orgId = "", section = "", subsection = "", id = "") => {
  const sections = [section];
  const paths = [orgId, ...sections, subsection]
    .filter((s) => !!s)
    .map((s) => encodeURIComponent(decamelize(s, { separator: "-" })))
    .join("/");
  return `/organization/${paths}${id ? `#${id}` : ""}`;
};

const organizationUrl = (orgId?: string) =>
  urljoin(base, `/organization/${orgId}`);

const organizationAdd = () => urljoin(base, "/organization/add");

const downloadKeystore = (privateKey = "", type = "") =>
  urljoin(api, `/project/${privateKey}/admin/download/keystore/${type}`);

const uploadKeystore = (privateKey = "") =>
  urljoin(api, `/project/${privateKey}/admin/upload/upload-keystore`);

const accountUrl = (userId: string) => urljoin(base, `/account/${userId}`);

const backupUrl = (privateKey = "", version = 1) =>
  `/app/${privateKey}/backup/${version}/${SECTION.overview}`;

export const transporterUrl =
  "https://apps.apple.com/us/app/transporter/id1450874784";

export default {
  accountUrl,
  api,
  api21,
  app,
  appBuilder,
  backupUrl,
  base,
  claim,
  clone,
  createApp,
  discuss,
  docs,
  documentation,
  downloadKeystore,
  installAppQrCode,
  images,
  license,
  manage,
  legacy,
  login,
  logout,
  organization,
  organizationUrl,
  organizationAdd,
  policy,
  pricing,
  root,
  share,
  shopify,
  signup,
  static: staticPath,
  terms,
  transporterUrl,
  uploadKeystore,
};
