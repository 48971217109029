import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DeepPartial } from "utility-types";

import { appBuilderUpdater } from "utils/appBuilder";

import { AppState } from "../../constants";
import { selectAppBuilder } from "./utils";

export const defaultUserTrackingDescription =
  "Your data will be used to personalize your experience and at times to deliver personalized ads";

export interface AppBuilderPermissionsState {
  appTrackingTransparency: {
    ios: {
      active: boolean | undefined;
      consentOnLoad: boolean | undefined;
      userTrackingDescription?: string;
    };
  };
  locationServices: {
    ios?: {
      description?: string;
    };
    android: {
      active: boolean;
    };
  };
  webRtc: {
    android: {
      cameraActive: boolean;
      microphoneActive: boolean;
    };
  };
  downloadsDirectory: {
    android: {
      active: boolean;
    };
  };
  androidExtDirLegacyEnabled: boolean;
  backgroundAudio?: {
    ios: {
      active: boolean | undefined;
    };
  };
  camera?: {
    ios: {
      description?: string;
    };
  };
  microphone?: {
    ios: {
      description?: string;
    };
  };
  nativeBridgeAllowedUrls: string[];
}

const initialState: AppBuilderPermissionsState = {
  appTrackingTransparency: {
    ios: {
      active: undefined,
      consentOnLoad: true,
    },
  },
  locationServices: {
    ios: {
      description: "",
    },
    android: {
      active: true,
    },
  },
  webRtc: {
    android: {
      cameraActive: false,
      microphoneActive: false,
    },
  },
  downloadsDirectory: {
    android: {
      active: false,
    },
  },
  androidExtDirLegacyEnabled: false,
  camera: {
    ios: {
      description: "",
    },
  },
  microphone: {
    ios: {
      description: "",
    },
  },
  nativeBridgeAllowedUrls: [],
};

const permissionsSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    permissionsUpdated: (
      state,
      action: PayloadAction<DeepPartial<AppBuilderPermissionsState>>
    ) => appBuilderUpdater(state, action.payload),
  },
});

export const { permissionsUpdated } = permissionsSlice.actions;

export const selectPermissions = (state: AppState) =>
  selectAppBuilder(state).permissions;

const permissionsReducer = permissionsSlice.reducer;

export default permissionsReducer;
