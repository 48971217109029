import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DeepPartial } from "utility-types";

import { appBuilderUpdater } from "utils/appBuilder";

import {
  AppState,
  defaultAppIcon,
  splashscreenDefaultAppIconDark,
  splashscreenDefaultAppIconLight,
} from "../../constants";
import { selectAppBuilder } from "./utils";

export enum BrandingSplashScreenType {
  AppIcon = "appIcon",
  NewImage = "newImage",
  SolidColor = "solidColor",
  Animation = "animation",
}

export enum AnimationLoop {
  once = "once",
  infinite = "infinite",
}

export interface AppBuilderBrandingState {
  appIcon: {
    base: string | undefined;
    ios: string | undefined;
    android: string | undefined;
  };
  customIcons?: {
    css: string | undefined;
    font: string | undefined;
    fontelloConfigJson: string | undefined;
  };
  splashScreen: {
    ios: {
      animation: string | undefined;
      animationDark: string | undefined;
      animationFrame: string | undefined;
      animationFrameDark: string | undefined;
      backgroundColor: string;
      backgroundColorDark: string;
      launchImageRequireUpdate: boolean;
      sourceImage: string | undefined;
      sourceImageDark: string | undefined;
      type: BrandingSplashScreenType;
      animationLoop: AnimationLoop;
    };
    android: {
      animation: string | undefined;
      animationDark: string | undefined;
      animationFrame: string | undefined;
      animationFrameDark: string | undefined;
      backgroundColor: string;
      backgroundColorDark: string;
      launchImagesRequireUpdate: boolean;
      sourceImage: string | undefined;
      sourceImageDark: string | undefined;
      type: BrandingSplashScreenType;
      animationLoop: AnimationLoop;
    };
  };
  themeColors: {
    ios: {
      primaryColor: string;
      primaryColorDark: string;
    };
    android: {
      primaryColor: string;
      primaryColorDark: string;
    };
  };
  statusBar: {
    ios: {
      style: string;
      overlay: boolean;
      blur: boolean;
      backgroundColor: string;
      backgroundColorDark: string;
    };
    android: {
      style: string;
      overlay: boolean;
      backgroundColor: string;
      backgroundColorDark: string;
    };
  };
  systemNavBar: {
    android: {
      color: string;
      colorDark: string;
    };
  };
}

const initialState: AppBuilderBrandingState = {
  appIcon: {
    base: defaultAppIcon,
    ios: defaultAppIcon,
    android: defaultAppIcon,
  },
  splashScreen: {
    ios: {
      animation: undefined,
      animationDark: undefined,
      animationFrame: undefined,
      animationFrameDark: undefined,
      backgroundColor: "#FFFFFF",
      backgroundColorDark: "#0E0D08",
      launchImageRequireUpdate: false,
      sourceImage: splashscreenDefaultAppIconLight,
      sourceImageDark: splashscreenDefaultAppIconDark,
      type: BrandingSplashScreenType.NewImage,
      animationLoop: AnimationLoop.once,
    },
    android: {
      animation: undefined,
      animationDark: undefined,
      animationFrame: undefined,
      animationFrameDark: undefined,
      backgroundColor: "#FFFFFF",
      backgroundColorDark: "##1A100B",
      launchImagesRequireUpdate: false,
      sourceImage: splashscreenDefaultAppIconLight,
      sourceImageDark: splashscreenDefaultAppIconDark,
      type: BrandingSplashScreenType.NewImage,
      animationLoop: AnimationLoop.once,
    },
  },
  themeColors: {
    ios: {
      primaryColor: "#0E0D08",
      primaryColorDark: "#FFFFFF",
    },
    android: {
      primaryColor: "#009688",
      primaryColorDark: "#80cbc4",
    },
  },
  statusBar: {
    ios: {
      style: "auto",
      overlay: false,
      blur: false,
      backgroundColor: "#ffffffff",
      backgroundColorDark: "#000000",
    },
    android: {
      style: "auto",
      overlay: false,
      backgroundColor: "#5c5c5c",
      backgroundColorDark: "#333333",
    },
  },
  systemNavBar: {
    android: {
      color: "#FFFFFF",
      colorDark: "#000000",
    },
  },
};

const brandingSlice = createSlice({
  name: "branding",
  initialState,
  reducers: {
    brandingUpdated: (
      state,
      action: PayloadAction<DeepPartial<AppBuilderBrandingState>>
    ) => appBuilderUpdater(state, action.payload),
  },
});

export const { brandingUpdated } = brandingSlice.actions;

export const selectBranding = (state: AppState) =>
  selectAppBuilder(state).branding;
export const selectAppIcon = (state: AppState) =>
  selectBranding(state).appIcon.base;

const brandingReducer = brandingSlice.reducer;

export default brandingReducer;
