import { createReducer } from "@reduxjs/toolkit";
import set from "lodash/set";
import { combineReducers } from "redux";
import * as yup from "yup";

import projectsApi from "services/api/projects";
import { defaultAppIcon } from "stores/constants";
import { appBuilderDiscarder, appBuilderUpdater } from "utils/appBuilder";
import { formatGoogleServiceInfoPlist } from "utils/formatting";
import { migrateTopNavigationBar } from "utils/migrations";

import bannersReducer from "./banners";
import bottomTabBarReducer from "./bottomTabBar";
import brandingReducer, { BrandingSplashScreenType } from "./branding";
import buildReducer, { appBuilderBuildSchema } from "./build";
import contextualNavToolbarReducer from "./contextualNavToolbar";
import interfaceReducer from "./interface";
import licenseReducer from "./license";
import linkHandlingReducer from "./linkHandling";
import nativePluginsReducer from "./nativePlugins";
import overviewReducer, {
  appBuilderOverviewAuthSchema,
  appBuilderOverviewSchema,
} from "./overview";
import permissionsReducer from "./permissions";
import previewReducer from "./preview";
import pushNotificationsReducer from "./pushNotifications";
import servicesReducer from "./services";
import sidebarNavigationBarReducer from "./sidebarNavigationBar";
import supportReducer from "./support";
import topNavigationBarReducer from "./topNavigationBar";
import unlistedReducer from "./unlisted";
import {
  appBuilderDiscarded,
  appBuilderUpdated,
  formBuilderChanged,
} from "./utils";
import websiteOverridesReducer from "./websiteOverrides";

export const appBuilderSchema = yup.object({
  overview: appBuilderOverviewSchema,
  build: appBuilderBuildSchema,
});

export const appBuilderAuthSchema = yup.object({
  overview: appBuilderOverviewAuthSchema,
  build: appBuilderBuildSchema,
});

const combinedReducer = combineReducers({
  unlisted: unlistedReducer,
  overview: overviewReducer,
  branding: brandingReducer,
  contextualNavToolbar: contextualNavToolbarReducer,
  interface: interfaceReducer,
  linkHandling: linkHandlingReducer,
  permissions: permissionsReducer,
  websiteOverrides: websiteOverridesReducer,
  topNavigationBar: topNavigationBarReducer,
  sidebarNavigationBar: sidebarNavigationBarReducer,
  bottomTabBar: bottomTabBarReducer,
  pushNotifications: pushNotificationsReducer,
  nativePlugins: nativePluginsReducer,
  build: buildReducer,
  support: supportReducer,
  services: servicesReducer,
  license: licenseReducer,
  preview: previewReducer,
  banners: bannersReducer,
});

const appBuilderReducer = createReducer<ReturnType<typeof combinedReducer>>(
  // This is a Typescript workaround. This will always have a default value.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  {} as any,
  (builder) =>
    builder
      .addCase(appBuilderUpdated, (state, action) =>
        appBuilderUpdater(state, action.payload)
      )
      .addCase(appBuilderDiscarded, (state, action) =>
        appBuilderDiscarder(state, action.payload)
      )
      .addCase(formBuilderChanged, (state, action) => {
        set(state, action.payload.key, action.payload.value);
      })
      .addMatcher(
        projectsApi.endpoints.cloneProject.matchFulfilled,
        (state, { payload }) => {
          state.branding.appIcon.base = payload.icon;
          state.branding.appIcon.ios = payload.icon;
          state.branding.appIcon.android = payload.icon;

          state.branding.splashScreen.ios.launchImageRequireUpdate = true;
          state.branding.splashScreen.ios.sourceImage = payload.icon;
          state.branding.splashScreen.ios.sourceImageDark = payload.icon;

          state.branding.splashScreen.android.launchImagesRequireUpdate = true;
          state.branding.splashScreen.android.sourceImage = payload.icon;
          state.branding.splashScreen.android.sourceImageDark = payload.icon;

          state.overview.appName = payload.appName;
          state.overview.websiteUrl = payload.initialUrl;
        }
      )
      .addMatcher(
        projectsApi.endpoints.getProject.matchFulfilled,
        (state, { payload }) => {
          appBuilderUpdater(state, payload.appBuilder.appBuilder);

          if (state.build.googleServices?.ios?.infoPlist) {
            state.build.googleServices.ios.infoPlist =
              formatGoogleServiceInfoPlist(
                state.build.googleServices.ios.infoPlist
              );
          }

          if (
            state.branding.appIcon.ios !== defaultAppIcon &&
            state.branding.splashScreen.ios.type !==
              BrandingSplashScreenType.SolidColor &&
            state.branding.appIcon.ios ===
              state.branding.splashScreen.ios.sourceImage &&
            state.branding.appIcon.ios ===
              state.branding.splashScreen.ios.sourceImageDark
          ) {
            state.branding.splashScreen.ios.type =
              BrandingSplashScreenType.AppIcon;
          }

          if (
            state.branding.appIcon.android !== defaultAppIcon &&
            state.branding.splashScreen.android.type !==
              BrandingSplashScreenType.SolidColor &&
            state.branding.appIcon.android ===
              state.branding.splashScreen.android.sourceImage &&
            state.branding.appIcon.android ===
              state.branding.splashScreen.android.sourceImageDark
          ) {
            state.branding.splashScreen.android.type =
              BrandingSplashScreenType.AppIcon;
          }

          const migratedTopNavigationBar = migrateTopNavigationBar(
            state.topNavigationBar
          );
          if (state.topNavigationBar.customIcons) {
            state.topNavigationBar.customIcons.actions =
              migratedTopNavigationBar.actions;
            state.topNavigationBar.customIcons.actionSelection =
              migratedTopNavigationBar.actionSelection;
          }
          if (state.topNavigationBar.dynamicTitles) {
            state.topNavigationBar.dynamicTitles.items =
              migratedTopNavigationBar.dynamicTitles;
          }
        }
      )
      .addMatcher(
        projectsApi.endpoints.getProjectShare.matchFulfilled,
        (state, { payload }) => {
          state.branding.appIcon.base = payload.appConfig.styling?.icon;

          state.build.downloadLinks.androidLink = payload.androidLink;
          state.preview.appnumHashed = payload.appnumHashed;

          state.overview.appName = payload.appConfig.general?.appName || "";

          state.preview.appetizeAndroidPublicKey =
            payload.appetizeAndroidPublicKey || undefined;
          state.preview.appetizePublicKey =
            payload.appetizePublicKey || undefined;
        }
      )
      .addDefaultCase((state, action) => combinedReducer(state, action))
);
export default appBuilderReducer;
