// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_ENV = process.env.SENTRY_ENV;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    "https://3b3348dac42c462cbf82204eefb243cd@o1070082.ingest.sentry.io/6065651",
  enabled: process.env.NEXT_PUBLIC_ENV === "production",
  environment: SENTRY_ENV,
  // See https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/16
  ignoreErrors: [
    "Non-Error exception captured",
    "Non-Error promise rejection captured",
  ],
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});
