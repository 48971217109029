import { appUpdated, AppUser } from "stores/features/app";

import apiSlice from ".";

export interface ApiGetAppAccessArgs {
  headers?: Record<string, string>;
  privateKey: string;
}

export interface ApiGetAppAccess {
  isOrgOwner: boolean;
  isAppAdmin: boolean;
  users: AppUser[];
}

const appApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAppAccess: build.query<ApiGetAppAccess, ApiGetAppAccessArgs>({
      query: ({ privateKey, headers }) => ({
        url: `account/apps/access/${privateKey}`,
        headers,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            appUpdated({ isAppAdmin: data.isAppAdmin, users: data.users })
          );
        } catch {
          // NO OP
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const { useGetAppAccessQuery } = appApi;

export default appApi;
