import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DeepPartial } from "utility-types";

import { appBuilderUpdater } from "utils/appBuilder";

import { AppState } from "../../constants";
import { selectAppBuilder } from "./utils";

export type LicenseStatus =
  | "active"
  | "enterprise"
  | "fullService"
  | "card_declined"
  | "processing"
  | "expired"
  | "nonProduction"
  | "inactive";

export type LicenseSupportStatus =
  | "none"
  | "basic"
  | "appUpdates"
  | "appUpdatesPremium"
  | "enterprise"
  | "fullService"
  | "premium"
  | "custom"
  | "appUpdatesCustom";

export interface License {
  name: string;
  status: LicenseStatus | undefined;
  support: LicenseSupportStatus;
  expiryDate: string | undefined;
  subscriptionManagementLink: string | undefined;
}

export interface AppBuilderLicenseState {
  app: Omit<License, "name">;
  pushNotifications: Record<string, Omit<License, "support">>;
  nativePlugins: Record<string, Omit<License, "support">>;
  services: {
    appStorePublishing: Omit<
      License,
      "name" | "support" | "subscriptionManagementLink" | "expiryDate"
    >;
    assistedFullServiceConfig: Omit<
      License,
      "name" | "support" | "subscriptionManagementLink" | "expiryDate"
    >;
    fullServiceConfig: Omit<
      License,
      "name" | "support" | "subscriptionManagementLink" | "expiryDate"
    >;
    enterpriseServiceConfig: Omit<
      License,
      "name" | "support" | "subscriptionManagementLink" | "expiryDate"
    >;
    samEnterprise: Omit<
      License,
      "name" | "support" | "subscriptionManagementLink" | "expiryDate"
    >;
    developerOnboarding: Omit<
      License,
      "name" | "support" | "subscriptionManagementLink" | "expiryDate"
    >;
    virtualOnsite: Omit<
      License,
      "name" | "support" | "subscriptionManagementLink" | "expiryDate"
    >;
  };
  hasDevToolkit: boolean;
}

const initialState: AppBuilderLicenseState = {
  app: {
    status: undefined,
    support: "none",
    subscriptionManagementLink: undefined,
    expiryDate: undefined,
  },
  pushNotifications: {},
  nativePlugins: {},
  services: {
    appStorePublishing: { status: undefined },
    assistedFullServiceConfig: { status: undefined },
    fullServiceConfig: { status: undefined },
    enterpriseServiceConfig: { status: undefined },
    samEnterprise: { status: undefined },
    developerOnboarding: { status: undefined },
    virtualOnsite: { status: undefined },
  },
  hasDevToolkit: false,
};

const licenseSlice = createSlice({
  name: "license",
  initialState,
  reducers: {
    licenseUpdated: (
      state,
      action: PayloadAction<DeepPartial<AppBuilderLicenseState>>
    ) => appBuilderUpdater(state, action.payload),
  },
});

export const { licenseUpdated } = licenseSlice.actions;

export const selectLicense = (state: AppState) =>
  selectAppBuilder(state).license;

const licenseReducer = licenseSlice.reducer;

export default licenseReducer;
