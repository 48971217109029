import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DeepPartial } from "utility-types";

import { appBuilderUpdater } from "utils/appBuilder";

import { AppState } from "../../constants";
import { selectAppBuilder } from "./utils";

export enum PublishState {
  newGoNativeApp = "newGoNativeApp",
  updateGoNativeApp = "updateGoNativeApp",
  updateNonGoNativeApp = "updateNonGoNativeApp",
}

export interface PublishingHistoryItem {
  purchaseDate: string;
  publishingStatusState: number;
  publishingStatusText: string;
  publishingLicenseKey: string;
}

export interface AppBuilderServicesState {
  published: boolean | undefined;
  publishingHistory: PublishingHistoryItem[];
  publishingForm: {
    general: {
      state: PublishState;
      releaseDate: string | undefined;
      requireScreenshot: boolean;
      requireLoginCredentials: boolean;
      username: string | undefined;
      password: string | undefined;
      screenshotCreationInstructions: string | undefined;
    };
    ios: {
      primaryLanguage: string | undefined;
      appName: string | undefined;
      categoryPrimary: string | undefined;
      ageRating: string | undefined;
      externalContent: boolean;
      pricingAndAvailability: string | undefined;
      keywords: string | undefined;
      description: string | undefined;
      supportUrl: string | undefined;
      privacyPolicyUrl: string | undefined;
      copyrightLine: string | undefined;
      requireLoginCredentials: boolean;
      username: string | undefined;
      password: string | undefined;
      firstName: string | undefined;
      lastName: string | undefined;
      phoneNumber: string | undefined;
      email: string | undefined;
    };
    android: {
      primaryLanguage: string | undefined;
      appName: string | undefined;
      descriptionShort: string | undefined;
      descriptionLong: string | undefined;
      category: string | undefined;
      email: string | undefined;
      containsAds: boolean;
      newsApp: boolean;
      pricingAndAvailability: string | undefined;
      ageRating: string | undefined;
    };
  };
  appDevelopmentForm: {
    projectDetails?: string;
    customerNotesInternal?: string;
  };
}

const initialState: AppBuilderServicesState = {
  published: false,
  publishingHistory: [],
  publishingForm: {
    general: {
      state: PublishState.newGoNativeApp,
      releaseDate: "Immediately upon approval",
      requireScreenshot: false,
      requireLoginCredentials: false,
      username: undefined,
      password: undefined,
      screenshotCreationInstructions:
        "Guidance to our team on which pages to capture screenshots\nScreenshot 1:\nScreenshot 2:\n...",
    },
    ios: {
      primaryLanguage: undefined,
      appName: undefined,
      categoryPrimary: undefined,
      ageRating: undefined,
      externalContent: false,
      pricingAndAvailability: "Free and Global",
      keywords: undefined,
      description: undefined,
      supportUrl: undefined,
      privacyPolicyUrl: undefined,
      copyrightLine: undefined,
      requireLoginCredentials: false,
      username: undefined,
      password: undefined,
      firstName: undefined,
      lastName: undefined,
      phoneNumber: undefined,
      email: undefined,
    },
    android: {
      primaryLanguage: undefined,
      appName: undefined,
      descriptionShort: undefined,
      descriptionLong: undefined,
      category: undefined,
      email: "info@domain.com",
      containsAds: false,
      newsApp: false,
      pricingAndAvailability: "Free and Global",
      ageRating: undefined,
    },
  },
  appDevelopmentForm: {
    projectDetails: undefined,
    customerNotesInternal: undefined,
  },
};

const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    servicesUpdated: (
      state,
      action: PayloadAction<DeepPartial<AppBuilderServicesState>>
    ) => appBuilderUpdater(state, action.payload),
  },
});

export const { servicesUpdated } = servicesSlice.actions;

export const selectServices = (state: AppState) =>
  selectAppBuilder(state).services;
export const selectIsPublished = (state: AppState) =>
  selectServices(state).published;
export const selectPublishingHistory = (state: AppState) =>
  selectServices(state).publishingHistory;

export const selectProjectDetails = (state: AppState) =>
  selectServices(state).appDevelopmentForm.projectDetails;
export const selectCustomerNotesInternal = (state: AppState) =>
  selectServices(state).appDevelopmentForm.customerNotesInternal;

const servicesReducer = servicesSlice.reducer;

export default servicesReducer;
