/* eslint-disable no-underscore-dangle */

// window.gtag fix ref: https://stackoverflow.com/a/69396560

import sumBy from "lodash/sumBy";

import { CartState } from "stores/features/cart";

import { isDev } from "./constants";
import { filterUndefined } from "./objects";
import urls from "./urls";

type ParamsValue = boolean | number | string;

interface UetqFunction {
  (
    action: string,
    eventName: string,
    options?: { [key: string]: unknown }
  ): void;
  push: (...items: unknown[]) => number;
}

declare global {
  interface Window {
    // Google
    gtag(
      command: "event" | "set",
      eventName: string,
      params?: Record<
        string,
        | ParamsValue
        | Record<string, ParamsValue>
        | Record<string, ParamsValue>[]
      >
    ): void;
    gtag(
      command: "get",
      target: string,
      fieldName: string,
      callback: (field: string) => void
    ): void;

    // Hotjar
    hj(
      command: "identify",
      email: string,
      params: Record<
        string,
        | ParamsValue
        | Record<string, ParamsValue>
        | Record<string, ParamsValue>[]
      >
    ): void;

    lintrk: (action: string, options: { conversion_id: number }) => void;

    fbq: (
      command: string,
      eventName: string,
      options?: Record<string, unknown>
    ) => void;

    uetq: UetqFunction;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _hsq: ([string, any] | [string])[];
  }
}

/*
  GPS: Google Paid (?s=1)
  GOS: Google Organic (?s=2)
  HPD: Home Page Direct - came from the form on the home page (?url=*)
  DIR: Direct - Directly on /app not from homepage
*/

export type ProjectSource =
  | "GPS"
  | "GOS"
  | "BPS"
  | "BOS"
  | "YOS"
  | "LIP"
  | "LIO"
  | "HPD"
  | "DIR";

export type OValue = {
  r?: string; // Referrer
  u?: string; // initial path
  t: number; // timestamp
  g?: string; // gclid
  m?: string; // msclkid
  utm_campaign?: string;
  utm_medium?: string;
  utm_source?: string;
  utm_term?: string;
};

export type ProjectSession = {
  initialPath?: string;
  referrer?: string;
  timestamp: number;
  gclid?: string;
  msclkid?: string;
  utm_campaign?: string;
  utm_medium?: string;
  utm_source?: string;
  utm_term?: string;
};

function stringifyCart(cart: Partial<CartState>) {
  const value: string[] = [];

  if (cart.basicLicense) {
    value.push("App Distribution License");
  }
  if (cart.appConfigFullService) {
    value.push("Concierge App Development");
  }
  if (cart.assistedFullServiceConfig) {
    value.push("Assisted App Development");
  }
  if (cart.professionalPublishInitial) {
    value.push("App Store Publishing");
  }
  if (cart.professionalPublishUpdate) {
    value.push("App Store Publishing Update");
  }
  if (cart.professionalSupport) {
    value.push("App Support & Maintenance Plan");
  }
  cart.nativePlugins?.forEach(({ name }) => value.push(name));
  cart.pushNotifications?.forEach(({ name }) => value.push(name));

  return value.join(", ");
}

function supportsAnalytics() {
  return !isDev && document.cookie.indexOf("analytics-ignore") === -1;
}

interface LeadInput {
  email: string;
  firstname?: string;
  lastname?: string;

  // Needs pre-processing
  cartItems?: Partial<CartState>;
  privateKey?: string;
  publicKey?: string;
  purchaseItems?: Partial<CartState>;
  purchaseAmount?: number;
  cartPrice?: number;

  // Sent as-is
  android_apk_downloaded?: true;
  android_simulator_played?: true;
  android_source_downloaded?: true;
  app_create_page?: string;
  app_create_selection?: string;
  app_create_source?: ProjectSource;
  app_icon_added?: true;
  appconfig_edited?: "import" | "edit";
  haspurchased?: true;
  ios_simulator_played?: true;
  ios_source_downloaded?: true;
  licensed?: boolean;
  native_navigation_once_enabled?: true;
  onesignal_once_enabled?: true;
  recent_app_initial_url?: string;
  recent_app_name?: string;
  recent_purchase_amount?: number;

  // HubSpot lead properties
  recent_unlicensed_app?: string;
  recent_licensed_app?: string;
  recent_fullservice_app?: string;
  recent_enterprise_app?: string;
  recent_supported_app?: string;
  customer_type?: string;
}

export type Lead = Omit<
  LeadInput,
  "cartItems" | "privateKey" | "publicKey" | "purchaseItems"
> & {
  app_create_source_gps?: true; // True if app_create_source is GPS
  recent_app_manage_url?: string; // Based on privateKey
  recent_app_public_url?: string; // Based on publicKey
  recent_cart_item?: string; // Based on cartItems
  recent_purchase_item?: string; // Based on purchaseItems
  recent_cart_amount?: number; // Based on cart amount
};

interface TrackLeadOptions {
  appendLicense?: boolean;
}

interface TrackEventOptions {
  email?: string;
  firstName?: string;
  lastName?: string;
}

export function trackLead(
  lead: Readonly<LeadInput>,
  { appendLicense }: TrackLeadOptions = {}
) {
  const {
    cartItems,
    cartPrice,
    email,
    licensed,
    privateKey,
    publicKey,
    purchaseAmount,
    purchaseItems,
    ...leadRemaining
  } = lead;

  const customerType = lead.customer_type;

  if (!email) {
    return;
  }

  let leadActual: Lead = { ...leadRemaining, email, licensed };

  if (!licensed || !appendLicense) {
    leadActual.licensed = undefined;
  }

  if (publicKey) {
    leadActual.recent_app_public_url = urls.share(publicKey);
  }

  if (privateKey) {
    leadActual.recent_app_manage_url = urls.manage(privateKey);
  }

  if (leadActual.app_create_source === "GPS") {
    leadActual.app_create_source_gps = true;
  }

  if (cartItems) {
    leadActual.recent_cart_item = stringifyCart(cartItems);
  }

  if (purchaseItems) {
    leadActual.recent_purchase_item = stringifyCart(purchaseItems);
  }

  if (purchaseAmount) {
    leadActual.recent_purchase_amount = purchaseAmount;
  }

  if (cartPrice) {
    leadActual.recent_cart_amount = cartPrice;
  }

  if (customerType) {
    leadActual.customer_type = customerType;
  }

  leadActual = filterUndefined(leadActual);

  if (supportsAnalytics()) {
    if (window.hj) {
      const leadIdentify = { email, licensed };

      if (!leadIdentify.licensed) {
        leadIdentify.licensed = undefined;
      }

      window.hj("identify", lead.email, filterUndefined(leadIdentify));
    }

    window._hsq = window._hsq || [];
    window._hsq.push(["identify", leadActual]);
    window._hsq.push(["trackPageView"]);
  } else {
    console.log("[Analytics] Excluded lead", leadActual);
  }
}

export type EventName =
  | "android_apk" // Download APK
  | "android_app_bundle" // Download AAB
  | "android_simulator" // Start Android simulator
  | "android_source" // Download Android Source
  | "app_icon_added" // Upload Icon
  | "appconfig_edit" // App Config Edit - Save
  | "appconfig_export" // App Config Export
  | "appconfig_import" // App Config Import - Save
  | "cart_add" // Anything added to cart
  | "clone_create_click" // Click Start Building my App and cloning
  | "ios_simulator" // Start iOS simulator
  | "ios_source" // Download iOS Source
  | "native_navigation_enabled"
  | "new_create_click" // Click Start Building my App
  | "onesignal_enabled" // Enable OneSignal
  | "payment-success" // Successful Purchase
  | "success_update_project" // Click Rebuild App
  | "pricing_flow_self_service_tab" // Click on Self-Service tab
  | "pricing_flow_self_service_step_1" // Click on Customize a license
  | "pricing_flow_full_service_tab" // Click on Full-Service tab (only a click on tab, not page view)
  | "pricing_flow_full_service_step_1a" // Click on Assisted App Development
  | "pricing_flow_full_service_step_1b" // Click on Full-Service App Development
  | "pricing_flow_enterprise_tab" // Click on Enterprise tab
  | "pricing_flow_enterprise_call" // Click on Schedule a call
  | "pricing_flow_go_to_checkout" // Click on Go to Checkout
  | "backups_view" // Click "Manage App Backups"
  | "backups_save" // Click bookmark to save a backup
  | "backups_preview" // Click "Preview"
  | "backups_restore" // Click "Restore"
  | "get_user"; // Get current user data

export function trackEvent(
  eventName: EventName,
  { email, firstName, lastName }: TrackEventOptions = {}
) {
  if (supportsAnalytics()) {
    /*
    const payload = {
      send_to: "",
      value: 0,
    };
    const linkedInPayload = {
      conversion_id: 0,
    };
    const microsoftPayload = {
      value: 0,
    };

    const metaPayload = {
      value: 0,
      eventName: "",
    };
    */

    if (typeof window.gtag === "function") {
      if (email && (firstName || lastName)) {
        window.gtag("set", "user_data", {
          email,
          address: {
            firstName: firstName || "",
            lastName: lastName || "",
          },
        });
      }
      if (email && !firstName && !lastName) {
        window.gtag("set", "user_data", {
          email,
        });
      }
      window.gtag("event", eventName, { event_category: "user_interactions" });
    }

    /*

    if (eventName === "new_create_click") {
      payload.send_to = "AW-10785461575/UWbuCL34hpMYEMeq9JYo";
      payload.value = 0.1;
      linkedInPayload.conversion_id = 14398476;
      microsoftPayload.value = 0.2;
      metaPayload.value = 0.2;
      metaPayload.eventName = "CompleteRegistration";
    } else if (eventName === "success_update_project") {
      payload.send_to = "AW-10785461575/0dgZCLj5hpMYEMeq9JYo";
      payload.value = 0.02;
      linkedInPayload.conversion_id = 14398476;
      microsoftPayload.value = 0.1;
      metaPayload.value = 0.1;
      metaPayload.eventName = "StartTrial";
    } else if (eventName === "app_icon_added") {
      payload.send_to = "AW-10785461575/Ys0DCP7njJMYEMeq9JYo";
      payload.value = 1.0;
    } else if (eventName === "onesignal_enabled") {
      payload.send_to = "AW-10785461575/ng7JCJvAjJMYEMeq9JYo";
      payload.value = 2.0;
    } else if (eventName === "ios_simulator") {
      payload.send_to = "AW-10785461575/AdJwCJ7AjJMYEMeq9JYo";
      payload.value = 0.01;
      linkedInPayload.conversion_id = 14398476;
      microsoftPayload.value = 0.1;
      metaPayload.value = 0.1;
      metaPayload.eventName = "TutorialCompletion";
    } else if (eventName === "android_simulator") {
      payload.send_to = "AW-10785461575/D3UfCKHAjJMYEMeq9JYo";
      payload.value = 0.01;
      linkedInPayload.conversion_id = 14398476;
      microsoftPayload.value = 0.02;
      metaPayload.value = 0.02;
      metaPayload.eventName = "LevelAchieved";
    } else if (eventName === "ios_source") {
      payload.send_to = "AW-10785461575/KmVnCKTAjJMYEMeq9JYo";
      payload.value = 1.5;
      linkedInPayload.conversion_id = 14398476;
      microsoftPayload.value = 10;
      metaPayload.value = 10;
      metaPayload.eventName = "AchievementUnlocked";
    } else if (eventName === "cart_add") {
      payload.send_to = "AW-10785461575/k8B1CLf4hpMYEMeq9JYo";
      payload.value = 0.5;
      linkedInPayload.conversion_id = 14398476;
      microsoftPayload.value = 20;
      metaPayload.value = 20;
      metaPayload.eventName = "AddToCart";
    } else if (eventName === "pricing_flow_self_service_tab") {
      payload.send_to = "AW-10785461575/OgQ8CKfAjJMYEMeq9JYo";
      payload.value = 0.01;
    } else if (eventName === "pricing_flow_self_service_step_1") {
      payload.send_to = "AW-10785461575/51dwCKrAjJMYEMeq9JYo";
      payload.value = 0.05;
    } else if (eventName === "pricing_flow_full_service_tab") {
      payload.send_to = "AW-10785461575/V1YkCK3AjJMYEMeq9JYo";
      payload.value = 0.01;
    } else if (eventName === "pricing_flow_full_service_step_1a") {
      payload.send_to = "AW-10785461575/tBYgCLDAjJMYEMeq9JYo";
      payload.value = 0.1;
    } else if (eventName === "pricing_flow_full_service_step_1b") {
      payload.send_to = "AW-10785461575/WPnPCLPAjJMYEMeq9JYo";
      payload.value = 0.2;
    } else if (eventName === "pricing_flow_enterprise_tab") {
      payload.send_to = "AW-10785461575/McuqCLbAjJMYEMeq9JYo";
      payload.value = 0.01;
    } else if (eventName === "pricing_flow_enterprise_call") {
      payload.send_to = "AW-10785461575/bscaCLnAjJMYEMeq9JYo";
      payload.value = 0.3;
    } else if (eventName === "pricing_flow_go_to_checkout") {
      payload.send_to = "AW-10785461575/QHH6CLr4hpMYEMeq9JYo";
      payload.value = 1.0;
    }
    if (payload.value > 0 && typeof window.gtag === "function") {
      window.gtag("event", "conversion", payload);
    }
    if (
      linkedInPayload.conversion_id > 0 &&
      typeof window.lintrk === "function"
    ) {
      window.lintrk("track", { conversion_id: linkedInPayload.conversion_id });
    }
    if (microsoftPayload.value > 0) {
      window.uetq = window.uetq || [];
      window.uetq.push("event", eventName, {
        revenue_value: microsoftPayload.value,
        currency: "USD",
      });
    }
    if (metaPayload.value > 0 && typeof window.fbq === "function") {
      window.fbq("track", metaPayload.eventName, { value: metaPayload.value });
    }
    */
  } else {
    console.log(`[Analytics] Excluded event: ${eventName}`);
  }
}

// function to track user using user_id in google analytics
export function setGaUserId(gaUserId: string) {
  if (
    supportsAnalytics() &&
    typeof window.gtag === "function" &&
    gaUserId?.length > 0
  ) {
    window.gtag("set", "user_id", { user_id: gaUserId });
  }
}

export function trackPageView(path = "") {
  if (supportsAnalytics()) {
    if (window.gtag) {
      window.gtag("event", "page_view", {
        page_location: path || window.location.pathname,
      });
    }

    window._hsq = window._hsq || [];
    window._hsq.push(["setPath", path || window.location.pathname]);
    window._hsq.push(["trackPageView"]);
  } else {
    console.log(
      `[Analytics] Excluded page view: ${path || window.location.pathname}`
    );
  }
}

export interface PurchaseAnalytics {
  name: string;
  category:
    | "license"
    | "native plugin"
    | "push provider"
    | "service"
    | "support and maintenance";
  price: number;
}

export function trackLicensedCustomer() {
  if (supportsAnalytics()) {
    if (typeof window.gtag === "function") {
      window.gtag("event", "licenced_customers", {
        event_category: "user_interactions",
      });
      window.gtag("event", "conversion", {
        send_to: "AW-10785461575/75WZCIep5sUZEMeq9JYo",
        transaction_id: "",
      });
    }
    window.uetq = window.uetq || [];
    window.uetq.push("event", "licensed_customers", {});
  }
}

export function trackPurchase(
  cart: Readonly<Partial<CartState>>,
  supportPrice = 0
) {
  if (supportsAnalytics()) {
    const transId = Date.now();
    const purchases: PurchaseAnalytics[] = [];

    if (cart.appConfigFullService) {
      purchases.push({
        name: "Concierge App Development",
        category: "service",
        price: 5100,
      });
    }

    if (cart.assistedFullServiceConfig) {
      purchases.push({
        name: "Assisted App Development",
        category: "service",
        price: 1100,
      });
    }

    if (cart.basicLicense) {
      purchases.push({
        name: "App Distribution License",
        category: "license",
        price: 790,
      });
    }

    cart.nativePlugins?.forEach((nativePlugin) =>
      purchases.push({
        name: nativePlugin.name,
        category: "native plugin",
        price: nativePlugin.price,
      })
    );

    cart.pushNotifications?.forEach((pushNotification) =>
      purchases.push({
        name: pushNotification.name,
        category: "push provider",
        price: pushNotification.price,
      })
    );

    if (cart.professionalPublishInitial) {
      purchases.push({
        name: "App Store Publishing",
        category: "service",
        price: 700,
      });
    }

    if (cart.professionalPublishUpdate) {
      purchases.push({
        name: "App Store Publishing Update",
        category: "service",
        price: 350,
      });
    }

    if (cart.professionalSupport && supportPrice) {
      purchases.push({
        name: "App Support & Maintenance Plan",
        category: "support and maintenance",
        price: supportPrice,
      });
    }

    const totalPrice = sumBy(purchases, "price");

    if (window.gtag) {
      window.gtag("event", "purchase", {
        transaction_id: transId,
        affiliation: "GoNative",
        value: totalPrice,
        currency: "USD",
        tax: 0,
        shipping: 0,
        items: purchases.map((purchase, index) => ({
          id: transId,
          name: purchase.name,
          brand: "GoNative",
          category: purchase.category,
          variant: "GoNative",
          list_position: index + 1,
          quantity: 1,
          price: purchase.price,
        })),
      });
    }

    // Google Ad Words

    if (window.gtag) {
      window.gtag("event", "conversion", {
        send_to: "AW-10785461575/6z2nCJX08ZwaEMeq9JYo",
        value: totalPrice,
        currency: "USD",
        transaction_id: transId,
      });
    }

    if (typeof window.lintrk === "function") {
      window.lintrk("track", { conversion_id: 14398524 });
    }

    if (typeof window.fbq === "function") {
      window.fbq("track", "Purchase", { value: totalPrice, currency: "USD" });
    }

    window.uetq = window.uetq || [];
    window.uetq.push("event", "payment-success", {
      revenue_value: totalPrice,
      currency: "USD",
    });
  } else {
    console.log("[Analytics] Excluded purchase");
  }
}
