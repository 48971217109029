import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { BackupItem } from "services/api/backups";
import { AppState } from "stores/constants";

import { selectAppAccess } from "./app";

export interface BackupState {
  backups: BackupItem[];
  backupItem?: BackupItem;
  isFetching?: boolean;
}

const initialState: BackupState = {
  backups: [],
};

const backupsSlice = createSlice({
  name: "backups",
  initialState,
  reducers: {
    backupsUpdated: (state, action: PayloadAction<Partial<BackupState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { backupsUpdated } = backupsSlice.actions;

export const selectBackups = (state: AppState) => state.backups;
export const selectBackupsList = (state: AppState) =>
  selectBackups(state).backups;
export const selectBackupItem = (state: AppState) =>
  selectBackups(state).backupItem;
export const selectIsBackupPreview = (state: AppState) =>
  !!selectBackupItem(state) || selectAppAccess(state) === "viewer";
export const selectIsFetchingBackups = (state: AppState) =>
  selectBackups(state).isFetching;

const backupsReducer = backupsSlice.reducer;

export default backupsReducer;
