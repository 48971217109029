import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DeepPartial } from "utility-types";

import projectsApi from "services/api/projects";

import { AppState } from "../constants";

export interface AppConfigState {
  forms?: unknown;
  general?: {
    userAgentAdd?: string;
    enableWindowOpen?: boolean;
    forceScreenOrientation?: string | null;
    publicKey?: string;
    deviceRegKey?: string;
    appName?: string;
    initialUrl?: string;
  };
  navigation?: unknown;
  performance?: unknown;
  permissions?: unknown;
  publishing?: unknown;
  services?: unknown;
  styling?: {
    icon?: string;
  };
}

const initialState: AppConfigState = {};

const appConfigSlice = createSlice({
  name: "appConfig",
  initialState,
  reducers: {
    appConfigUpdated: (
      state,
      action: PayloadAction<DeepPartial<AppConfigState>>
    ) => ({ ...action.payload }),
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        projectsApi.endpoints.getProject.matchFulfilled,
        (state, { payload }) => ({ ...initialState, ...payload.appConfig })
      )
      .addMatcher(
        projectsApi.endpoints.getProjectShare.matchFulfilled,
        (state, { payload }) => ({ ...initialState, ...payload.appConfig })
      );
  },
});

export const { appConfigUpdated } = appConfigSlice.actions;

export const selectAppConfig = (state: AppState) => state.appConfig;

const appConfigReducer = appConfigSlice.reducer;

export default appConfigReducer;
