import React from "react";

export enum DeviceType {
  iphone = "iphone",
  androidPhone = "androidPhone",
  ipad = "ipad",
  androidTablet = "androidTablet",
  otherIos = "otherIos",
  otherAndroid = "otherAndroid",
}

export interface DeviceDimensions {
  portrait: {
    width: number;
    height: number;
  };
  landscape: {
    width: number;
    height: number;
  };
}

export interface Device {
  dimensions: DeviceDimensions;
  iframeRef: React.RefObject<HTMLIFrameElement> | null;
  isLaunched: boolean;
  model: string;
  orientation: "portrait" | "landscape";
  os: "ios" | "android";
  osVersion: string;
  type: DeviceType;
  connectionString?: string;
  sessionToken?: string;
  remainingTime?: string;
}

export const deviceScale = 75;

export const defaultDeviceDimensions: {
  [key in DeviceType]: { [model: string]: DeviceDimensions };
} = {
  [DeviceType.iphone]: {
    iphone8: {
      portrait: {
        width: (416 * deviceScale) / 100,
        height: (870 * deviceScale) / 100,
      },
      landscape: {
        width: (870 * deviceScale) / 100,
        height: (416 * deviceScale) / 100,
      },
    },
    iphone11pro: {
      portrait: {
        width: (440 * deviceScale) / 100,
        height: (860 * deviceScale) / 100,
      },
      landscape: {
        width: (860 * deviceScale) / 100,
        height: (440 * deviceScale) / 100,
      },
    },
    iphone12: {
      portrait: {
        width: (440 * deviceScale) / 100,
        height: (884 * deviceScale) / 100,
      },
      landscape: {
        width: (884 * deviceScale) / 100,
        height: (440 * deviceScale) / 100,
      },
    },
    iphone13pro: {
      portrait: {
        width: (440 * deviceScale) / 100,
        height: (884 * deviceScale) / 100,
      },
      landscape: {
        width: (884 * deviceScale) / 100,
        height: (440 * deviceScale) / 100,
      },
    },
    iphone13promax: {
      portrait: {
        width: (481 * deviceScale) / 100,
        height: (971 * deviceScale) / 100,
      },
      landscape: {
        width: (886 * deviceScale) / 100,
        height: (971 * deviceScale) / 100,
      },
    },
    iphone14pro: {
      portrait: {
        width: (439 * deviceScale) / 100,
        height: (892 * deviceScale) / 100,
      },
      landscape: {
        width: (892 * deviceScale) / 100,
        height: (439 * deviceScale) / 100,
      },
    },
    iphone14promax: {
      portrait: {
        width: (482 * deviceScale) / 100,
        height: (974 * deviceScale) / 100,
      },
      landscape: {
        width: (974 * deviceScale) / 100,
        height: (482 * deviceScale) / 100,
      },
    },
    iphone15pro: {
      portrait: {
        width: (439 * deviceScale) / 100,
        height: (887 * deviceScale) / 100,
      },
      landscape: {
        width: (887 * deviceScale) / 100,
        height: (439 * deviceScale) / 100,
      },
    },
    iphone15promax: {
      portrait: {
        width: (482 * deviceScale) / 100,
        height: (967 * deviceScale) / 100,
      },
      landscape: {
        width: (967 * deviceScale) / 100,
        height: (482 * deviceScale) / 100,
      },
    },
    iphone16pro: {
      portrait: {
        width: (439 * deviceScale) / 100,
        height: (892 * deviceScale) / 100,
      },
      landscape: {
        width: (892 * deviceScale) / 100,
        height: (439 * deviceScale) / 100,
      },
    },
    iphone16promax: {
      portrait: {
        width: (474 * deviceScale) / 100,
        height: (984 * deviceScale) / 100,
      },
      landscape: {
        width: (984 * deviceScale) / 100,
        height: (474 * deviceScale) / 100,
      },
    },
  },
  [DeviceType.androidPhone]: {
    nexus5: {
      portrait: {
        width: (400 * deviceScale) / 100,
        height: (795 * deviceScale) / 100,
      },
      landscape: {
        width: (795 * deviceScale) / 100,
        height: (400 * deviceScale) / 100,
      },
    },
    pixel4: {
      portrait: {
        width: (397 * deviceScale) / 100,
        height: (840 * deviceScale) / 100,
      },
      landscape: {
        width: (840 * deviceScale) / 100,
        height: (397 * deviceScale) / 100,
      },
    },
    pixel4xl: {
      portrait: {
        width: (531 * deviceScale) / 100,
        height: (1119 * deviceScale) / 100,
      },
      landscape: {
        width: (1119 * deviceScale) / 100,
        height: (531 * deviceScale) / 100,
      },
    },
    pixel6: {
      portrait: {
        width: (404 * deviceScale) / 100,
        height: (852 * deviceScale) / 100,
      },
      landscape: {
        width: (852 * deviceScale) / 100,
        height: (404 * deviceScale) / 100,
      },
    },
    pixel6pro: {
      portrait: {
        width: (508 * deviceScale) / 100,
        height: (1092 * deviceScale) / 100,
      },
      landscape: {
        width: (1092 * deviceScale) / 100,
        height: (508 * deviceScale) / 100,
      },
    },
    pixel7: {
      portrait: {
        width: (404 * deviceScale) / 100,
        height: (852 * deviceScale) / 100,
      },
      landscape: {
        width: (852 * deviceScale) / 100,
        height: (404 * deviceScale) / 100,
      },
    },
    pixel7pro: {
      portrait: {
        width: (508 * deviceScale) / 100,
        height: (1092 * deviceScale) / 100,
      },
      landscape: {
        width: (1092 * deviceScale) / 100,
        height: (508 * deviceScale) / 100,
      },
    },
    pixel8: {
      portrait: {
        width: (449 * deviceScale) / 100,
        height: (955 * deviceScale) / 100,
      },
      landscape: {
        width: (955 * deviceScale) / 100,
        height: (449 * deviceScale) / 100,
      },
    },
    pixel8pro: {
      portrait: {
        width: (485 * deviceScale) / 100,
        height: (1031 * deviceScale) / 100,
      },
      landscape: {
        width: (1031 * deviceScale) / 100,
        height: (485 * deviceScale) / 100,
      },
    },
  },
  [DeviceType.ipad]: {
    ipadair4thgeneration: {
      portrait: {
        width: (924 * deviceScale) / 100,
        height: (1280 * deviceScale) / 100,
      },
      landscape: {
        width: (1280 * deviceScale) / 100,
        height: (924 * deviceScale) / 100,
      },
    },
    ipadpro129inch5thgeneration: {
      portrait: {
        width: (1122 * deviceScale) / 100,
        height: (1464 * deviceScale) / 100,
      },
      landscape: {
        width: (1464 * deviceScale) / 100,
        height: (1122 * deviceScale) / 100,
      },
    },
    ipad9thgeneration: {
      portrait: {
        width: (904 * deviceScale) / 100,
        height: (1290 * deviceScale) / 100,
      },
      landscape: {
        width: (1290 * deviceScale) / 100,
        height: (904 * deviceScale) / 100,
      },
    },
    ipadmini6thgeneration: {
      portrait: {
        width: (874 * deviceScale) / 100,
        height: (1265 * deviceScale) / 100,
      },
      landscape: {
        width: (1265 * deviceScale) / 100,
        height: (874 * deviceScale) / 100,
      },
    },
  },
  [DeviceType.androidTablet]: {
    galaxytabs7: {
      portrait: {
        width: (718 * deviceScale) / 100,
        height: (1099 * deviceScale) / 100,
      },
      landscape: {
        width: (1099 * deviceScale) / 100,
        height: (718 * deviceScale) / 100,
      },
    },
  },
  [DeviceType.otherIos]: {
    otherIos: {
      portrait: {
        width: 0,
        height: 0,
      },
      landscape: {
        width: 0,
        height: 0,
      },
    },
  },
  [DeviceType.otherAndroid]: {
    otherAndroid: {
      portrait: {
        width: 0,
        height: 0,
      },
      landscape: {
        width: 0,
        height: 0,
      },
    },
  },
};

export const deviceTypeOptions = {
  [DeviceType.iphone]: {
    iphone8: ["15.5", "16.2"],
    iphone11pro: ["15.5", "16.2", "17.2", "18.0"],
    iphone12: ["15.5", "16.2", "17.2", "18.0"],
    iphone13pro: ["15.5", "16.2", "17.2", "18.0"],
    iphone13promax: ["15.5", "16.2", "17.2", "18.0"],
    iphone14pro: ["16.2", "17.2", "18.0"],
    iphone14promax: ["16.2", "17.2", "18.0"],
    iphone15pro: ["17.2", "18.0"],
    iphone15promax: ["17.2", "18.0"],
    iphone16pro: ["18.0"],
    iphone16promax: ["18.0"],
  },
  [DeviceType.ipad]: {
    ipadair4thgeneration: ["15.5", "16.2", "17.2", "18.0"],
    ipadpro129inch5thgeneration: ["15.5", "16.2", "17.2", "18.0"],
    ipad9thgeneration: ["15.5", "16.2", "17.2"],
    ipadmini6thgeneration: ["18.0"],
  },
  [DeviceType.androidPhone]: {
    nexus5: ["8.1", "9.0", "10.0", "11.0"],
    pixel4: ["10.0", "11.0", "12.0"],
    pixel4xl: ["10.0", "11.0", "12.0"],
    pixel6: ["12.0", "13.0", "14.0", "15.0"],
    pixel6pro: ["12.0", "13.0", "14.0", "15.0"],
    pixel7: ["13.0", "14.0", "15.0"],
    pixel7pro: ["13.0", "14.0", "15.0"],
    pixel8: ["14.0", "15.0"],
    pixel8pro: ["14.0", "15.0"],
  },
  [DeviceType.androidTablet]: {
    galaxytabs7: ["10.0", "11.0", "12.0", "13.0"],
  },
};

export const defaultDeviceTypes = [
  "iphone14Pro",
  "ipadair4thgeneration",
  "pixel8",
  "galaxytabs7",
];

export enum DefaultModel {
  iphone = "iphone14pro",
  androidPhone = "pixel8",
  ipad = "ipadair4thgeneration",
  androidTablet = "galaxytabs7",
}
