import compact from "lodash/compact";

import {
  DynamicTitleItem,
  MenusItem,
  SelectionConfigItem,
} from "components/common/VisualEditor/constants";

interface MigrateTopNavigationBarInput {
  customIcons?: {
    actions?: MenusItem[];
    actionSelection?: SelectionConfigItem[];
  };
  dynamicTitles?: {
    items?: DynamicTitleItem[];
  };
}

export const migrateTopNavigationBar = (
  input: MigrateTopNavigationBarInput
) => {
  const actions = compact(input.customIcons?.actions || []);
  const actionSelection = compact(input.customIcons?.actionSelection || []);
  const dynamicTitles = compact(input.dynamicTitles?.items || []);

  let id = 0;

  const actionItems: MenusItem[] = [];
  const configItems: SelectionConfigItem[] = [];
  const titleItems: DynamicTitleItem[] = [];
  const mergedConfigIds: (string | number)[] = [];
  const mergedTitleIds: (string | number)[] = [];

  const allPagesTitle = dynamicTitles.find((i) => i.regex === ".*");
  const allPagesConfig = actionSelection.find((i) => i.regex === ".*");
  const allPagesAction = actions.find((i) => i.id === allPagesConfig?.id);

  const processDynamicTitles = () => {
    dynamicTitles.forEach((item) => {
      if (mergedTitleIds.includes(item.id || "")) {
        return;
      }

      id += 1;

      // Add dynamic title
      if (typeof item.customTitle !== "undefined") {
        titleItems.push({ ...item, id: `${id}` });
      } else {
        titleItems.push({ ...item, id: `${id}`, customTitle: !!item.title });
      }

      const configMatch =
        actionSelection.find((i) => i.id === item.id) ||
        actionSelection.find((i) => i.regex === item.regex);

      // Config exists
      if (configMatch) {
        mergedConfigIds.push(configMatch.id || "");

        // Add config
        configItems.push({ ...configMatch, id: `${id}` });

        // Add action
        const actionMatch = actions.find((i) => i.id === configMatch.id);
        if (actionMatch) {
          actionItems.push({ ...actionMatch, id: `${id}` });
        } else {
          actionItems.push({ id: `${id}`, items: [] });
        }
        return;
      }

      // Add new config
      configItems.push({
        id: `${id}`,
        custom: !!item.custom,
        regex: item.regex,
        url: item.url,
      });

      // All pages config exists
      if (allPagesConfig) {
        // Add action
        if (allPagesAction) {
          actionItems.push({ ...allPagesAction, id: `${id}` });
        } else {
          actionItems.push({ id: `${id}`, items: [] });
        }
        return;
      }

      // Add new action
      actionItems.push({ id: `${id}`, items: [] });
    });
  };

  const processActionSelection = () => {
    actionSelection.forEach((item) => {
      if (mergedConfigIds.includes(item.id || "")) {
        return;
      }

      id += 1;

      // Add config
      configItems.push({ ...item, id: `${id}` });

      // Add action
      const actionMatch = actions.find((i) => i.id === item.id);
      if (actionMatch) {
        actionItems.push({ ...actionMatch, id: `${id}` });
      } else {
        actionItems.push({ id: `${id}`, items: [] });
      }

      const titleMatch =
        dynamicTitles.find((i) => i.id === item.id) ||
        dynamicTitles.find((i) => i.regex === item.regex);

      if (titleMatch) {
        mergedTitleIds.push(titleMatch.id || "");

        // Add dynamic title
        if (typeof titleMatch.customTitle !== "undefined") {
          titleItems.push({ ...titleMatch, id: `${id}` });
        } else {
          titleItems.push({
            ...titleMatch,
            id: `${id}`,
            customTitle: !!titleMatch.title,
          });
        }
        return;
      }

      if (allPagesTitle) {
        // Add dynamic title
        if (typeof allPagesTitle.customTitle !== "undefined") {
          titleItems.push({ ...allPagesTitle, id: `${id}` });
        } else {
          titleItems.push({
            ...allPagesTitle,
            id: `${id}`,
            custom: !!item.custom,
            regex: item.regex,
            url: item.url,
          });
        }
        return;
      }

      // Add dynamic title
      titleItems.push({
        id: `${id}`,
        custom: !!item.custom,
        regex: item.regex,
        url: item.url,
        customTitle: false,
      });
    });
  };

  // Special case
  if (
    dynamicTitles.length === 1 &&
    dynamicTitles[0].regex === ".*" &&
    actionSelection.length > 1
  ) {
    processActionSelection();
    processDynamicTitles();
  } else {
    processDynamicTitles();
    processActionSelection();
  }

  return {
    actions: actionItems,
    actionSelection: configItems,
    dynamicTitles: titleItems,
  };
};
