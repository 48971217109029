import {
  createApi,
  FetchArgs,
  fetchBaseQuery,
  retry,
} from "@reduxjs/toolkit/query/react";
import { HYDRATE } from "next-redux-wrapper";

import { errorMatchesMessage } from "utils/errors";
import urls from "utils/urls";

import { BaseQueryFnType, CUSTOM_API_ERRORS, TagType } from "./constants";

const staggeredBaseQueryWithBailOut = retry(
  (async (args: string | FetchArgs, api, extraOptions) => {
    const result = await fetchBaseQuery({ baseUrl: urls.api })(
      args,
      api,
      extraOptions
    );

    if (result.error && errorMatchesMessage(result.error, CUSTOM_API_ERRORS)) {
      retry.fail(result.error);
    }

    return result;
  }) as BaseQueryFnType,
  { maxRetries: 3 }
);

const baseApi = createApi({
  reducerPath: "api",
  tagTypes: Object.values(TagType),
  baseQuery: staggeredBaseQueryWithBailOut,
  endpoints: () => ({}),

  // eslint-disable-next-line consistent-return
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
});

export default baseApi;
