import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DeepPartial } from "utility-types";

import {
  AutoNewWindowsValue,
  DynamicTitleValue,
  TabBarValue,
} from "components/common/MenuBuilder/constants";
import { appBuilderUpdater } from "utils/appBuilder";

import { AppState, defaultAppIcon } from "../../constants";
import { selectAppBuilder } from "./utils";

export const defaultCustomIcons = (): TabBarValue<
  "actionSelection",
  "actions"
> => ({
  actions: [
    {
      id: "1",
      items: [],
    },
  ],
  actionSelection: [
    {
      id: "1",
      label: "Top Nav Bar Menu 1 - All Pages",
      regex: ".*",
      custom: false,
    },
  ],
  active: true,
});

export const defaultDynamicTitles = () => ({
  active: true,
  items: [{ id: "1", regex: ".*" }],
});

export interface AppBuilderTopNavigationBarState {
  enable: {
    ios: {
      active: boolean;
    };
    android: {
      active: boolean;
    };
  };
  styling: {
    ios: {
      defaultDisplay: "text" | "image";
      imageType: "appIcon" | "newImage";
      newImage: string | undefined;
      newImageDark: string | undefined;
      textColor: string;
      textColorDark: string;
      tintColor: string;
      tintColorDark: string;
    };
    android: {
      backgroundColor: string;
      backgroundColorDark: string;
      defaultDisplay: "text" | "image";
      imageType: "appIcon" | "newImage";
      newImage: string | undefined;
      newImageDark: string | undefined;
      textColor: string;
      textColorDark: string;
    };
  };
  autoNewWindows: {
    active: boolean;
    items: AutoNewWindowsValue[][];
  };
  dynamicTitles: {
    active: boolean;
    items: DynamicTitleValue[];
  };
  customIcons: TabBarValue<"actionSelection", "actions">;
}

const initialState: AppBuilderTopNavigationBarState = {
  enable: {
    ios: {
      active: true,
    },
    android: {
      active: true,
    },
  },
  styling: {
    ios: {
      defaultDisplay: "text",
      imageType: "appIcon",
      newImage: defaultAppIcon,
      newImageDark: defaultAppIcon,
      textColor: "#0E0D08",
      textColorDark: "#ffffff",
      tintColor: "#f8f8f8",
      tintColorDark: "#202020",
    },
    android: {
      backgroundColor: "#ffffff",
      backgroundColorDark: "#333333",
      defaultDisplay: "text",
      imageType: "appIcon",
      newImage: defaultAppIcon,
      newImageDark: defaultAppIcon,
      textColor: "#1A100B",
      textColorDark: "#ffffff",
    },
  },
  autoNewWindows: {
    active: true,
    items: [],
  },
  dynamicTitles: defaultDynamicTitles(),
  customIcons: defaultCustomIcons(),
};

const topNavigationBarSlice = createSlice({
  name: "topNavigationBar",
  initialState,
  reducers: {
    topNavigationBarUpdated: (
      state,
      action: PayloadAction<DeepPartial<AppBuilderTopNavigationBarState>>
    ) => appBuilderUpdater(state, action.payload),
  },
});

export const { topNavigationBarUpdated } = topNavigationBarSlice.actions;

export const selectTopNavigationBar = (state: AppState) =>
  selectAppBuilder(state).topNavigationBar;

const topNavigationBarReducer = topNavigationBarSlice.reducer;

export default topNavigationBarReducer;
