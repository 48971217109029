import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppState } from "stores/constants";

import { selectAppBuilder } from "./utils";

export interface BannerItem {
  id: string;
  category: string;
  display?: {
    cookie?: number;
    startDate?: number;
    endDate?: number;
  };
  type: string;
  message: string;
  button?: {
    label?: string;
    action?: string;
    uri?: string;
  };
}

export type BannerState = BannerItem[];

const initialState: BannerState = [];

const bannersSlice = createSlice({
  name: "banners",
  initialState,
  reducers: {
    bannersUpdated: (_state, action: PayloadAction<BannerState>) => [
      ...action.payload,
    ],
  },
});

export const { bannersUpdated } = bannersSlice.actions;

export const selectBanners = (state: AppState) =>
  selectAppBuilder(state).banners;

const bannersReducer = bannersSlice.reducer;

export default bannersReducer;
