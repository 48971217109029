import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DeepPartial } from "utility-types";

import { appBuilderUpdater } from "utils/appBuilder";

import { AppState } from "../../constants";
import { selectAppBuilder } from "./utils";

export enum DarkModeType {
  auto = "auto",
  light = "light",
  dark = "dark",
}

export enum LocalizationOptions {
  en = "en",
  ar = "ar",
  ca = "ca",
  zh_Hant = "zh-Hant",
  cs = "cs",
  da = "da",
  nl = "nl",
  fi = "fi",
  fr = "fr",
  gd = "gd",
  de = "de",
  he = "he",
  hu = "hu",
  it = "it",
  ja = "ja",
  ko = "ko",
  nn_NO = "nn-NO",
  pt_BR = "pt-BR",
  pt_PT = "pt-PT",
  es = "es",
  es_419 = "es-419",
  tr = "tr",
  ru = "ru",
  uk = "uk",
}

export enum ScreenOrientationType {
  portrait = "portrait",
  landscape = "landscape",
}

export enum OffLinePageType {
  disable = "disable",
  default = "default",
  custom = "custom",
  match = "match",
}

export interface AppBuilderInterfaceState {
  allowInsecure: boolean | undefined;
  keepScreenOn: boolean | undefined;
  fullScreen?: {
    ios: {
      active: boolean | undefined;
    };
    android: {
      active: boolean | undefined;
    };
  };
  darkMode: {
    ios: {
      value: DarkModeType;
    };
    android: {
      value: DarkModeType;
    };
  };
  screenOrientation: {
    iphone: ScreenOrientationType | null;
    ipad: ScreenOrientationType | null;
    androidPhone: ScreenOrientationType | null;
    androidTablet: ScreenOrientationType | null;
  };
  nativePageTransitions: {
    active: boolean;
    alpha: number;
  };
  pullToRefresh: {
    ios: {
      active: boolean;
    };
    android: {
      active: boolean;
      iconColor: string;
      // TODO:
      iconColorDark: string;
    };
  };
  activityIndicator?: {
    android?: {
      color?: string;
      colorDark?: string;
    };
    ios?: {
      color?: string;
      colorDark?: string;
    };
  };
  swipeGestures: {
    value: boolean | undefined;
    android: {
      backgroundColor: string;
      backgroundColorDark: string;
      activeColor: string;
      activeColorDark: string;
      inactiveColor: string;
      inactiveColorDark: string;
    };
  };
  pinchToZoom: {
    ios: {
      active: boolean | undefined;
    };
    android: {
      active: boolean | undefined;
    };
  };
  dynamicType: {
    ios: {
      active: boolean | undefined;
    };
  };
  fontScaling: {
    android: {
      value: number | null;
    };
  };
  maxWindows: {
    enabled?: boolean;
    numWindows?: number;
    autoClose?: boolean;
  };
  viewportWidth: number | undefined;
  localization: { languages: string[] };
  offlinePage: {
    ios: {
      value: OffLinePageType;
      connectionTimeoutThreshold: number | undefined;
      html: string | undefined;
      offlinePageUrl: string | undefined;
      offlinePageUrlExternal: string | undefined;
    };
    android: {
      value: OffLinePageType;
      connectionTimeoutThreshold: number | undefined;
      html: string | undefined;
      offlinePageUrl: string | undefined;
      offlinePageUrlExternal: string | undefined;
    };
  };
}

export const defaultActivityIndicatorColor = "#808080";

const initialState: AppBuilderInterfaceState = {
  allowInsecure: undefined,
  keepScreenOn: undefined,
  darkMode: {
    ios: {
      value: DarkModeType.auto,
    },
    android: {
      value: DarkModeType.auto,
    },
  },
  screenOrientation: {
    iphone: null,
    ipad: null,
    androidPhone: null,
    androidTablet: null,
  },
  nativePageTransitions: {
    active: true,
    alpha: 0.5,
  },
  pullToRefresh: {
    ios: {
      active: true,
    },
    android: {
      active: false,
      iconColor: "#1A100B",
      iconColorDark: "#ffffff",
    },
  },
  activityIndicator: {
    android: {
      color: defaultActivityIndicatorColor,
      colorDark: defaultActivityIndicatorColor,
    },
    ios: {
      color: defaultActivityIndicatorColor,
      colorDark: defaultActivityIndicatorColor,
    },
  },
  swipeGestures: {
    value: undefined,
    android: {
      backgroundColor: "#ffffff",
      backgroundColorDark: "#333333",
      activeColor: "#000000",
      activeColorDark: "#ffffff",
      inactiveColor: "#666666",
      inactiveColorDark: "#666666",
    },
  },
  pinchToZoom: {
    ios: {
      active: undefined,
    },
    android: {
      active: undefined,
    },
  },
  dynamicType: {
    ios: {
      active: undefined,
    },
  },
  fontScaling: {
    android: {
      value: 100,
    },
  },
  maxWindows: {
    enabled: false,
    numWindows: 5,
    autoClose: false,
  },
  viewportWidth: undefined,
  localization: { languages: [] },
  offlinePage: {
    ios: {
      value: OffLinePageType.default,
      connectionTimeoutThreshold: undefined,
      html: "",
      offlinePageUrl: undefined,
      offlinePageUrlExternal: undefined,
    },
    android: {
      value: OffLinePageType.default,
      connectionTimeoutThreshold: undefined,
      html: "",
      offlinePageUrl: undefined,
      offlinePageUrlExternal: undefined,
    },
  },
};

const interfaceSlice = createSlice({
  name: "interface",
  initialState,
  reducers: {
    interfaceUpdated: (
      state,
      action: PayloadAction<DeepPartial<AppBuilderInterfaceState>>
    ) => {
      const nextInterface = appBuilderUpdater(state, action.payload);

      if (
        nextInterface.offlinePage.ios.value !== OffLinePageType.disable &&
        typeof nextInterface.offlinePage.ios.connectionTimeoutThreshold !==
          "number"
      ) {
        nextInterface.offlinePage.ios.connectionTimeoutThreshold = 10;
      } else if (
        nextInterface.offlinePage.ios.value === OffLinePageType.disable
      ) {
        nextInterface.offlinePage.ios.connectionTimeoutThreshold = undefined;
      }

      if (
        nextInterface.offlinePage.android.value !== OffLinePageType.disable &&
        typeof nextInterface.offlinePage.android.connectionTimeoutThreshold !==
          "number"
      ) {
        nextInterface.offlinePage.android.connectionTimeoutThreshold = 10;
      } else if (
        nextInterface.offlinePage.android.value === OffLinePageType.disable
      ) {
        nextInterface.offlinePage.android.connectionTimeoutThreshold =
          undefined;
      }

      if (nextInterface.offlinePage.android.value === OffLinePageType.match) {
        nextInterface.offlinePage.android = {
          ...nextInterface.offlinePage.ios,
          value: OffLinePageType.match,
        };
      }

      if (nextInterface.offlinePage.ios.value === OffLinePageType.match) {
        nextInterface.offlinePage.ios = {
          ...nextInterface.offlinePage.android,
          value: OffLinePageType.match,
        };
      }

      return nextInterface;
    },
  },
});

export const { interfaceUpdated } = interfaceSlice.actions;

export const selectInterface = (state: AppState) =>
  selectAppBuilder(state).interface;

const interfaceReducer = interfaceSlice.reducer;

export default interfaceReducer;
