import { createAction } from "@reduxjs/toolkit";

import { AppState } from "stores/constants";
import { AppBuilderInput } from "utils/appBuilder";

export const selectAppBuilder = (state: AppState) => state.appBuilder;

export const appBuilderUpdated = createAction<AppBuilderInput>(
  "appBuilder/appBuilderUpdated"
);

export const appBuilderDiscarded = createAction<AppBuilderInput>(
  "appBuilder/appBuilderDiscarded"
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const formBuilderChanged = createAction<{ key: string; value: any }>(
  "appBuilder/formBuilderChanged"
);
