import { AppAccessType } from "stores/features/app";
import { overviewUpdated } from "stores/features/appBuilder/overview";
import {
  OrganizationInvitedMember,
  OrganizationMember,
  OrganizationMemberDetails,
  organizationMemberDetailsUpdated,
  organizationUpdated,
} from "stores/features/organization";
import { uiUpdated } from "stores/features/ui";
import App from "types/App";
import { errorMatchesMessage } from "utils/errors";

import apiSlice from ".";

interface ApiOrganizationArgs {
  headers?: Record<string, string>;
  orgId: string;
}

interface ApiGetApps {
  isOrgOwner: boolean;
  apps: App[];
  nextSkip: number | null;
}

interface ApiGetAppsArgs {
  headers?: Record<string, string>;
  orgId: string;
  limit?: number;
  skip?: number | null;
}

interface ApiGetSettings {
  isOrgOwner: boolean;
  organizationName: string;
}

interface ApiGetMemberDetailsArgs {
  orgId: string;
  userId: string;
}

interface ApiRemoveMemberArgs {
  organizationId: string;
  userId: string;
}

type ApiRemoveMember = {
  organizationMembers: OrganizationMember[];
};

type ApiAddOrganization = {
  organizationId: string;
};

type ApiAddOrganizationApp = {
  organizationName: string;
};

type ApiGetMembers = {
  isOrgOwner: boolean;
  organizationMembers: OrganizationMember[];
  invitedMembers: OrganizationInvitedMember[];
};

interface ApiUpdateMemberDetailsArgs {
  organizationId: string;
  userId: string;
  role: "owner" | "collaborator";
  appAccess: {
    appId: string;
    access: AppAccessType;
  }[];
}

interface ApiUpdateOrganizationSettingsArgs {
  organizationId: string;
  organizationName: string;
}

interface ApiInviteMemberArgs {
  organizationId: string;
  inviteeEmail: string;
  inviteeName: string;
  role: "owner" | "collaborator";
  apps: { appId: string; access: AppAccessType }[];
}

interface ApiInviteMember {
  invitedMembers: OrganizationInvitedMember[];
}

interface ApiResendInviteArgs {
  organizationId: string;
  inviteId: string;
}

interface ApiValidateInviteArgs {
  inviteId: string;
  headers: Record<string, string>;
}

interface ApiValidateInvite {
  organizationName: string;
  inviterName: string;
  role: "collaborator" | "owner";
}

export interface ApiAddAppArgs {
  organizationId: string;
  appId: string;
}

export interface ApiTransferAppArgs {
  fromOrganizationId: string;
  toOrganizationId: string;
  appId: string;
}

type ApiTransferApp = ApiTransferAppArgs;

interface ApiDeleteAppArgs {
  organizationId: string;
  appId: string;
}

interface ApiImportAppConfiguration {
  sourceAppId?: string;
  destinationAppId?: string;
  sections: string[];
  organizationId: string;
}

type ApiDeleteApp = ApiDeleteAppArgs;

const organizationApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getOrganizationApps: build.query<ApiGetApps, ApiGetAppsArgs>({
      query: ({ orgId, headers, limit, skip }) => ({
        url: `account/organization/${orgId}/apps?skip=${skip}&limit=${limit}`,
        headers,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(organizationUpdated({ organizationId: args.orgId }));
        } catch (e) {
          if (errorMatchesMessage(e, "not-loggedin")) {
            dispatch(
              uiUpdated({ authMessageType: "orgAccessError/notLoggedIn" })
            );
          } else if (errorMatchesMessage(e, "not-organization-member")) {
            dispatch(
              uiUpdated({ authMessageType: "orgAccessError/notOrgMember" })
            );
          }
        }
      },
    }),
    getOrganizationMembers: build.query<ApiGetMembers, ApiOrganizationArgs>({
      query: ({ orgId, headers }) => ({
        url: `account/organization/${orgId}/members`,
        headers,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(organizationUpdated({ organizationId: args.orgId }));
        } catch (e) {
          if (errorMatchesMessage(e, "not-loggedin")) {
            dispatch(
              uiUpdated({ authMessageType: "orgAccessError/notLoggedIn" })
            );
          } else if (errorMatchesMessage(e, "not-organization-member")) {
            dispatch(
              uiUpdated({ authMessageType: "orgAccessError/notOrgMember" })
            );
          }
        }
      },
    }),
    getOrganizationSettings: build.query<ApiGetSettings, ApiOrganizationArgs>({
      query: ({ orgId, headers }) => ({
        url: `account/organization/${orgId}/settings`,
        headers,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(organizationUpdated({ organizationId: args.orgId }));
        } catch (e) {
          if (errorMatchesMessage(e, "not-loggedin")) {
            dispatch(
              uiUpdated({ authMessageType: "orgAccessError/notLoggedIn" })
            );
          } else if (errorMatchesMessage(e, "not-organization-member")) {
            dispatch(
              uiUpdated({ authMessageType: "orgAccessError/notOrgMember" })
            );
          }
        }
      },
    }),
    getOrganizationMemberDetails: build.query<
      OrganizationMemberDetails,
      ApiGetMemberDetailsArgs
    >({
      query: ({ orgId, userId }) => ({
        url: `account/organization/${orgId}/${userId}/details`,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            organizationMemberDetailsUpdated({ ...data, userId: args.userId })
          );
        } catch (e) {
          if (errorMatchesMessage(e, "not-organization-owner")) {
            dispatch(
              uiUpdated({ authMessageType: "orgAccessError/notOrgOwner" })
            );
          }
        }
      },
    }),
    updateOrganizationMemberDetails: build.mutation<
      OrganizationMemberDetails,
      ApiUpdateMemberDetailsArgs
    >({
      query: (args) => ({
        url: "account/organization/member-details/update",
        method: "POST",
        body: args,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            organizationMemberDetailsUpdated({ ...data, userId: args.userId })
          );
        } catch {
          // NO-OP
        }
      },
    }),
    updateOrganizationSettings: build.mutation<
      ApiUpdateOrganizationSettingsArgs,
      ApiUpdateOrganizationSettingsArgs
    >({
      query: (args) => ({
        url: "account/organization/settings/update",
        method: "POST",
        body: args,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            organizationUpdated({ organizationName: data.organizationName })
          );
        } catch {
          // NO-OP
        }
      },
    }),
    removeOrganizationMember: build.mutation<
      ApiRemoveMember,
      ApiRemoveMemberArgs
    >({
      query: (args) => ({
        url: "account/organization/member-details/remove",
        method: "POST",
        body: args,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            organizationUpdated({
              members: data.organizationMembers,
            })
          );
        } catch {
          // NO-OP
        }
      },
    }),
    inviteOrganizationMember: build.mutation<
      ApiInviteMember,
      ApiInviteMemberArgs
    >({
      query: (args) => ({
        url: "account/organization/invite",
        method: "POST",
        body: args,
      }),
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            organizationUpdated({ invitedMembers: data.invitedMembers })
          );
        } catch (e) {
          // NO-OP
        }
      },
    }),
    resendOrganizationInvite: build.mutation<
      ApiInviteMember,
      ApiResendInviteArgs
    >({
      query: (args) => ({
        url: "account/organization/invite/resend",
        method: "POST",
        body: args,
      }),
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            organizationUpdated({ invitedMembers: data.invitedMembers })
          );
        } catch (e) {
          // NO-OP
        }
      },
    }),
    cancelOrganizationInvite: build.mutation<
      ApiInviteMember,
      ApiResendInviteArgs
    >({
      query: (args) => ({
        url: "account/organization/invite/cancel",
        method: "POST",
        body: args,
      }),
      onQueryStarted: async (_args, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            organizationUpdated({ invitedMembers: data.invitedMembers })
          );
        } catch (e) {
          // NO-OP
        }
      },
    }),
    validateOrganizationInvite: build.query<
      ApiValidateInvite,
      ApiValidateInviteArgs
    >({
      query: ({ inviteId, headers }) => ({
        url: `account/organization/invite/${inviteId}`,
        headers,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            organizationUpdated({
              invitationId: args.inviteId,
              invitationInviter: data.inviterName,
              invitationOrganization: data.organizationName,
              invitationRole: data.role,
            })
          );
          dispatch(uiUpdated({ authMessageType: "orgInvite" }));
        } catch (e) {
          if (errorMatchesMessage(e, "not-loggedin")) {
            dispatch(uiUpdated({ authMessageType: "orgInvite/notLoggedIn" }));
          } else if (errorMatchesMessage(e, "email-not-matching")) {
            dispatch(
              uiUpdated({ authMessageType: "orgInvite/emailNotMatching" })
            );
          } else if (errorMatchesMessage(e, "invitation-invalid")) {
            dispatch(
              uiUpdated({ authMessageType: "orgInvite/invitationInvalid" })
            );
          } else if (errorMatchesMessage(e, "invitation-expired")) {
            dispatch(
              uiUpdated({ authMessageType: "orgInvite/invitationExpired" })
            );
          }
        }
      },
    }),
    acceptOrganizationInvite: build.mutation<ApiAddOrganization, string>({
      query: (inviteId) => ({
        url: "account/organization/invite/accept",
        method: "POST",
        body: { inviteId },
      }),
    }),
    addOrganization: build.mutation<ApiAddOrganization, string>({
      query: (name) => ({
        url: "account/organization/create",
        method: "POST",
        body: { name },
      }),
    }),
    addOrganizationApp: build.mutation<ApiAddOrganizationApp, ApiAddAppArgs>({
      query: (args) => ({
        url: "account/organization/add-app",
        method: "POST",
        body: args,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(
            overviewUpdated({ organizationName: data.organizationName })
          );
        } catch (e) {}
      },
    }),
    transferOrganizationApp: build.mutation<ApiTransferApp, ApiTransferAppArgs>(
      {
        query: (args) => ({
          url: "account/organization/transfer-app",
          method: "POST",
          body: args,
        }),
      }
    ),
    deleteOrganizationApp: build.mutation<ApiDeleteApp, ApiDeleteAppArgs>({
      query: (args) => ({
        url: "account/organization/delete-app",
        method: "POST",
        body: args,
      }),
    }),
    switchCurrentOrganization: build.mutation<void, string>({
      query: (organizationId) => ({
        url: "account/organization/switch",
        method: "POST",
        body: { organizationId },
        responseHandler: (response) => response.text(),
      }),
    }),
    leaveOrganization: build.mutation<void, string>({
      query: (organizationId) => ({
        url: "account/organization/leave",
        method: "POST",
        body: { organizationId },
        responseHandler: (response) => response.text(),
      }),
    }),
    deleteOrganization: build.mutation<void, string>({
      query: (organizationId) => ({
        url: "account/organization/delete",
        method: "POST",
        body: { organizationId },
        responseHandler: (response) => response.text(),
      }),
    }),
    importAppConfiguration: build.mutation<void, ApiImportAppConfiguration>({
      query: (args) => ({
        url: "account/organization/import-app",
        method: "POST",
        body: args,
        responseHandler: (response) => response.text(),
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useAcceptOrganizationInviteMutation,
  useAddOrganizationMutation,
  useAddOrganizationAppMutation,
  useGetOrganizationAppsQuery,
  useLazyGetOrganizationAppsQuery,
  useGetOrganizationMembersQuery,
  useGetOrganizationSettingsQuery,
  useUpdateOrganizationSettingsMutation,
  useRemoveOrganizationMemberMutation,
  useGetOrganizationMemberDetailsQuery,
  useInviteOrganizationMemberMutation,
  useResendOrganizationInviteMutation,
  useCancelOrganizationInviteMutation,
  useSwitchCurrentOrganizationMutation,
  useLeaveOrganizationMutation,
  useDeleteOrganizationMutation,
  useTransferOrganizationAppMutation,
  useDeleteOrganizationAppMutation,
  useUpdateOrganizationMemberDetailsMutation,
  useImportAppConfigurationMutation,
} = organizationApi;

export default organizationApi;
