import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppState } from "../constants";

export interface HubspotConfigState {
  identificationEmail?: string;
  identificationToken?: string;
}

const initialState: HubspotConfigState = {
  identificationEmail: undefined,
  identificationToken: undefined,
};

const HubspotConfigSlice = createSlice({
  name: "hubspot",
  initialState,
  reducers: {
    hubspotUpdated: (
      state,
      action: PayloadAction<Partial<HubspotConfigState>>
    ) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { hubspotUpdated } = HubspotConfigSlice.actions;

export const selectHubpsot = (state: AppState) => state.hubspot;
export const selectHubpostEmail = (state: AppState) =>
  selectHubpsot(state).identificationEmail;
export const selectHubpostToken = (state: AppState) =>
  selectHubpsot(state).identificationToken;

const HubspotConfigReducer = HubspotConfigSlice.reducer;

export default HubspotConfigReducer;
