/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { deepEqual } from "fast-equals";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import { DeepPartial } from "utility-types";

import organizationApi from "services/api/organization";
import projectsApi from "services/api/projects";
import {
  appBuilderDiff,
  appBuilderUpdater,
  getAppBuilderCache,
  serializeAppBuilder,
} from "utils/appBuilder";

import { AppState } from "../constants";
import { AppBuilderState } from "./appBuilder/constants";
import { appBuilderUpdated } from "./appBuilder/utils";

export interface PendingUploadItem {
  appConfigKey: string;
  extension: string;
  filename: string;
  fileType: string;
  tempUrl?: string;
  urlKey: string;
  valueKey: string;
}

export interface MetaState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  appBuilderErrors: any;
  cachedAppBuilder: DeepPartial<AppBuilderState> | null;
  cloneKey: string | null;

  isAppetizeBlocked?: boolean;
  isBuildDownloadsBlocked?: boolean;
  isShareDownloadsBlocked?: boolean;

  organizationApps: {
    isLoaded?: boolean;
    isLoading?: boolean;
    skip?: number | null;
  };
  pendingUploads: Record<string, PendingUploadItem>;
}

const initialState: MetaState = {
  appBuilderErrors: {},
  cachedAppBuilder: null,
  cloneKey: null,

  isAppetizeBlocked: true,
  isBuildDownloadsBlocked: true,
  isShareDownloadsBlocked: false,

  organizationApps: {
    isLoaded: false,
    isLoading: false,
    skip: 0,
  },
  pendingUploads: {},
};

const metaSlice = createSlice({
  name: "meta",
  initialState,
  reducers: {
    metaUpdated: (state, action: PayloadAction<Partial<MetaState>>) => ({
      ...state,
      ...action.payload,
    }),
    cachedAppBuilderUpdated: (
      state,
      action: PayloadAction<DeepPartial<AppBuilderState>>
    ) => {
      const cachedAppBuilder = cloneDeep(state.cachedAppBuilder || {});
      appBuilderUpdater(cachedAppBuilder, { ...action.payload });
      return { ...state, cachedAppBuilder };
    },
    pendingUploadsUpdated: (
      state,
      action: PayloadAction<PendingUploadItem | { deleteKey: string }>
    ) => {
      const pendingUploads = { ...state.pendingUploads };
      if ("deleteKey" in action.payload) {
        delete pendingUploads[action.payload.deleteKey];
      } else {
        pendingUploads[action.payload.valueKey] = { ...action.payload };
      }
      return { ...state, pendingUploads };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(appBuilderUpdated, (state, { payload }) => {
        state.cachedAppBuilder = getAppBuilderCache(payload);
      })
      .addMatcher(
        projectsApi.endpoints.cloneProject.matchFulfilled,
        (state, { payload }) => {
          state.cloneKey = payload.publicKey;
        }
      )
      .addMatcher(
        projectsApi.endpoints.getProject.matchFulfilled,
        (state, { payload }) => {
          state.cachedAppBuilder = getAppBuilderCache(
            payload.appBuilder.appBuilder
          );
          state.isAppetizeBlocked = payload.isAppetizeBlocked;
          state.isBuildDownloadsBlocked = payload.isDownloadsBlocked;
        }
      )
      .addMatcher(
        projectsApi.endpoints.getProjectShare.matchFulfilled,
        (state, { payload }) => {
          state.isAppetizeBlocked = payload.isAppetizeBlocked;
          state.isShareDownloadsBlocked = payload.isDownloadsBlocked;
        }
      )
      .addMatcher(
        organizationApi.endpoints.getOrganizationApps.matchFulfilled,
        (state, { payload }) => {
          state.organizationApps.skip = payload.nextSkip;
          if (payload.nextSkip === null) {
            state.organizationApps.isLoaded = true;
          }
        }
      );
  },
});

export const { metaUpdated, cachedAppBuilderUpdated, pendingUploadsUpdated } =
  metaSlice.actions;

export const selectMeta = (state: AppState) => state.meta;

export function hasChangeAndroidOrIosKeys(inputObj: any): string {
  let hasAndroid = false;
  let hasIos = false;

  function checkKeys(obj: any) {
    if (typeof obj !== "object" || obj === null) {
      return;
    }

    Object.keys(obj).forEach((key) => {
      if (key.includes("android")) {
        hasAndroid = true;
      } else if (key.includes("ios")) {
        hasIos = true;
      }
      checkKeys(obj[key]);
    });
  }

  checkKeys(inputObj);

  if (hasAndroid && hasIos) {
    return "ios&android";
  } else if (hasAndroid) {
    return "android";
  } else if (hasIos) {
    return "ios";
  } else {
    return "ios&android";
  }
}

export const selectHasChanges = (state: AppState) => {
  const { cachedAppBuilder } = selectMeta(state);
  if (!cachedAppBuilder) return false;

  const filteredAppBuilder = serializeAppBuilder(
    getAppBuilderCache(state.appBuilder)
  );
  return !deepEqual(filteredAppBuilder, serializeAppBuilder(cachedAppBuilder));
};

export const selectBuildChanges = (state: AppState) => {
  const { cachedAppBuilder } = selectMeta(state);
  if (!cachedAppBuilder) return false;

  const filteredAppBuilder = serializeAppBuilder(
    getAppBuilderCache(state.appBuilder)
  );
  const buildChanges = !isEqual(
    omit(filteredAppBuilder, ["overview.description", "overview.email"]),
    omit(serializeAppBuilder(cachedAppBuilder), [
      "overview.description",
      "overview.email",
    ])
  );
  return buildChanges;
};

export const selectBuilderDiff = (state: AppState) => {
  const { cachedAppBuilder } = selectMeta(state);
  if (!cachedAppBuilder) return {};

  const filteredAppBuilder = serializeAppBuilder(
    getAppBuilderCache(state.appBuilder)
  );
  const buildDiff = appBuilderDiff(
    serializeAppBuilder(cachedAppBuilder),
    filteredAppBuilder
  );

  return buildDiff;
};

export const selectAppBuilderErrors = (state: AppState) =>
  selectMeta(state).appBuilderErrors;
export const selectCachedAppBuilder = (state: AppState) =>
  selectMeta(state).cachedAppBuilder;
export const selectCloneKey = (state: AppState) => selectMeta(state).cloneKey;

const metaReducer = metaSlice.reducer;

export default metaReducer;
