import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ToolBarValue } from "components/common/MenuBuilder/constants";
import { AppState } from "stores/constants";
import { selectAppBuilder } from "stores/features/appBuilder/utils";
import { appBuilderUpdater } from "utils/appBuilder";

export enum ContextualToolbarVisibilityByPagesType {
  allPages = "allPages",
  specificPages = "specificPages",
}

export enum ContextualToolbarVisibilityByBackButtonType {
  backButtonActive = "backButtonActive",
  always = "always",
}

export enum ContextualToolbarButtonTitleType {
  noText = "noText",
  defaultText = "defaultText",
  customText = "customText",
}

export interface ContextualNavigationToolbarIos {
  v2?: boolean;
  enabled?: boolean;
  items?: {
    enabled?: boolean;
    icon?: string;
    system: "back" | "forward" | "refresh";
    title?: string;
    urlRegex?: ToolBarValue[];
    visibility?: ContextualToolbarVisibilityByPagesType;
    titleType?: ContextualToolbarButtonTitleType;
  }[];
  regexes?: {
    enabled: boolean;
    regex: string;
  }[];
  visibilityByBackButton?: ContextualToolbarVisibilityByBackButtonType;
  visibilityByPages?: ContextualToolbarVisibilityByPagesType;
}

export interface AppBuilderContextualNavToolbarState {
  ios: ContextualNavigationToolbarIos | Record<string, never>;
}

const initialState: AppBuilderContextualNavToolbarState = {
  ios: {
    enabled: false,
    visibilityByPages: ContextualToolbarVisibilityByPagesType.allPages,
    visibilityByBackButton:
      ContextualToolbarVisibilityByBackButtonType.backButtonActive,
    regexes: [{ enabled: true, regex: ".*" }],
    items: [
      {
        system: "back",
        titleType: ContextualToolbarButtonTitleType.defaultText,
        visibility: ContextualToolbarVisibilityByPagesType.allPages,
        urlRegex: [{ enabled: true, regex: ".*" }],
      },
      {
        system: "refresh",
        enabled: false,
        visibility: ContextualToolbarVisibilityByPagesType.allPages,
        urlRegex: [{ enabled: true, regex: ".*" }],
      },
      {
        system: "forward",
        enabled: false,
        titleType: ContextualToolbarButtonTitleType.defaultText,
        visibility: ContextualToolbarVisibilityByPagesType.allPages,
        urlRegex: [{ enabled: true, regex: ".*" }],
      },
    ],
  },
};

const contextualNavToolbarSlice = createSlice({
  name: "contextualNavToolbar",
  initialState,
  reducers: {
    contextualNavToolbarIosUpdated: (
      state,
      action: PayloadAction<ContextualNavigationToolbarIos>
    ) => appBuilderUpdater(state, { ios: action.payload }),
    contextualNavToolbarIosBackButtonUpdated: (
      state,
      action: PayloadAction<ContextualNavigationToolbarIos>
    ) => {
      const items = state.ios?.items || [];
      const button = items.find((item) => item.system === "back");
      if (button) {
        appBuilderUpdater(state, {
          ios: {
            items: items.map((item) => {
              if (item.system !== "back") return item;
              return { ...item, ...action.payload };
            }),
          },
        });
      } else {
        items.push({
          system: "back",
          urlRegex: [{ enabled: true, regex: ".*" }],
          ...action.payload,
        });
        appBuilderUpdater(state, { ios: { items } });
      }
    },
    contextualNavToolbarIosRefreshButtonUpdated: (
      state,
      action: PayloadAction<ContextualNavigationToolbarIos>
    ) => {
      const items = state.ios?.items || [];
      const button = items.find((item) => item.system === "refresh");
      if (button) {
        appBuilderUpdater(state, {
          ios: {
            items: items.map((item) => {
              if (item.system !== "refresh") return item;
              return { ...item, ...action.payload };
            }),
          },
        });
      } else {
        items.push({
          system: "refresh",
          enabled: true,
          urlRegex: [{ enabled: true, regex: ".*" }],
          ...action.payload,
        });
        appBuilderUpdater(state, { ios: { items } });
      }
    },
    contextualNavToolbarIosForwardButtonUpdated: (
      state,
      action: PayloadAction<ContextualNavigationToolbarIos>
    ) => {
      const items = state.ios?.items || [];
      const button = items.find((item) => item.system === "forward");
      if (button) {
        appBuilderUpdater(state, {
          ios: {
            items: items.map((item) => {
              if (item.system !== "forward") return item;
              return { ...item, ...action.payload };
            }),
          },
        });
      } else {
        items.push({
          system: "forward",
          enabled: true,
          urlRegex: [{ enabled: true, regex: ".*" }],
          ...action.payload,
        });
        appBuilderUpdater(state, { ios: { items } });
      }
    },
  },
});

export const {
  contextualNavToolbarIosUpdated,
  contextualNavToolbarIosBackButtonUpdated,
  contextualNavToolbarIosRefreshButtonUpdated,
  contextualNavToolbarIosForwardButtonUpdated,
} = contextualNavToolbarSlice.actions;

export const selectContextualNavToolbar = (state: AppState) =>
  selectAppBuilder(state).contextualNavToolbar;

const contextualNavToolbarReducer = contextualNavToolbarSlice.reducer;

export default contextualNavToolbarReducer;
