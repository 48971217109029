import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DeepPartial } from "utility-types";

import { DeviceType } from "components/containers/RightPanel/Preview/constants";
import { appBuilderUpdater } from "utils/appBuilder";

import { AppState } from "../../constants";
import { selectAppBuilder } from "./utils";

export interface AppBuilderPreviewState {
  appetizePublicKey: string | undefined;
  currentDeviceType: DeviceType;
  publicKey: string | undefined;

  appetizeAndroidPublicKey: string | undefined;
  appnumHashed: string;
}

const initialState: AppBuilderPreviewState = {
  appetizePublicKey: undefined,
  appetizeAndroidPublicKey: undefined,
  publicKey: undefined,

  appnumHashed: "",
  currentDeviceType: DeviceType.iphone,
};

const previewSlice = createSlice({
  name: "preview",
  initialState,
  reducers: {
    previewUpdated: (
      state,
      action: PayloadAction<DeepPartial<AppBuilderPreviewState>>
    ) => appBuilderUpdater(state, action.payload),
  },
});

export const { previewUpdated } = previewSlice.actions;

export const selectPreview = (state: AppState) =>
  selectAppBuilder(state).preview;

export const selectAppnumHashed = (state: AppState) =>
  selectPreview(state).appnumHashed;

const previewReducer = previewSlice.reducer;

export default previewReducer;
