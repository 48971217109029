
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __appWithI18n from 'next-translate/appWithI18n'
// @ts-ignore
    
import "assets/styles/modern-normalize.css";
import "assets/styles/main.scss";
import "assets/styles/perfectScrollbar.css";
import "react-popper-tooltip/dist/styles.css";
import "react-toastify/dist/ReactToastify.css";

import App, { AppContext, AppProps } from "next/app";
import React from "react";
import { Provider } from "react-redux";

import { appStoreWrapper } from "stores";
import urls from "utils/urls";

function MyApp({ Component, ...rest }: AppProps) {
  const { store, props } = appStoreWrapper.useWrappedStore(rest);

  return (
    <Provider store={store}>
      <Component {...props} />
    </Provider>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);

  if (appContext.ctx.res?.statusCode === 404) {
    if (appContext.ctx.asPath !== urls.root) {
      appContext.ctx.res.writeHead(302, { Location: urls.root });
    }
    appContext.ctx.res.end();
    return {};
  }

  return { ...appProps };
};

const __Page_Next_Translate__ = MyApp;


// @ts-ignore
    export default __appWithI18n(__Page_Next_Translate__, {
// @ts-ignore
      ...__i18nConfig,
// @ts-ignore
      isLoader: true,
// @ts-ignore
      skipInitialProps: false,
// @ts-ignore
      loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
    });
// @ts-ignore
  