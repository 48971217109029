import type { AppStore } from ".";

export type AppDispatch = AppStore["dispatch"];
export type AppState = ReturnType<AppStore["getState"]>;

export const defaultAppIcon = "assets/defaults/app-icon-placeholder.png";
export const splashscreenDefaultAppIconLight =
  "assets/defaults/splash-light-transparent.png";
export const splashscreenDefaultAppIconDark =
  "assets/defaults/splash-dark-transparent.png";
export const splashscreenAppIconLight = "assets/defaults/splash-light.png";
export const splashscreenAppIconDark = "assets/defaults/splash-dark.png";
export const defaultSidebarIcon = "assets/defaults/logo1.png";
export const defaultAndroidNotificationIcon = "assets/defaults/android.png";
export const defaultOpenGraphImage = "images/opengraph-1.png";

export const defaultOfflineHtml = `<html>
<head>
  <meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=no">
  <title>Device Offline</title>
  <style type="text/css"> 
    :root { --background-color: white; --primary-color: #1C1C1E; }
    [data-theme="dark"] { --background-color: #121212; --primary-color: #eee; }
    [data-theme="light"] { --background-color: white; --primary-color: #1C1C1E; }
    html, body{ background-color: var(--background-color) !important; }
    html, body, button { font-family: Arial, Helvetica, sans-serif; font-size: 18px; } 
    div.container { color: var(--primary-color); position: relative; top: 100px; text-align: center; } 
    #logo>svg>g>path{ fill: var(--primary-color); }
    button { padding: 10px 30px; margin: 4px 2px; }
  </style>
</head>
<body>
  <div class="container">
    <div id="logo">
      <svg width="120" height="96" viewBox="0 0 120 96" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_5510_497)"><path d="M118.275 87.9562L7.27647 0.958312C6.45147 0.313687 5.47272 0 4.50522 0C3.17022 0 1.84459 0.592125 0.959779 1.72294C-0.575284 3.68062 -0.234596 6.51 1.72253 8.04187L112.554 94.8731C114.523 96.4112 117.348 96.0596 118.871 94.1085C120.581 92.325 120.225 89.4937 118.275 87.9562Z" fill="black"/><path opacity="0.4" d="M36 53.8313C32.6869 53.8313 30 56.5163 30 59.6625V89.4937C30 92.8069 32.6869 95.4937 36 95.4937C39.3131 95.4937 42 92.8069 42 89.4937V59.6625C42 56.6813 39.3187 53.8313 36 53.8313ZM54 90C54 93.3131 56.6869 96 60 96C63.3131 96 66 93.3131 66 90V69.8625L54 60.4575V90ZM12 71.8313C8.68687 71.8313 6 74.5163 6 77.6625V89.4937C6 92.8069 8.68687 95.4937 12 95.4937C15.3131 95.4937 18 92.8069 18 89.4937V77.6625C18 74.6813 15.3131 71.8313 12 71.8313ZM60 36C57.9937 36 56.325 37.0313 55.2375 38.55L66 46.9875V42C66 38.6812 63.3187 36 60 36ZM107.831 0C104.518 0 101.831 2.68687 101.831 6V75.2063L113.831 84.6113V6C113.831 2.68687 111.319 0 107.831 0ZM78 90C78 93.3131 80.6869 96 84 96C87.3131 96 90 93.3131 90 90V88.6684L78 79.2634V90ZM84 18C80.6869 18 78 20.6869 78 24V56.3813L90 65.7862V24C90 20.6812 87.3187 18 84 18Z" fill="black"/></g><defs><clipPath id="clip0_5510_497"><rect width="120" height="96" fill="white"/></clipPath></defs></svg>
    </div>
    <span id="message">
      <p>No internet connection<br/>Check your connection and try again</p>
    </span>
    <button id="retryButton" type="button" onclick="gonative.webview.reload()">Retry</button>
  </div>
  <script>
    var message = document.getElementById('message');
    var retryButton = document.getElementById('retryButton')
    if (['ko', 'ko-kr', 'ko-kp'].indexOf(navigator.language.toLowerCase()) > -1) {
      // Korean
      message.innerHTML = '<p>인터넷 연결이 끊겼습니다.<br/>연결하고 다시 시도하시길 바랍니다.</p>';
      retryButton.innerText = '괜찮아';
    }
    function updateDarkMode(){
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        document.documentElement.setAttribute("data-theme", "dark");
      }
      else {
        document.documentElement.setAttribute("data-theme", "light");
      } 
    }
    updateDarkMode();
    if(window.matchMedia){
      window.matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', updateDarkMode);
    }
  </script>
</body>
</html>`;
