import isString from "lodash/isString";

const colorAlphaRegex = /^#[a-f\d]{8}$/i;

export interface AppConfigDefaultColor {
  [key: string]: string;
}

export const appConfigAlphaColorKeys = [
  "styling.iosStatusBarBackgroundColor",
  "styling.iosStatusBarBackgroundColorDark",
  "styling.androidStatusBarBackgroundColor",
  "styling.androidStatusBarBackgroundColorDark",
];

export const appConfigDefaultColor: AppConfigDefaultColor = {
  "styling.iosLaunchScreen.backgroundColor": "#FFFFFF",
  "styling.iosLaunchScreen.backgroundColorDark": "#0E0D08",
  "styling.androidTabPressedBackgroundColor": "#CCCCCC",
  "styling.androidTabPressedBackgroundColorDark": "#999999",
  "styling.androidBackgroundColor": "#FFFFFF",
  "styling.androidBackgroundColorDark": "#333333",
  "styling.iosStatusBarBackgroundColor": "#FFFFFF",
  "styling.iosStatusBarBackgroundColorDark": "#202020",
  "styling.androidStatusBarBackgroundColor": "#5C5C5C",
  "styling.androidStatusBarBackgroundColorDark": "#333333",
  "styling.iosTintColor": "#0E0D08",
  "styling.iosTintColorDark": "#FFFFFF",
  "styling.androidSplashBackgroundColor": "#FFFFFF",
  "styling.androidSplashBackgroundColorDark": "#000000",
  "styling.androidAccentColor": "#009688",
  "styling.androidAccentColorDark": "#80cbc4",
  "styling.androidPullToRefreshColor": "#1A100B",
  "styling.androidPullToRefreshColorDark": "#FFFFFF",
  "styling.androidSwipeNavigationBackgroundColor": "#FFFFFF",
  "styling.androidSwipeNavigationBackgroundColorDark": "#333333",
  "styling.androidSwipeNavigationActiveColor": "#000000",
  "styling.androidSwipeNavigationActiveColorDark": "#FFFFFF",
  "styling.androidSwipeNavigationInactiveColor": "#666666",
  "styling.androidSwipeNavigationInactiveColorDark": "#666666",
  "styling.iosTitleColor": "#0E0D08",
  "styling.iosTitleColorDark": "#FFFFFF",
  "styling.iosNavigationBarTintColor": "#f8f8f8",
  "styling.iosNavigationBarTintColorDark": "#202020",
  "styling.androidActionBarBackgroundColor": "#FFFFFF",
  "styling.androidActionBarBackgroundColorDark": "#333333",
  "styling.androidActionBarForegroundColor": "#1A100B",
  "styling.androidActionBarForegroundColorDark": "#FFFFFF",
  "styling.iosSidebarBackgroundColor": "#f8f8f8",
  "styling.iosSidebarBackgroundColorDark": "#0E0D08",
  "styling.iosSidebarTextColor": "#0E0D08",
  "styling.iosSidebarTextColorDark": "#FFFFFF",
  "styling.androidSidebarBackgroundColor": "#FFFFFF",
  "styling.androidSidebarBackgroundColorDark": "#333333",
  "styling.androidSidebarForegroundColor": "#1A100B",
  "styling.androidSidebarForegroundColorDark": "#FFFFFF",
  "styling.androidSidebarSeparatorColor": "#CCCCCC",
  "styling.androidSidebarSeparatorColorDark": "#666666",
  "styling.androidSidebarHighlightColor": "#1A100B",
  "styling.androidSidebarHighlightColorDark": "#FFFFFF",
  "styling.androidTabBarBackgroundColor": "#FFFFFF",
  "styling.androidTabBarBackgroundColorDark": "#333333",
  "styling.androidTabBarTextColor": "#949494",
  "styling.androidTabBarTextColorDark": "#FFFFFF",
  "styling.androidTabBarIndicatorColor": "#1A100B",
  "styling.androidTabBarIndicatorColorDark": "#666666",
  "styling.iosTabBarTintColor": "#f8f8f8",
  "styling.iosTabBarTintColorDark": "#000000",
  "styling.iosTabBarInactiveColor": "#A1A1A1",
  "styling.iosTabBarInactiveColorDark": "#818181",
};

export const appConfigColorKeys = Object.keys(appConfigDefaultColor);

export function isColorAlpha(value: unknown): value is string {
  return (
    isString(value) && (value === "transparent" || colorAlphaRegex.test(value))
  );
}

// #AARRGGBB -> #RRGGBBAA
export function argbToRgba(color: Readonly<string>) {
  if (!isColorAlpha(color) || color === "transparent") return color;
  return `#${color[3]}${color[4]}${color[5]}${color[6]}${color[7]}${color[8]}${color[1]}${color[2]}`;
}

// #RRGGBBAA -> #AARRGGBB
export function rgbaToArgb(color: Readonly<string>) {
  if (!isColorAlpha(color) || color === "transparent") return color;
  return `#${color[7]}${color[8]}${color[1]}${color[2]}${color[3]}${color[4]}${color[5]}${color[6]}`;
}
