import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import uniqBy from "lodash/uniqBy";
import { DeepPartial } from "utility-types";

import { MenuItemValue } from "components/common/MenuBuilder/constants";
import { appBuilderUpdater } from "utils/appBuilder";

import { AppState, defaultSidebarIcon } from "../../constants";
import { selectAppBuilder } from "./utils";

export interface SidebarItems {
  menuSelectionConfig: {
    redirectLocations: {
      regex: string | null;
      menuName: string;
      loggedIn: boolean;
    }[];
    testURL?: string | null;
  };
  menus: {
    active: boolean;
    items: MenuItemValue[] | null;
    name: string;
  }[];
  menuIcon?: string;
}

export interface AppBuilderSidebarNavigationBarState {
  active: boolean;
  styling: {
    ios: {
      backgroundColor: string;
      backgroundColorDark: string;
      sidebarFont: string;
      sidebarImage: string;
      sidebarImageDark: string;
      textColor: string;
      textColorDark: string;
      type: "appIcon" | "newImage";
    };
    android: {
      showAppName: boolean;
      showLogo: boolean;
      backgroundColor: string;
      backgroundColorDark: string;
      foregroundColor: string;
      foregroundColorDark: string;
      separatorColor: string;
      separatorColorDark: string;
      highlightColor: string;
      highlightColorDark: string;
    };
  };
  sidebarItems: SidebarItems;
}

export const defaultSidebarItems = (menuItems: MenuItemValue[] = []) =>
  ({
    menuSelectionConfig: {
      redirectLocations: [
        /* {
          regex: null,
          menuName: "default",
          loggedIn: false,
        }, */
        {
          regex: ".*",
          menuName: "default",
          loggedIn: true,
        },
      ],
      // testURL: null,
    },
    menus: [
      {
        active: false,
        items: uniqBy(menuItems, "url"),
        name: "default",
      },
      /* {
        active: false,
        items: null,
        name: "loggedIn",
      }, */
    ],
  } as SidebarItems);

const initialState: AppBuilderSidebarNavigationBarState = {
  active: false,
  styling: {
    ios: {
      backgroundColor: "#f8f8f8",
      backgroundColorDark: "#202020",
      sidebarFont: "Default",
      sidebarImage: defaultSidebarIcon,
      sidebarImageDark: defaultSidebarIcon,
      textColor: "#0E0D08",
      textColorDark: "#ffffff",
      type: "appIcon",
    },
    android: {
      showAppName: true,
      showLogo: true,
      backgroundColor: "#ffffff",
      backgroundColorDark: "#333333",
      foregroundColor: "#1A100B",
      foregroundColorDark: "#ffffff",
      separatorColor: "#cccccc",
      separatorColorDark: "#666666",
      highlightColor: "#1A100B",
      highlightColorDark: "#ffffff",
    },
  },
  sidebarItems: defaultSidebarItems(),
};

const sidebarNavigationBarSlice = createSlice({
  name: "sidebarNavigationBar",
  initialState,
  reducers: {
    sidebarNavigationBarUpdated: (
      state,
      action: PayloadAction<DeepPartial<AppBuilderSidebarNavigationBarState>>
    ) => appBuilderUpdater(state, action.payload),
    sidebarNavigationBarMenuIconUpdated: (
      state,
      action: PayloadAction<string | null>
    ) => {
      if (action.payload) {
        state.sidebarItems.menuIcon = action.payload;
      } else {
        delete state.sidebarItems.menuIcon;
      }
      return state;
    },
  },
});

export const {
  sidebarNavigationBarUpdated,
  sidebarNavigationBarMenuIconUpdated,
} = sidebarNavigationBarSlice.actions;

export const selectSidebarNavigationBar = (state: AppState) =>
  selectAppBuilder(state).sidebarNavigationBar;
export const selectSidebarMenu = (state: AppState) =>
  selectSidebarNavigationBar(state).sidebarItems.menus || [];
export const selectSidebarMenuItems = (state: AppState) => {
  const sidebarMenu = selectSidebarMenu(state);

  return sidebarMenu.find((menu) => menu.name === "default")?.items || [];
};
export const selectSidebarMenuIcon = (state: AppState) =>
  selectSidebarNavigationBar(state).sidebarItems.menuIcon;

const sidebarNavigationBarReducer = sidebarNavigationBarSlice.reducer;

export default sidebarNavigationBarReducer;
