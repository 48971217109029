import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { camelize } from "humps";
import clamp from "lodash/clamp";
import sumBy from "lodash/sumBy";

import Plugin from "types/Plugin";
import { trackEvent, trackLead } from "utils/analytics";
import { appBuilderUpdater } from "utils/appBuilder";

import { AppState } from "../constants";
import { selectAppBuilder } from "./appBuilder/utils";

export interface CartState {
  appConfigFullService: boolean;
  samUpdates: boolean;
  assistedFullServiceConfig: boolean;
  basicLicense: boolean;
  nativePlugins: Plugin[];
  professionalPublishInitial: boolean;
  professionalPublishUpdate: boolean;
  professionalSupport: boolean;
  pushNotifications: Plugin[];
  samEnterprise: boolean;
  selfSupport: boolean;
  developerOnboarding: boolean;
  virtualOnsite: boolean;
}

export interface CartStateLocalStorage {
  cart: CartState;
  app: boolean;
  lastActive: string;
}

export interface ActiveCart {
  [privatKey: string]: CartStateLocalStorage;
}

const initialState: CartState = {
  appConfigFullService: false,
  samUpdates: false,
  assistedFullServiceConfig: false,
  basicLicense: false,
  nativePlugins: [],
  professionalPublishInitial: false,
  professionalPublishUpdate: false,
  professionalSupport: false,
  pushNotifications: [],
  samEnterprise: false,
  selfSupport: false,
  developerOnboarding: false,
  virtualOnsite: false,
};

interface CartPayload {
  cart: Partial<CartState>;
  emailAnalytics?: string;
  disableAnalytics?: boolean;
  licensed?: boolean;
}

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    cartUpdated: (state, action: PayloadAction<CartPayload>) => {
      const { cart, disableAnalytics, emailAnalytics, licensed } =
        action.payload;
      if (!licensed && cart.basicLicense === undefined) {
        cart.basicLicense = true;
      }

      if (cart.appConfigFullService) {
        cart.assistedFullServiceConfig = false;
      } else if (cart.assistedFullServiceConfig) {
        cart.appConfigFullService = false;
      }

      if (cart.professionalSupport) {
        cart.samUpdates = false;
      } else if (cart.samUpdates) {
        cart.professionalSupport = false;
      }

      const nextCart = appBuilderUpdater(state, cart);

      if (!disableAnalytics) {
        trackEvent("cart_add");

        if (
          emailAnalytics &&
          (cart.appConfigFullService ||
            cart.assistedFullServiceConfig ||
            cart.basicLicense ||
            cart.professionalPublishInitial ||
            cart.professionalPublishUpdate ||
            cart.professionalSupport ||
            !!cart.nativePlugins?.length ||
            !!cart.pushNotifications?.length)
        ) {
          trackLead({
            email: emailAnalytics,
            cartItems: nextCart,
            licensed,
          });
        }
      }

      return nextCart;
    },
    nativePluginRemovedFromCart: (state, action: PayloadAction<string>) => {
      state.nativePlugins = state.nativePlugins.filter(
        (plugin) => plugin.slug !== action.payload
      );
    },
    pushNotificationsRemovedFromCart: (
      state,
      action: PayloadAction<string>
    ) => {
      state.pushNotifications = state.pushNotifications.filter(
        (pushNotification) => pushNotification.slug !== action.payload
      );
    },
    cartReset: (state) => ({
      ...state,
      ...initialState,
    }),
  },
});

export const {
  nativePluginRemovedFromCart,
  pushNotificationsRemovedFromCart,
  cartReset,
  cartUpdated,
} = cartSlice.actions;

export const selectCart = (state: AppState) => state.cart;

export const selectIsCartLoaded = (state: AppState) => {
  const {
    appConfigFullService,
    assistedFullServiceConfig,
    basicLicense,
    nativePlugins,
    professionalPublishInitial,
    professionalPublishUpdate,
    professionalSupport,
    pushNotifications,
    samUpdates,
    developerOnboarding,
    virtualOnsite,
  } = state.cart;

  return (
    appConfigFullService ||
    assistedFullServiceConfig ||
    basicLicense ||
    nativePlugins.length > 0 ||
    professionalPublishInitial ||
    professionalPublishUpdate ||
    professionalSupport ||
    pushNotifications.length > 0 ||
    samUpdates ||
    developerOnboarding ||
    virtualOnsite
  );
};

export const selectHasPremiumPlugins = (state: AppState, plugins: Plugin[]) => {
  const appBuilder = selectAppBuilder(state);
  const cart = selectCart(state);

  const nativePluginsInstalled = plugins.filter((plugin) =>
    Object.keys(appBuilder.license.nativePlugins).includes(
      camelize(plugin.gnid)
    )
  );

  const pushNotificationInstalled = plugins.filter((plugin) =>
    Object.keys(appBuilder.license.pushNotifications).includes(
      camelize(plugin.gnid)
    )
  );

  const hasPremiumPlugins = [
    ...nativePluginsInstalled,
    ...pushNotificationInstalled,
    ...cart.nativePlugins,
  ].some((plugin) => plugin.premiumPlugin);

  return hasPremiumPlugins;
};

export const selectLicensedSupportPrice = (state: AppState) => {
  const appBuilder = selectAppBuilder(state);

  if (appBuilder.support.status === "samExpired") {
    return 0;
  }
  if (appBuilder.license.app.support === "premium") {
    return 1200;
  }
  if (appBuilder.license.app.support === "basic") {
    return 490;
  }
  if (appBuilder.license.app.support === "appUpdatesPremium") {
    return 600;
  }
  if (appBuilder.license.app.support === "appUpdates") {
    return 229;
  }
  return 0;
};

export const selectBasicSupportPrice = (
  state: AppState,
  plugins: Plugin[],
  preview = false
) => {
  const appBuilder = selectAppBuilder(state);
  const cart = selectCart(state);
  const hasPremiumPlugins = selectHasPremiumPlugins(state, plugins);
  const licensedSupportPrice = selectLicensedSupportPrice(state);

  let supportPrice = 0;

  if (cart.samUpdates || preview) {
    supportPrice = hasPremiumPlugins ? 600 : 229;
  }

  // Automatically upgrade appUpdates to appUpdatesPremium.
  if (hasPremiumPlugins && appBuilder.license.app.support === "appUpdates") {
    supportPrice = cart.professionalSupport ? 0 : 600;
  }

  return clamp(supportPrice - licensedSupportPrice, 0, 999999999);
};

export const selectProfessionalSupportPrice = (
  state: AppState,
  plugins: Plugin[],
  preview = false
) => {
  const appBuilder = selectAppBuilder(state);
  const cart = selectCart(state);
  const hasPremiumPlugins = selectHasPremiumPlugins(state, plugins);
  const licensedSupportPrice = selectLicensedSupportPrice(state);

  let supportPrice = 0;

  if (cart.professionalSupport || preview) {
    supportPrice = hasPremiumPlugins ? 1200 : 490;
  }

  // Automatically upgrade basic to premium.
  if (hasPremiumPlugins && appBuilder.license.app.support === "basic") {
    supportPrice = 1200;
  }

  if (
    appBuilder.license.app.support === "appUpdatesPremium" &&
    cart.professionalSupport
  ) {
    supportPrice = 1200;
  }

  return clamp(supportPrice - licensedSupportPrice, 0, 999999999);
};

export const selectPrices = (state: AppState, plugins: Plugin[]) => {
  const cart = selectCart(state);

  const appConfigFullService = cart.appConfigFullService ? 5100 : 0;

  const assistedFullServiceConfig = cart.assistedFullServiceConfig ? 1100 : 0;

  const basicLicense = cart.basicLicense ? 790 : 0;

  const nativePlugins = sumBy(
    cart.nativePlugins,
    (plugin) => plugin.price || 0
  );

  const pushNotificationsPrice = sumBy(
    cart.pushNotifications,
    (pushNotification) => pushNotification.price || 0
  );

  const professionalPublishInitial = cart.professionalPublishInitial ? 700 : 0;

  const professionalPublishUpdate = cart.professionalPublishUpdate ? 350 : 0;

  const professionalSupport = selectProfessionalSupportPrice(state, plugins);

  const samUpdates = selectBasicSupportPrice(state, plugins);

  const developerOnboarding = cart.developerOnboarding ? 1500 : 0;

  const virtualOnsite = cart.virtualOnsite ? 1500 : 0;

  return {
    appConfigFullService,
    assistedFullServiceConfig,
    basicLicense,
    nativePlugins,
    professionalPublishInitial,
    professionalPublishUpdate,
    pushNotificationsPrice,
    samUpdates,
    professionalSupport,
    developerOnboarding,
    virtualOnsite,
  };
};

export const selectCartPrice = (state: AppState, plugins: Plugin[]) => {
  const {
    appConfigFullService,
    assistedFullServiceConfig,
    basicLicense,
    nativePlugins,
    professionalPublishInitial,
    professionalPublishUpdate,
    pushNotificationsPrice,
    samUpdates,
    professionalSupport,
    developerOnboarding,
    virtualOnsite,
  } = selectPrices(state, plugins);

  return (
    appConfigFullService +
    assistedFullServiceConfig +
    basicLicense +
    nativePlugins +
    professionalPublishInitial +
    pushNotificationsPrice +
    samUpdates +
    professionalSupport +
    professionalPublishUpdate +
    developerOnboarding +
    virtualOnsite
  );
};

const cartReducer = cartSlice.reducer;

export default cartReducer;
