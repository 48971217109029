import S3 from "aws-sdk/clients/s3";
import AWS from "aws-sdk/global";

export const s3Credential = {
  accessKeyId: process.env.NEXT_PUBLIC_S3_ACCESS_KEY_ID,
  secretAccessKey: process.env.NEXT_PUBLIC_S3_SECRET_ACCESS_KEY,
  region: process.env.NEXT_PUBLIC_S3_REGION,
  bucket: process.env.NEXT_PUBLIC_S3_BUCKET,
};

AWS.config.update({
  accessKeyId: s3Credential.accessKeyId,
  secretAccessKey: s3Credential.secretAccessKey,
  region: s3Credential.region,
});

const s3 = new S3();

function getS3KeyFromUrl(url: string | undefined) {
  // url validation
  if (!url || !url.startsWith("https://")) {
    return null;
  }

  // parse url to check for valid url
  const parsedLink = new URL(url);
  if (!parsedLink.hostname) {
    return null;
  }

  // if s3 url is not from amazonaws.com, return the original url
  const domain = `https://${parsedLink.hostname}/`;
  if (!domain.includes(".amazonaws.com")) {
    return null;
  }

  let key = "";
  // if s3 url uses the old domain, extract the key
  if (domain === "https://s3.amazonaws.com/") {
    [, key] = url.split(`${domain}${s3Credential.bucket}/`);
  }
  // if s3 url uses the new domain, extract the key
  else {
    [, key] = url.split(domain);
  }

  return key || null;
}

export function getSignedUrl(url: string | undefined) {
  try {
    const key = getS3KeyFromUrl(url);
    if (!key) {
      return url;
    }

    return s3.getSignedUrl("getObject", {
      Bucket: s3Credential.bucket,
      Expires: 60,
      Key: key,
    });
  } catch (error) {
    console.log(`Error getting signed url for ${url}`, error);
    return url;
  }
}

export async function copyTempToAppFiles(sourceUrl: string) {
  try {
    const sourceKey = getS3KeyFromUrl(sourceUrl);
    if (!sourceKey || !sourceKey.startsWith("temp/")) {
      return sourceUrl;
    }

    const destinationKey = sourceKey.replace("temp/", "app_files/");

    await s3
      .copyObject({
        Bucket: s3Credential.bucket!,
        CopySource: `/${s3Credential.bucket}/${sourceKey}`,
        Key: destinationKey,
      })
      .promise();

    return `https://s3.amazonaws.com/${s3Credential.bucket}/${destinationKey}`;
  } catch (error) {
    console.log(`Error copying ${sourceUrl}`, error);
    return sourceUrl;
  }
}
