import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as yup from "yup";

import { appBuilderUpdater } from "utils/appBuilder";

import { AppState } from "../../constants";
import { selectUser } from "../user";
import { selectAppBuilder } from "./utils";

export const appBuilderOverviewSchema = yup.object({
  appName: yup.string().required(),
  websiteUrl: yup.string().url().required(),
  email: yup.string().required(),
});

export const appBuilderOverviewAuthSchema = yup.object({
  appName: yup.string().required(),
  websiteUrl: yup.string().url().required(),
  showLegacyManagementLink: yup.boolean(),
});

export interface AppBuilderOverviewState {
  appName: string;
  websiteUrl: string;
  email: string;
  description?: string;
  showLegacyManagementLink?: boolean;
  organizationName?: string;
  organizationId?: string;
}

const initialState: AppBuilderOverviewState = {
  appName: "",
  websiteUrl: "",
  email: "",
  organizationName: "",
  organizationId: "",
};

const overviewSlice = createSlice({
  name: "overview",
  initialState,
  reducers: {
    overviewUpdated: (
      state,
      action: PayloadAction<Partial<AppBuilderOverviewState>>
    ) => appBuilderUpdater(state, action.payload),
  },
});

export const { overviewUpdated } = overviewSlice.actions;

export const selectOverview = (state: AppState) =>
  selectAppBuilder(state).overview;
export const selectAppName = (state: AppState) => selectOverview(state).appName;
export const selectWebsiteUrl = (state: AppState) =>
  selectOverview(state).websiteUrl;
export const selectEmail = (state: AppState) =>
  selectUser(state).email || selectOverview(state).email;
export const selectContact = (state: AppState) => selectOverview(state).email;
export const selectDescription = (state: AppState) =>
  selectOverview(state).description;

const overviewReducer = overviewSlice.reducer;

export default overviewReducer;
