/* eslint-disable @typescript-eslint/no-explicit-any */
import { deepEqual } from "fast-equals";
import assign from "lodash/assign";
import assignWith from "lodash/assignWith";
import cloneDeep from "lodash/cloneDeep";
import get from "lodash/get";
import isPlainObject from "lodash/isPlainObject";
import merge from "lodash/merge";
import pick from "lodash/pick";
import set from "lodash/set";
import { DeepPartial } from "utility-types";
import { remove } from "wild-wild-path";

import { BannerState } from "stores/features/appBuilder/banners";
import { AppBuilderBottomTabBarState } from "stores/features/appBuilder/bottomTabBar";
import { AppBuilderBrandingState } from "stores/features/appBuilder/branding";
import {
  AppBuilderBuildState,
  BuildState,
} from "stores/features/appBuilder/build";
import { AppBuilderState } from "stores/features/appBuilder/constants";
import { AppBuilderContextualNavToolbarState } from "stores/features/appBuilder/contextualNavToolbar";
import {
  AppBuilderInterfaceState,
  OffLinePageType,
} from "stores/features/appBuilder/interface";
import { AppBuilderLicenseState } from "stores/features/appBuilder/license";
import { AppBuilderLinkHandlingState } from "stores/features/appBuilder/linkHandling";
import { AppBuilderNativePluginsState } from "stores/features/appBuilder/nativePlugins";
import { AppBuilderOverviewState } from "stores/features/appBuilder/overview";
import { AppBuilderPermissionsState } from "stores/features/appBuilder/permissions";
import { AppBuilderPreviewState } from "stores/features/appBuilder/preview";
import { AppBuilderPushNotificationsState } from "stores/features/appBuilder/pushNotifications";
import { AppBuilderServicesState } from "stores/features/appBuilder/services";
import { AppBuilderSidebarNavigationBarState } from "stores/features/appBuilder/sidebarNavigationBar";
import { AppBuilderSupportState } from "stores/features/appBuilder/support";
import { AppBuilderTopNavigationBarState } from "stores/features/appBuilder/topNavigationBar";
import { AppBuilderWebsiteOverridesState } from "stores/features/appBuilder/websiteOverrides";
import { AppConfigState } from "stores/features/appConfig";

import { isColorAlpha } from "./colors";

export interface AppBuilder {
  banners: BannerState;
  overview: AppBuilderOverviewState;
  branding: AppBuilderBrandingState;
  contextualNavToolbar: AppBuilderContextualNavToolbarState;
  interface: AppBuilderInterfaceState;
  linkHandling: AppBuilderLinkHandlingState;
  permissions: AppBuilderPermissionsState;
  websiteOverrides: AppBuilderWebsiteOverridesState;
  topNavigationBar: AppBuilderTopNavigationBarState;
  sidebarNavigationBar: AppBuilderSidebarNavigationBarState;
  bottomTabBar: AppBuilderBottomTabBarState;
  pushNotifications: AppBuilderPushNotificationsState;
  nativePlugins: AppBuilderNativePluginsState;
  build: AppBuilderBuildState;
  support: AppBuilderSupportState;
  services: AppBuilderServicesState;
  license: AppBuilderLicenseState;
  preview: AppBuilderPreviewState;
}

export type AppBuilderInput = DeepPartial<AppBuilder>;

export type AppConfig = AppConfigState;

export type AppConfigInput = DeepPartial<AppConfig>;

export type BuilderConfigInput = DeepPartial<
  BuildState & { platform?: "ios" | "android"; hasChanges: boolean | string }
>;
export function appBuilderUpdater<T, U>(
  prevValue: Readonly<T>,
  currentValue: Readonly<U>
): T {
  return assignWith(
    prevValue,
    currentValue,
    // eslint-disable-next-line consistent-return
    (objValue, srcValue) => {
      if (isPlainObject(srcValue)) {
        return appBuilderUpdater(objValue, srcValue);
      } else if (Array.isArray(srcValue)) {
        return srcValue;
      } else if (srcValue === undefined) {
        return null;
      }
    }
  );
}

export function appBuilderDiff(
  prevValue: any,
  currentValue: any
): DeepPartial<AppBuilderState> {
  if (deepEqual(prevValue, currentValue)) {
    return {};
  }

  if (!isPlainObject(prevValue) || !isPlainObject(currentValue)) {
    return currentValue;
  }

  const deletedValues: Record<string, any> = Object.keys(prevValue).reduce(
    (acc, key) => {
      // Removed keys
      if (currentValue[key] === undefined) {
        return { ...acc, [key]: null };
      }
      return acc;
    },
    {}
  );

  return Object.keys(currentValue).reduce((acc, key) => {
    // Added keys
    if (prevValue[key] === undefined) {
      return { ...acc, [key]: currentValue[key] };
    }

    // Modified keys
    const difference = appBuilderDiff(prevValue[key], currentValue[key]);
    if (!isPlainObject(difference) || Object.keys(difference).length > 0) {
      return { ...acc, [key]: difference };
    }

    return acc;
  }, deletedValues);
}

export const discardKeysIgnored = [
  "banners",
  "build.androidReleaseSigningCertificate",
  "build.appIdentifiers.isReadOnly",
  "build.appListing",
  "build.downloadLinks",
  "build.license",
  "build.androidBuild",
  "build.iosBuild",
  "build.androidLastBuiltDate",
  "build.iosLastBuiltDate",
  "build.androidLastBuiltBy",
  "build.iosLastBuiltBy",
  "contextualNavToolbar.ios.v2",
  "linkHandling.internalVsExternalLinks.androidApplinksCertHash",
  "linkHandling.internalVsExternalLinks.items.*.id",
  "linkHandling.internalVsExternalLinks.itemsDefault",
  "license",
  "overview.organizationName",
  "overview.organizationId",
  "preview",
  "support",
  "services.publishingHistory",
  "services.appDevelopmentForm.projectDetails",
  "services.appDevelopmentForm.customerNotesInternal",
  "topNavigationBar.autoNewWindows.items.*.*.id",
];

export function appBuilderDiscarder<T, U>(
  prevValue: Readonly<T>,
  currentValue: Readonly<U>
): T {
  return assign(
    prevValue,
    merge(
      cloneDeep(currentValue),
      pick(cloneDeep(prevValue), discardKeysIgnored)
    )
  );
}

export function getAppBuilderCache<T extends object>(appBuilder: T) {
  return remove(appBuilder, discardKeysIgnored.join(" ")) as T;
}

const colorAlphaKeys = [
  "branding.statusBar.ios.backgroundColor",
  "branding.statusBar.ios.backgroundColorDark",
  "branding.statusBar.android.backgroundColor",
  "branding.statusBar.android.backgroundColorDark",
];

export const colorWithoutAlphaKeys = [
  "branding.splashScreen.ios.backgroundColor",
  "branding.splashScreen.ios.backgroundColorDark",
  "branding.splashScreen.android.backgroundColor",
  "branding.splashScreen.android.backgroundColorDark",
  "branding.themeColors.ios.primaryColor",
  "branding.themeColors.ios.primaryColorDark",
  "branding.themeColors.android.primaryColor",
  "branding.themeColors.android.primaryColorDark",
  "interface.pullToRefresh.android.iconColor",
  "interface.swipeGestures.android.backgroundColorDark",
  "interface.swipeGestures.android.backgroundColor",
  "interface.swipeGestures.android.backgroundColorDark",
  "interface.swipeGestures.android.activeColor",
  "interface.swipeGestures.android.inactiveColorDark",
  "interface.swipeGestures.android.inactiveColor",
  "bottomTabBar.styling.ios.tintColorDark",
  "bottomTabBar.styling.ios.tintColor",
  "bottomTabBar.styling.android.backgroundColorDark",
  "bottomTabBar.styling.android.backgroundColor",
  "bottomTabBar.styling.android.textColorDark",
  "bottomTabBar.styling.android.textColor",
  "bottomTabBar.styling.android.indicatorColorDark",
  "bottomTabBar.styling.android.indicatorColor",
  "sidebarNavigationBar.styling.ios.textColorDark",
  "sidebarNavigationBar.styling.ios.backgroundColorDark",
  "sidebarNavigationBar.styling.ios.textColor",
  "sidebarNavigationBar.styling.ios.backgroundColor",
  "sidebarNavigationBar.styling.android.backgroundColorDark",
  "sidebarNavigationBar.styling.android.foregroundColorDark",
  "sidebarNavigationBar.styling.android.separatorColorDark",
  "sidebarNavigationBar.styling.android.highlightColorDark",
  "sidebarNavigationBar.styling.android.backgroundColor",
  "sidebarNavigationBar.styling.android.foregroundColor",
  "sidebarNavigationBar.styling.android.separatorColor",
  "sidebarNavigationBar.styling.android.highlightColor",
  "topNavigationBar.styling.ios.tintColorDark",
  "topNavigationBar.styling.ios.textColorDark",
  "topNavigationBar.styling.ios.tintColor",
  "topNavigationBar.styling.ios.textColor",
  "topNavigationBar.styling.android.backgroundColorDark",
  "topNavigationBar.styling.android.textColorDark",
  "topNavigationBar.styling.android.backgroundColor",
  "topNavigationBar.styling.android.textColor",
];

export function deserializeAppBuilder(appBuilder: Readonly<AppBuilder>) {
  const deserializedAppBuilder = cloneDeep<AppBuilder>(appBuilder);

  /* colorAlphaKeys.forEach((key) => {
    const value = get(deserializedAppBuilder, key);
    if (!isColorAlpha(value)) return;

    set(deserializedAppBuilder, key, argbToRgba(value));
  }); */

  return deserializedAppBuilder;
}

export function serializeAppBuilder(appBuilder: Readonly<AppBuilderInput>) {
  const serializedAppBuilder = cloneDeep<AppBuilderInput>(appBuilder);
  colorAlphaKeys.forEach((key) => {
    const value = get(serializedAppBuilder, key);
    if (!value) return;
    if (isColorAlpha(value)) return;

    if (value === "transparent") {
      set(serializedAppBuilder, key, "#00000000");
    }

    if (value.length > 9) {
      set(serializedAppBuilder, key, value.substring(0, 9));
    }
  });
  colorWithoutAlphaKeys.forEach((key) => {
    const value: string = get(serializedAppBuilder, key);
    if (!value) return;
    if (value.length > 7) {
      set(serializedAppBuilder, key, value.substring(0, 7));
    }
  });

  if (
    serializedAppBuilder.build?.googleServices?.android &&
    !serializedAppBuilder.build?.googleServices?.android?.json
  ) {
    delete serializedAppBuilder.build?.googleServices.android;
  }

  if (
    serializedAppBuilder.build?.googleServices?.ios &&
    !serializedAppBuilder.build?.googleServices?.ios?.infoPlist
  ) {
    delete serializedAppBuilder.build?.googleServices.ios;
  }

  if (serializedAppBuilder.websiteOverrides?.customCss) {
    if (!serializedAppBuilder.websiteOverrides?.customCss?.ios) {
      delete serializedAppBuilder.websiteOverrides?.customCss?.ios;
    }
    if (!serializedAppBuilder.websiteOverrides?.customCss?.android) {
      delete serializedAppBuilder.websiteOverrides?.customCss?.android;
    }
    if (!serializedAppBuilder.websiteOverrides?.customCss?.base) {
      delete serializedAppBuilder.websiteOverrides?.customCss?.base;
    }
    if (
      Object.keys(serializedAppBuilder.websiteOverrides?.customCss).length === 0
    ) {
      delete serializedAppBuilder.websiteOverrides?.customCss;
    }
  }

  if (serializedAppBuilder.websiteOverrides?.customJs) {
    if (!serializedAppBuilder.websiteOverrides?.customJs?.ios) {
      delete serializedAppBuilder.websiteOverrides?.customJs?.ios;
    }
    if (!serializedAppBuilder.websiteOverrides?.customJs?.android) {
      delete serializedAppBuilder.websiteOverrides?.customJs?.android;
    }
    if (!serializedAppBuilder.websiteOverrides?.customJs?.base) {
      delete serializedAppBuilder.websiteOverrides?.customJs?.base;
    }
    if (
      Object.keys(serializedAppBuilder.websiteOverrides?.customJs).length === 0
    ) {
      delete serializedAppBuilder.websiteOverrides?.customJs;
    }
  }

  if (!serializedAppBuilder.linkHandling?.urlSchemeProtocol) {
    delete serializedAppBuilder.linkHandling?.urlSchemeProtocol;
  }

  if (!serializedAppBuilder.interface?.maxWindows) {
    delete serializedAppBuilder.interface?.maxWindows;
  }

  if (!serializedAppBuilder.permissions?.appTrackingTransparency?.ios?.active) {
    delete serializedAppBuilder.permissions?.appTrackingTransparency?.ios
      ?.active;
    delete serializedAppBuilder.permissions?.appTrackingTransparency?.ios
      ?.userTrackingDescription;
  }

  if (
    !serializedAppBuilder.pushNotifications?.oneSignal?.config?.applicationId
  ) {
    delete serializedAppBuilder.pushNotifications?.oneSignal?.config
      ?.applicationId;
  }
  if (
    !serializedAppBuilder.pushNotifications?.oneSignal?.config?.iosSoftPrompt
  ) {
    delete serializedAppBuilder.pushNotifications?.oneSignal?.config
      ?.iosSoftPrompt;
  }

  if (!serializedAppBuilder.pushNotifications?.oneSignal?.config?.tagsJsonUrl) {
    delete serializedAppBuilder.pushNotifications?.oneSignal?.config
      ?.tagsJsonUrl;
  }

  if (
    !serializedAppBuilder.pushNotifications?.oneSignal?.config?.customSound1
  ) {
    delete serializedAppBuilder.pushNotifications?.oneSignal?.config
      ?.customSound1;
  }
  if (
    !serializedAppBuilder.pushNotifications?.oneSignal?.config?.customSound2
  ) {
    delete serializedAppBuilder.pushNotifications?.oneSignal?.config
      ?.customSound2;
  }
  if (
    !serializedAppBuilder.pushNotifications?.oneSignal?.config?.customSound3
  ) {
    delete serializedAppBuilder.pushNotifications?.oneSignal?.config
      ?.customSound3;
  }
  if (
    !serializedAppBuilder.pushNotifications?.oneSignal?.config?.customSound4
  ) {
    delete serializedAppBuilder.pushNotifications?.oneSignal?.config
      ?.customSound4;
  }
  if (
    !serializedAppBuilder.pushNotifications?.oneSignal?.config?.customSound5
  ) {
    delete serializedAppBuilder.pushNotifications?.oneSignal?.config
      ?.customSound5;
  }

  if (!serializedAppBuilder.build?.appIdentifiers?.android?.versionName) {
    delete serializedAppBuilder.build?.appIdentifiers?.android?.versionName;
  }

  if (
    serializedAppBuilder.interface?.offlinePage?.android?.value !==
    OffLinePageType.custom
  ) {
    delete serializedAppBuilder.interface?.offlinePage?.android?.html;
    delete serializedAppBuilder.interface?.offlinePage?.android?.offlinePageUrl;
    delete serializedAppBuilder.interface?.offlinePage?.android
      ?.offlinePageUrlExternal;
  } else {
    if (!serializedAppBuilder.interface?.offlinePage?.android?.offlinePageUrl) {
      delete serializedAppBuilder.interface?.offlinePage?.android
        ?.offlinePageUrl;
    }
    if (
      !serializedAppBuilder.interface?.offlinePage?.android
        ?.offlinePageUrlExternal
    ) {
      delete serializedAppBuilder.interface?.offlinePage?.android
        ?.offlinePageUrlExternal;
    }
  }

  if (
    serializedAppBuilder.interface?.offlinePage?.ios?.value !==
    OffLinePageType.custom
  ) {
    delete serializedAppBuilder.interface?.offlinePage?.ios?.html;
    delete serializedAppBuilder.interface?.offlinePage?.ios?.offlinePageUrl;
    delete serializedAppBuilder.interface?.offlinePage?.ios
      ?.offlinePageUrlExternal;
  } else {
    if (!serializedAppBuilder.interface?.offlinePage?.ios?.offlinePageUrl) {
      delete serializedAppBuilder.interface?.offlinePage?.ios?.offlinePageUrl;
    }
    if (
      !serializedAppBuilder.interface?.offlinePage?.ios?.offlinePageUrlExternal
    ) {
      delete serializedAppBuilder.interface?.offlinePage?.ios
        ?.offlinePageUrlExternal;
    }
  }

  if (
    typeof serializedAppBuilder.interface?.offlinePage?.android
      ?.connectionTimeoutThreshold !== "number"
  ) {
    delete serializedAppBuilder.interface?.offlinePage?.android
      ?.connectionTimeoutThreshold;
  }

  if (
    typeof serializedAppBuilder.interface?.offlinePage?.ios
      ?.connectionTimeoutThreshold !== "number"
  ) {
    delete serializedAppBuilder.interface?.offlinePage?.ios
      ?.connectionTimeoutThreshold;
  }

  return serializedAppBuilder;
}
