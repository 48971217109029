import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DeepPartial } from "utility-types";

import { appBuilderUpdater } from "utils/appBuilder";

import { AppState } from "../../constants";
import { selectAppBuilder } from "./utils";

export type SupportStatus =
  | "none"
  | "canceled"
  | "active"
  | "processing"
  | "card_declined"
  | "appUpdatesExpired"
  | "samExpired"
  | "expired"
  | "inactive";

export interface AppBuilderSupportState {
  status: SupportStatus;
  expiryDate: string | undefined;
}

const initialState: AppBuilderSupportState = {
  status: "none",
  expiryDate: undefined,
};

const supportSlice = createSlice({
  name: "support",
  initialState,
  reducers: {
    supportUpdated: (
      state,
      action: PayloadAction<DeepPartial<AppBuilderSupportState>>
    ) => appBuilderUpdater(state, action.payload),
  },
});

export const { supportUpdated } = supportSlice.actions;

export const selectSupport = (state: AppState) =>
  selectAppBuilder(state).support;

const supportReducer = supportSlice.reducer;

export default supportReducer;
