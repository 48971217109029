import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DeepPartial } from "utility-types";

import { LinkHandlingValue } from "components/common/MenuBuilder/constants";
import { appBuilderUpdater } from "utils/appBuilder";

import { AppState } from "../../constants";
import { selectAppBuilder } from "./utils";

export enum ContextMenuLinkAction {
  CopyLink = "copyLink",
  OpenExternal = "openExternal",
}

export interface AppBuilderLinkHandlingState {
  internalVsExternalLinks: {
    active: boolean;
    items: LinkHandlingValue[];
    itemsDefault: LinkHandlingValue[];
  };
  enableNewWindows: boolean;
  universalLinks: string[];
  enableAndroidApplinks: boolean;
  androidApplinksCertHash: string;
  urlSchemeProtocol: string | undefined;
  contextMenu?: {
    enabled?: boolean;
    linkActions?: ContextMenuLinkAction[];
  };
}

const initialState: AppBuilderLinkHandlingState = {
  internalVsExternalLinks: {
    active: true,
    items: [],
    itemsDefault: [],
  },
  enableNewWindows: true,
  universalLinks: [],
  enableAndroidApplinks: false,
  androidApplinksCertHash: "",
  urlSchemeProtocol: undefined,
  contextMenu: {
    enabled: false,
    linkActions: [
      ContextMenuLinkAction.CopyLink,
      ContextMenuLinkAction.OpenExternal,
    ],
  },
};

const linkHandlingSlice = createSlice({
  name: "linkHandling",
  initialState,
  reducers: {
    linkHandlingUpdated: (
      state,
      action: PayloadAction<DeepPartial<AppBuilderLinkHandlingState>>
    ) => appBuilderUpdater(state, action.payload),
  },
});

export const { linkHandlingUpdated } = linkHandlingSlice.actions;

export const selectLinkHandling = (state: AppState) =>
  selectAppBuilder(state).linkHandling;

export const selectInternalVsExternalLinkItems = (state: AppState) =>
  selectAppBuilder(state).linkHandling.internalVsExternalLinks.items;

export const selectInternalVsExternalLinkItemsDefault = (state: AppState) =>
  selectAppBuilder(state).linkHandling.internalVsExternalLinks.itemsDefault;

const linkHandlingReducer = linkHandlingSlice.reducer;

export default linkHandlingReducer;
