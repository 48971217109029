import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { MenuItemValue } from "components/common/MenuBuilder/constants";
import { Icon } from "types/Icon";

import { AppState } from "../constants";

export enum NativeNavigationTab {
  topNavigationBar = "topNavigationBar",
  sidebarNavigation = "sidebarNavigation",
  bottomTabBar = "bottomTabBar",
  contextualNavToolbar = "contextualNavToolbar",
  map = "map",
}

export enum ServicesTab {
  appPublishingPurchase = "appPublishing",
  appPublishingInformation = "appPublishingInformation",
  appPublishingUpdate = "appPublishing",
  appConfiguration = "appDevelopment",
  customDevelopment = "customDevelopment",
  solutionsArchitecture = "solutionsArchitecture",
}

export enum SectionsConfigOptions {
  overview = "overview",
  branding = "branding",
  linkHandling = "linkHandling",
  interface = "interface",
  websiteOverrides = "websiteOverrides",
  permissions = "permissions",
  nativeNavigation = "nativeNavigation",
  pushNotifications = "pushNotifications",
  nativePlugins = "nativePlugins",
  build = "build",
}

export interface UiState {
  authMessageType: string | null;
  authModalType: string | null;
  authModalOpened: string | null;

  forceShowCart: boolean;
  isFontello: boolean;
  isFooterVisible: boolean;
  isLeftNavCollapsed: boolean;
  isRightPanelCollapsed: boolean;
  nativeNavigationTab: NativeNavigationTab;
  lastUsedColors: string[];
  servicesTab: ServicesTab;

  progressiveLogin: boolean;
  localLastSavedTime: string;
  lastSavedTime: string;

  importFromApp: {
    sourceAppId?: string;
    destinationAppId?: string;
    sections: string[];
    step: number;
  };

  transferApp: string;

  customIconsData: {
    customIcons: Icon[];
    iconCodeMap: { [code: string]: string };
  };

  splashScreen: {
    ios?: { sourceImage?: string; sourceImageDark?: string };
    android?: { sourceImage?: string; sourceImageDark?: string };
  };

  rebuildOnSave?: boolean;

  grooveId?: string;
  supportGrooveId?: string;

  modalShown: "none" | "contactForm" | "search" | "installApp";
  showDocs: string | null;
  showDocsKey: number;

  defaultSidebarMenuItems: MenuItemValue[];
}

export const initialStateUi: UiState = {
  authMessageType: null,
  authModalType: null,
  authModalOpened: null,

  progressiveLogin: false,
  localLastSavedTime: "",
  lastSavedTime: "",

  importFromApp: {
    sourceAppId: "",
    destinationAppId: "",
    sections: [
      "overview",
      "branding",
      "linkHandling",
      "interface",
      "websiteOverrides",
      "permissions",
      "nativeNavigation",
      "pushNotifications",
      "nativePlugins",
      "build",
    ],
    step: 1,
  },

  transferApp: "",

  customIconsData: {
    customIcons: [],
    iconCodeMap: {},
  },

  grooveId: "22f64306-3ad3-4c15-919f-6b25abdd7275",
  supportGrooveId: "f07f6f5c-592a-4532-8241-3f87c96c6938",

  splashScreen: {
    ios: {},
    android: {},
  },

  rebuildOnSave: false,

  forceShowCart: false,
  isFontello: false,
  isFooterVisible: false,
  isLeftNavCollapsed: true,
  isRightPanelCollapsed: true,
  lastUsedColors: [],
  nativeNavigationTab: NativeNavigationTab.topNavigationBar,
  servicesTab: ServicesTab.appPublishingPurchase,
  modalShown: "none",
  showDocs: null,
  showDocsKey: 0,
  defaultSidebarMenuItems: [],
};

const uiSlice = createSlice({
  name: "ui",
  initialState: initialStateUi,
  reducers: {
    uiUpdated: (state, action: PayloadAction<Partial<UiState>>) => ({
      ...state,
      isFontello: false,
      ...action.payload,
    }),
    uiShowDocsKeyUpdated: (state) => ({
      ...state,
      showDocsKey: state.showDocsKey + 1,
    }),
  },
});

export const { uiUpdated, uiShowDocsKeyUpdated } = uiSlice.actions;

export const selectUi = (state: AppState) => state.ui;
export const selectAuthMessageType = (state: AppState) =>
  selectUi(state).authMessageType || "";
export const selectAuthModalType = (state: AppState) =>
  selectUi(state).authModalType || "";
export const selectAuthModalOpened = (state: AppState) =>
  selectUi(state).authModalOpened || "";
export const selectForceShowCart = (state: AppState) =>
  selectUi(state).forceShowCart;
export const selectIsFontello = (state: AppState) => selectUi(state).isFontello;
export const selectIsFooterVisible = (state: AppState) =>
  selectUi(state).isFooterVisible;
export const selectIsLeftNavCollapsed = (state: AppState) =>
  selectUi(state).isLeftNavCollapsed;
export const selectProgressiveLogin = (state: AppState) =>
  selectUi(state).progressiveLogin;
export const selectIsRightPanelCollapsed = (state: AppState) =>
  selectUi(state).isRightPanelCollapsed;
export const selectModalShown = (state: AppState) => selectUi(state).modalShown;
export const selectIsInstallModalOpen = (state: AppState) =>
  selectUi(state).modalShown === "installApp";
export const selectLastUsedColors = (state: AppState) =>
  selectUi(state).lastUsedColors;
export const selectNativeNavigationTab = (state: AppState) =>
  selectUi(state).nativeNavigationTab;
export const selectServicesTab = (state: AppState) =>
  selectUi(state).servicesTab;
export const selectShowDocs = (state: AppState) => selectUi(state).showDocs;
export const selectShowDocsKey = (state: AppState) =>
  selectUi(state).showDocsKey;
export const selectGrooveId = (state: AppState) => selectUi(state).grooveId;
export const selectSupportGrooveId = (state: AppState) =>
  selectUi(state).supportGrooveId;
export const selectDestinationApp = (state: AppState) =>
  selectUi(state).importFromApp.destinationAppId;
export const selectImportAppStep = (state: AppState) =>
  selectUi(state).importFromApp.step;
export const selectImportAppSections = (state: AppState) =>
  selectUi(state).importFromApp.sections;
export const selectImportFomApp = (state: AppState) =>
  selectUi(state).importFromApp;

export const selectCustomIconsData = (state: AppState) =>
  selectUi(state).customIconsData;

export const selectCustomIcons = (state: AppState) =>
  selectUi(state).customIconsData.customIcons;

export const selectIconCodeMap = (state: AppState) =>
  selectUi(state).customIconsData.iconCodeMap;

export const selectNewImageSplashScreen = (state: AppState) =>
  selectUi(state).splashScreen;

export const selectLocalLastSavedTime = (state: AppState) =>
  selectUi(state).localLastSavedTime;
export const selectLastSavedTime = (state: AppState) =>
  selectUi(state).lastSavedTime;

export const selectRebuildOnSave = (state: AppState) =>
  selectUi(state).rebuildOnSave;

export const selectDefaultSideBarMenuItems = (state: AppState) =>
  selectUi(state).defaultSidebarMenuItems;

const uiReducer = uiSlice.reducer;

export default uiReducer;
