import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppState } from "stores/constants";

export interface UserOrganization {
  id: string;
  name: string;
  isCurrentOrganization?: boolean;
  role: "owner" | "collaborator";
}

export interface UserState {
  userId: string;
  firstName: string;
  lastName: string;
  email: string;
  isSuperUser: boolean;
  organizations: UserOrganization[];

  registrationEmail: string | undefined;
  registrationToken: string | undefined;
  registrationRedirectUrl: string | undefined;

  otherOrganization: {
    orgId?: string;
    isOtherOrganization?: boolean;
  };
}

const initialState: UserState = {
  userId: "",
  firstName: "",
  lastName: "",
  email: "",
  isSuperUser: false,
  organizations: [],
  registrationEmail: undefined,
  registrationToken: undefined,
  registrationRedirectUrl: undefined,

  otherOrganization: {
    isOtherOrganization: false,
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userUpdated: (state, action: PayloadAction<Partial<UserState>>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { userUpdated } = userSlice.actions;

export const selectUser = (state: AppState) => state.user;
export const selectIsAuthenticated = (state: AppState) =>
  !!selectUser(state).userId;
export const selectUserId = (state: AppState) => selectUser(state).userId;
export const selectFirstName = (state: AppState) => selectUser(state).firstName;
export const selectLastName = (state: AppState) => selectUser(state).lastName;
export const selectUserEmail = (state: AppState) => selectUser(state).email;
export const selectIsSuperUser = (state: AppState) =>
  selectUser(state).isSuperUser;
export const selectUserOrganizations = (state: AppState) => {
  const { organizations } = selectUser(state);
  const updatedOrganizations = [...organizations];
  const currentOrganization = updatedOrganizations.find(
    (i) => i.isCurrentOrganization
  );
  if (!currentOrganization && updatedOrganizations.length > 0) {
    updatedOrganizations[0].isCurrentOrganization = true;
  }
  return updatedOrganizations;
};
export const selectUserCurrentOrganizationId = (state: AppState) => {
  const organizations = selectUserOrganizations(state);
  const currentOrganization = organizations.find(
    (i) => i.isCurrentOrganization
  );
  return currentOrganization?.id;
};
export const selectRegistrationEmail = (state: AppState) =>
  selectUser(state).registrationEmail;
export const selectRegistrationToken = (state: AppState) =>
  selectUser(state).registrationToken;
export const selectRegistrationRedirectUrl = (state: AppState) =>
  selectUser(state).registrationRedirectUrl;

export const selectIsOtherOrganizationSelected = (state: AppState) =>
  selectUser(state).otherOrganization.isOtherOrganization;
export const selectOtherOrganizationId = (state: AppState) =>
  selectUser(state).otherOrganization.orgId;

const authReducer = userSlice.reducer;

export default authReducer;
