import { AnyAction, combineReducers, configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { deepEqual } from "fast-equals";
import omit from "lodash/omit";
import { createWrapper, HYDRATE } from "next-redux-wrapper";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import baseApi from "services/api";
import { getAppBuilderCache } from "utils/appBuilder";
import { filterUndefined } from "utils/objects";

import { AppDispatch, AppState } from "./constants";
import appReducer from "./features/app";
import appBuilderReducer from "./features/appBuilder";
import appConfigReducer from "./features/appConfig";
import backupsReducer from "./features/backups";
import cartReducer from "./features/cart";
import hubspotReducer from "./features/hubspot";
import metaReducer from "./features/meta";
import organizationReducer from "./features/organization";
import projectReducer from "./features/project";
import uiReducer, { initialStateUi } from "./features/ui";
import userReducer from "./features/user";

function createAppStore() {
  const appStoreReducer = combineReducers({
    [baseApi.reducerPath]: baseApi.reducer,
    app: appReducer,
    appBuilder: appBuilderReducer,
    appConfig: appConfigReducer,
    backups: backupsReducer,
    cart: cartReducer,
    hubspot: hubspotReducer,
    meta: metaReducer,
    organization: organizationReducer,
    project: projectReducer,
    ui: uiReducer,
    user: userReducer,
  });

  const reducer = (
    state: ReturnType<typeof appStoreReducer>,
    action: AnyAction
  ) => {
    if (action.type === HYDRATE) {
      const { appBuilder, ...payload } = action.payload;

      // Ignore ui fields that get updated via useEffect because they get override.
      return {
        ...state,
        ...omit(payload, [
          "cart",
          "meta",
          deepEqual(payload.ui, initialStateUi) ? "ui" : "",
        ]),
        meta: {
          ...state.meta,
          ...payload.meta,
          cachedAppBuilder: getAppBuilderCache(appBuilder),
        },
        appBuilder,
      };
    } else {
      return appStoreReducer(state, action);
    }
  };

  const appStore = configureStore({
    reducer: reducer as typeof appStoreReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(baseApi.middleware),
  });

  setupListeners(appStore.dispatch);

  return appStore;
}

export type AppStore = ReturnType<typeof createAppStore>;

export const appStoreWrapper = createWrapper<ReturnType<typeof createAppStore>>(
  createAppStore,
  {
    serializeState: (state) =>
      // Undefined cannot be serialized.
      filterUndefined(state),
  }
);

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;
