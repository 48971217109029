/* eslint-disable @typescript-eslint/no-explicit-any */

import { camelizeKeys } from "humps";
import compact from "lodash/compact";
import set from "lodash/set";

import { AppBuilder } from "utils/appBuilder";
import { getSignedUrl } from "utils/s3";

export async function camelizedResponseJson(response: Response) {
  const json = await response.json();
  return camelizeKeys(json);
}

export function appIconUploaded(
  cachedAppBuilder: AppBuilder | undefined,
  appBuilder: AppBuilder | undefined
) {
  try {
    const previousIcons = compact([
      cachedAppBuilder?.branding?.appIcon?.android,
      cachedAppBuilder?.branding?.splashScreen?.android?.sourceImage,
      cachedAppBuilder?.branding?.splashScreen?.android?.sourceImageDark,
      cachedAppBuilder?.branding?.appIcon?.ios,
      cachedAppBuilder?.branding?.splashScreen?.ios?.sourceImage,
      cachedAppBuilder?.branding?.splashScreen?.ios?.sourceImageDark,
    ]);
    const currentIcons = compact([
      appBuilder?.branding?.appIcon?.android,
      appBuilder?.branding?.splashScreen?.android?.sourceImage,
      appBuilder?.branding?.splashScreen?.android?.sourceImageDark,
      appBuilder?.branding?.appIcon?.ios,
      appBuilder?.branding?.splashScreen?.ios?.sourceImage,
      appBuilder?.branding?.splashScreen?.ios?.sourceImageDark,
    ]);
    return !!currentIcons.find((i) => !previousIcons.includes(i));
  } catch {
    return false;
  }
}

export async function addCustomFilesContentToAppBuilder(
  appBuilder: AppBuilder
) {
  return Promise.all(
    [
      // Custom CSS
      {
        url: appBuilder?.websiteOverrides?.customCssUrl?.android,
        path: "websiteOverrides.customCss.android",
      },
      {
        url: appBuilder?.websiteOverrides?.customCssUrl?.ios,
        path: "websiteOverrides.customCss.ios",
      },
      {
        url: appBuilder?.websiteOverrides?.customCssUrl?.base,
        path: "websiteOverrides.customCss.base",
      },
      // Custom JS
      {
        url: appBuilder?.websiteOverrides?.customJsUrl?.android,
        path: "websiteOverrides.customJs.android",
      },
      {
        url: appBuilder?.websiteOverrides?.customJsUrl?.ios,
        path: "websiteOverrides.customJs.ios",
      },
      {
        url: appBuilder?.websiteOverrides?.customJsUrl?.base,
        path: "websiteOverrides.customJs.base",
      },
      // Custom Offline.html
      {
        url: appBuilder?.interface?.offlinePage?.android?.offlinePageUrl,
        path: "interface.offlinePage.android.html",
      },
      {
        url: appBuilder?.interface?.offlinePage?.ios?.offlinePageUrl,
        path: "interface.offlinePage.ios.html",
      },
    ].map(async (item) => {
      try {
        const url = getSignedUrl(item.url);
        if (url) {
          const response = await fetch(url);
          const content = await response.text();
          if (content) {
            set(appBuilder, item.path, content);
          }
        }
      } catch {
        // NO OP
      }
    })
  );
}
