import isArray from "lodash/isArray";
import isPlainObject from "lodash/isPlainObject";
import maxBy from "lodash/maxBy";

type Join<K, P> = K extends string | number
  ? P extends string | number
    ? `${K}${"" extends P ? "" : "."}${P}`
    : never
  : never;

type Prev = [
  never,
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  ...0[]
];

export type Paths<T, D extends number = 10> = [D] extends [never]
  ? never
  : T extends object
  ? {
      [K in keyof T]-?: K extends string | number
        ? `${K}` | Join<K, Paths<T[K], Prev[D]>>
        : never;
    }[keyof T]
  : "";

interface GetNextAvailableOptions {
  key?: string;
  subListKey?: string;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function getNextAvailable<T extends Record<string, any>>(
  list: T[],
  { key = "id", subListKey }: GetNextAvailableOptions = {}
) {
  const currentIds = list.reduce<number[]>((prevItem, currentItem) => {
    const nextItem = [];
    nextItem.push(Number(currentItem ? currentItem[key] : undefined));

    if (subListKey) {
      const subList = currentItem[subListKey];
      if (isArray(subList)) {
        subList.forEach((item) =>
          nextItem.push(Number(item ? item[key] : undefined))
        );
      }
    }

    return [...prevItem, ...nextItem];
  }, []);

  const currentMax = maxBy(currentIds, (o) => Number(o));

  if (currentMax == null) {
    return 1;
  }

  return currentMax + 1;
}

export function filterUndefined(obj: any | any[]) {
  if (isArray(obj)) {
    const newObj: any[] = [];

    obj.forEach((value: any) => {
      if (isArray(value) || isPlainObject(value)) {
        newObj.push(filterUndefined(value));
      } else if (value !== undefined) {
        newObj.push(value);
      }
    });

    return newObj;
  } else if (isPlainObject(obj)) {
    const newObj: any = {};

    Object.entries(obj).forEach(([key, value]) => {
      if (isArray(value) || isPlainObject(value)) {
        newObj[key] = filterUndefined(value);
      } else if (value !== undefined) {
        newObj[key] = value;
      }
    });

    return newObj;
  }

  return obj;
}
/* eslint-enable @typescript-eslint/no-explicit-any */
