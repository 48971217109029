import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DeepPartial } from "utility-types";

import { appBuilderUpdater } from "utils/appBuilder";

import { AppState } from "../../constants";
import { selectAppBuilder } from "./utils";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AppBuilderPreviewState = any;

const initialState: AppBuilderPreviewState = {};

const unlistedSlice = createSlice({
  name: "unlisted",
  initialState,
  reducers: {
    unlistedUpdated: (
      state,
      action: PayloadAction<DeepPartial<AppBuilderPreviewState>>
    ) => appBuilderUpdater(state, action.payload),
  },
});

export const { unlistedUpdated } = unlistedSlice.actions;

export const selectUnlisted = (state: AppState) =>
  selectAppBuilder(state).unlisted;

const unlistedReducer = unlistedSlice.reducer;

export default unlistedReducer;
