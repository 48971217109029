/* eslint-disable @typescript-eslint/ban-types */

import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/query/react";

export enum TagType {
  AppBuilder = "AppBuilder",
  AppBuilderStatus = "AppBuilderStatus",
  ManageAppAccess = "ManageAppAccess",
  Backup = "Backup",
  HelpInfo = "HelpInfo",
  Icon = "Icon",
  Plugin = "Plugin",
  Admin = "Admin",
  Shopify = "Shopify",
}

export type UseQueryStateOptions = {
  pollingInterval?: number;
  refetchOnFocus?: boolean;
  refetchOnMountOrArgChange?: boolean | number;
  refetchOnReconnect?: boolean;
  skip?: boolean;
};

export type BaseQueryFnType = BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError,
  {},
  FetchBaseQueryMeta
>;

export const CUSTOM_API_ERRORS = [
  "account-exists",
  "account-invalid",
  "email-not-matching",
  "existing-organization-member",
  "expired-token",
  "invalid-credential",
  "invitation-expired",
  "invitation-invalid",
  "invitation-pending",
  "no-app-access",
  "not-loggedin",
  "not-organization-member",
  "only-organization-owner",
  "project-not-linked",
];
